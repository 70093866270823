import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { BsSearch } from "react-icons/bs";
const SearchBox = React.memo(({ ...otherProps }) => {
  const searchFieldConfig = {
    fullWidth: true,
    autoComplete: "off",
    size: "small",
    ...otherProps,
  };
  return (
    <TextField
      {...searchFieldConfig}
      name="searchBox"
      type="text"
      placeholder="Search..............."
      className="searchBar"
      onChange={otherProps.handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BsSearch />
          </InputAdornment>
        ),
      }}
    />
  );
});

export default SearchBox;
