import React, { useEffect } from "react";
import SwipeableTemporaryDrawer from "./SwipeableTemporaryDrawer";

import FullcalendarEvent from "./FullcalendarEvent";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { observer } from "mobx-react";
import CalenderStore from "../../../Store/CalenderStore";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";

const NurseSchedule = () => {
  useEffect(() => {
    CalenderStore.getEmployeeList("RN");
    CalenderStore.getEmployeeCalendar("RN");
  }, []);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Nurse Schedule"
          subHeaderTitle="Schedule/Nurse Schedule"
        />
      </div>
      <div className="calendarContainer">
        <div className="calendarSection">
          <div className="leftCalendar">
            <div className="addScheduleBtn">
              <SwipeableTemporaryDrawer />
            </div>
            <div className="smallCalender">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
            </div>
            <div className="scheduleIndicators">
              <div className="expireDateIndicator">
                <div className="customSymbol expireSymbol"></div>
                <div className="scheduleIndicatorsTitle">Expire Date</div>
              </div>
              <div className="upcomingDataIndicator">
                <div className="customSymbol InitialVisit"></div>
                <div className="scheduleIndicatorsTitle">Initial Visit</div>
              </div>

              <div className="upcomingDataIndicator">
                <div className="customSymbol QuaterlyVisit"></div>
                <div className="scheduleIndicatorsTitle">Quaterly Visit</div>
              </div>
              <div className="upcomingDataIndicator">
                <div className="customSymbol AnuallVisit"></div>
                <div className="scheduleIndicatorsTitle">Anually Visit</div>
              </div>
              <div className="upcomingDataIndicator">
                <div className="customSymbol ClientMoves"></div>
                <div className="scheduleIndicatorsTitle">Client Moves</div>
              </div>
              <div className="upcomingDataIndicator">
                <div className="customSymbol CaregiverChanged"></div>
                <div className="scheduleIndicatorsTitle">
                  Care giver Changed
                </div>
              </div>
            </div>
          </div>
          <div className="rightCalendar">
            <FullcalendarEvent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(NurseSchedule);
