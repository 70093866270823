import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "../../../CSS/CareGiverClientView.css";
import FormikController from "../../../components/FormikController/FormikController";
import ClientStore from "../../../Store/ClientStore";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import Avatar from "@mui/material/Avatar";
import CareGiverStore from "../../../Store/CareGiverStore";
import GlobelStore from "../../../Store/GlobelStore";
import { Tooltip } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { stringAvatar } from "../../../Utils/Utils";

const NurseClientView = observer(({ employeeCategory }) => {
  const { employeeId } = useParams();
  const { clientId } = useParams();

  const [clientList, setClientList] = useState([]);
  let nurseClient = [];
  let careGiverClient = [];

  if (employeeCategory === "Nurse" || employeeCategory === "CareGiver") {
    ClientStore.clientEmployeeRelationData.map((item, index) => {
      if (item.employeeCategory === "RN" && employeeCategory === "Nurse") {
        nurseClient.push(item);
      } else if (item.employeeCategory === "CareGiver") {
        careGiverClient.push(item);
      }
    });
  }

  let employeeClientRelationData =
    employeeCategory === "Client"
      ? ClientStore.employeeClientRelationAllData || []
      : employeeCategory === "CareGiver"
      ? careGiverClient || []
      : nurseClient || [];

  const formik = useFormik({
    initialValues: {
      clientList: "",
    },
    validationSchema: Yup.object({
      clientList: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      setEmployeeClientRelation(values);
    },
  });

  const setEmployeeClientRelation = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeProfileId:
        employeeCategory === "CareGiver" || employeeCategory === "Nurse"
          ? values.clientList
          : employeeId,
      clientProfileId:
        employeeCategory === "CareGiver" || employeeCategory === "Nurse"
          ? clientId
          : values.clientList,
    };

    axios
      .post(ClientURL.SET_CLIENT_RELATION, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          employeeCategory === "Client"
            ? ClientStore.getEmployeeClientRelation(employeeId)
            : ClientStore.getClientEmployeeRelation(clientId);
          formik.resetForm();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully added");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Relation between employee and client already exist."
          );
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Client");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Client");
      });
  };

  const deleteEmployeeClientRelation = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      employeeProfileId:
        employeeCategory === "CareGiver" || employeeCategory === "Nurse"
          ? values
          : employeeId,
      clientProfileId:
        employeeCategory === "CareGiver" || employeeCategory === "Nurse"
          ? clientId
          : values,
    };

    axios
      .post(ClientURL.DELETE_CLIENT_RELATION, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          employeeCategory === "Client"
            ? ClientStore.getEmployeeClientRelation(employeeId)
            : ClientStore.getClientEmployeeRelation(clientId);
          formik.resetForm();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully deleted");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Faille to delete client");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Faille to delete client");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);

        AgencyStore.showAlert("error", "Faille to delete client");
      });
  };
  useEffect(() => {
    const response =
      employeeCategory === "CareGiver"
        ? CareGiverStore.careGiverList
        : employeeCategory === "Nurse"
        ? GlobelStore.nurseList
        : ClientStore.careGiverList;

    const finalData = response.map((item) => ({
      value: item.id,
      label:
        employeeCategory === "CareGiver" || employeeCategory === "Nurse"
          ? `${item.fullName} (${item.employeeId})`
          : `${item.fullName} (${item.mra})`,
    }));
    setClientList(finalData);
  }, [
    ClientStore.careGiverList,
    CareGiverStore.careGiverList,
    GlobelStore.nurseList,
  ]);

  return (
    <div>
      <div className="ClientListMainContainer">
        <div className="ClientListContainer">
          <div className="clientListinputFiled">
            <FormikController
              control="selectByInput"
              options={clientList}
              handleChange={(event, value) => {
                formik.setFieldValue("clientList", value ? value.value : "");
              }}
              error
              touch={formik.touched.clientList}
              errorname={formik.errors.clientList}
            />
          </div>
          <ConfirmPopUp
            buttonLabel="Add Client"
            title={
              employeeCategory === "CareGiver"
                ? "Add Personal Care Aid"
                : employeeCategory === "Nurse"
                ? "Add Nurse"
                : "Add Client"
            }
            content=" Please confirm your decision below."
            onConfirm={() => {
              formik.handleSubmit();
            }}
            buttonClassName="DeactivateAccount" // Pass the class name
            clickHTML={
              <div className="addClientBtn">
                <FormikController
                  name={
                    employeeCategory === "CareGiver"
                      ? "Add Personal Care Aid"
                      : employeeCategory === "Nurse"
                      ? "Add Nurse"
                      : "Add Client"
                  }
                  control="buttons"
                  className={
                    formik.values.clientList
                      ? "buttonSelected fixedWidth"
                      : "disableButton fixedWidth"
                  }
                  fullWidth="false"
                />
              </div>
            }
            isOpenPopup={formik.values.clientList ? true : false}
          />
        </div>
        <div className="headerContainer">
          <div className="headerItem">
            {employeeCategory === "Client" ? "#MRA" : "Id"}
          </div>
          <div className="headerItem">Name</div>
          <div className="headerItem">Phone</div>
          <div className="headerItem flexLeft">Action</div>
        </div>
        {employeeClientRelationData.length === 0 ? (
          <div className="noRecordsMessage">No records available</div>
        ) : (
          employeeClientRelationData.map((item, index) => (
            <div className="gridContainer" key={index}>
              <div className="gridItem">
                {employeeCategory === "CareGiver" ? (
                  <Link
                    to={`/agency/careGiverProfile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.employeeId}
                  </Link>
                ) : employeeCategory === "Nurse" ? (
                  <Link
                    to={`/agency/Nurse_Profile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.employeeId}
                  </Link>
                ) : (
                  <Link
                    to={`/agency/Client_profile/${item.id}`}
                    className="gridItemName"
                  >
                    {item.mra}
                  </Link>
                )}
              </div>

              <div className="gridItem">
                <div className="avatarNameContainer">
                  <Avatar
                    {...stringAvatar(`${item.firstName} ${item.lastName}`)}
                  />
                  <div className="gridItemNameEmail">
                    {employeeCategory === "CareGiver" ? (
                      <Link
                        to={`/agency/careGiverProfile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.fullName}`}
                      </Link>
                    ) : employeeCategory === "Nurse" ? (
                      <Link
                        to={`/agency/Nurse_Profile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.fullName}`}
                      </Link>
                    ) : (
                      <Link
                        to={`/agency/Client_profile/${item.id}`}
                        className="gridItemName"
                      >
                        {`${item.fullName}`}
                      </Link>
                    )}

                    <div className="gridItemEmail">{item.email}</div>
                  </div>
                </div>
              </div>
              <div className="gridItem">{item.phoneNo}</div>

              <div className="gridItem flexLeft">
                <ConfirmPopUp
                  buttonLabel="Delete"
                  title="Delete"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    deleteEmployeeClientRelation(item.id);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <Tooltip title="Delete">
                      <div className="iconBox actionMarginLeft deleteIcon">
                        <RiDeleteBin6Line />
                      </div>
                    </Tooltip>
                  }
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
});

export default NurseClientView;
