import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "../../CSS/AgGrid.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
const AgGrid = (props) => {
  const { ...otherProps } = props;
  const gridApiRef = useRef(null);
  const onGridSizeChanged = (params) => {
    gridApiRef.current = params.api;
    gridApiRef.current.sizeColumnsToFit();
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: false,
      suppressSizeToFit: false,
      unSortIcon: true,
      sortingOrder: ["asc", "desc"],
      suppressMovable: true,
    }),
    []
  );

  return (
    <div id="careGiver_table" className="ag-theme-alpine">
      <AgGridReact
        {...otherProps}
        suppressCellSelection={true}
        enableColResize={true}
        rowMultiSelectWithClick={true}
        enableCellTextSelection={true}
        defaultColDef={defaultColDef}
        onGridReady={otherProps.onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        headerHeight="35"
        rowHeight="35"
      />
    </div>
  );
};

export default React.memo(AgGrid);
