import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { ImFilePdf } from "react-icons/im";
const DownloadLink = ({ document, fileName }) => {
  return (
    <PDFDownloadLink
      style={{ textDecoration: "none" }}
      document={document}
      fileName={fileName}
    >
      <div className="changeLogBtn underlineClick">
        <ImFilePdf />
        <span className="underlineClickTitle"> Download</span>
      </div>
    </PDFDownloadLink>
  );
};

export default DownloadLink;
