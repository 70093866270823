import React from "react";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import AdminEmployeeListTable from "./AdminEmployeeListTable";
import CareGiverStore from "../../../Store/CareGiverStore";
import PageTitle from "../../PageTitle";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";

const AdminEmployeeList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  const handleAddAdminEmployee = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/addAdminEmployee");
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Admin Employee"
          subHeaderTitle="Employee/Admin Employee"
        />

        <div>
          <div className="actionButonContainer">
            <FormikController
              name="Add Admin Employee"
              control="buttons"
              className="roundedButton menuItemButton addCareGiverButton addButton"
              startIcon={<Icon iconName="groupAdd" />}
              onClick={() => handleAddAdminEmployee()}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="actionButtonSearchMainContainer">
          <div className="searchMainCotainer">
            <div className="searchContainer">
              <SearchBox
                handleChange={(e) => {
                  handleSearchChange(e, "AdminEmployee");
                }}
              />
            </div>
            <div className="activeAddButtonContainer">
              <div>
                <input
                  id="careGiver_active"
                  type="checkbox"
                  onChange={(e) => {
                    handleActiveCheckBox(e, "Active", "AdminEmployee");
                  }}
                />
                <span className="checkBoxTitle">Active</span>
              </div>
            </div>
          </div>
        </div>
        <AdminEmployeeListTable />
      </div>
    </div>
  );
});

export default AdminEmployeeList;
