import React from "react";
import Inputs from "../Inputs/Inputs";
import Buttons from "../Buttons/Buttons";
import SelectMenu from "../Select/SelectMenu";
import { Radios } from "../Radio/Radios";
import Checkboxes from "../CheckBox/Checkboxes";
import SelectByInput from "../SelectAutoComplete/SelectByInput";
import File from "../File/File";
import AgGrid from "../AgGridTable/AgGrid";

const FormikController = (props) => {
  const { control, ...otherProps } = props;
  switch (control) {
    case "textfield":
      return <Inputs {...otherProps} />;
    case "buttons":
      return <Buttons {...otherProps} />;
    case "selects":
      return <SelectMenu {...otherProps} />;
    case "radio":
      return <Radios {...otherProps} />;
    case "checkboxes":
      return <Checkboxes {...otherProps} />;
    case "selectByInput":
      return <SelectByInput {...otherProps} />;
    case "file":
      return <File {...otherProps} />;
    case "agGrid":
      return <AgGrid {...otherProps} />;
    default:
      return null;
  }
};

export default FormikController;
