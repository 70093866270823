import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AgencyStore from "../../../Store/AgencyStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import axios from "axios";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import ClientStore from "../../../Store/ClientStore";

const DocViewerList = observer(
  ({
    govResultSet,
    applicationResumeAndHireResultSet,
    confidentialResultSet,
    id,
    immigrationStatus,
    clientDocument,
    clientInfoPacket,
  }) => {
    const employeeId = id ? id : useParams()?.employeeId;
    CareGiverStore.setSelectedEmloyeeId(employeeId);
    const downloadPDF = (data, fileName) => {
      AgencyStore.downloadFile(data, fileName);
    };
    const openPdf = (data) => {
      AgencyStore.openPDF(data);
    };

    const formik = useFormik({
      initialValues: {
        uniqueCode: "",
      },

      validationSchema: Yup.object({
        uniqueCode: Yup.string().required("Unique Code Required"),
      }),
      onSubmit: (values) => {
        if (
          clientDocument == "clientDocument" ||
          clientInfoPacket == "clientInfoPacket"
        ) {
          if (ClientStore?.ClientAllData?.client?.mra === values?.uniqueCode) {
            CareGiverStore.setIsUniqueCodeEntered(true);
          } else {
            CareGiverStore.setIsUniqueCodeEntered(false);
            AgencyStore.showAlert("error", "Invalid Unique code");
          }
        } else {
          if (
            CareGiverStore.careGiverProfileData.employeeId === values.uniqueCode
          ) {
            CareGiverStore.setIsUniqueCodeEntered(true);
          } else {
            CareGiverStore.setIsUniqueCodeEntered(false);
            AgencyStore.showAlert("error", "Invalid Unique code");
          }
        }
      },
    });

    const addNewFile = (data) => {
      CareGiverStore.setFileUploadPopUp(true);
      CareGiverStore.setSelectedDoc(data);
    };

    const deleteEmployeeDocument = async (doc) => {
      AgencyStore.setIsLoading(true);
      const params = { employeeProfileId: employeeId, docId: doc.id };
      await axios
        .post(CareGiverURL.DELETE_EMPLOYEEDOC, params, {
          headers: { Authorization: localStorage.getItem("loginToken") },
        })
        .then((res) => {
          if (res?.data?.status === "success") {
            CareGiverStore.getConfidentialDoc(employeeId);
            AgencyStore.setIsLoading(false);
            AgencyStore.showAlert(
              "success",
              "The document has been successfully deleted."
            );
          } else {
            AgencyStore.setIsLoading(false);
            AgencyStore.showAlert("error", "Failed to delete the document.");
          }
        })
        .catch((err) => {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to delete the document.");
        });
    };

    const deleteClientDocument = async (doc) => {
      AgencyStore.setIsLoading(true);

      const params = { clientProfileId: doc?.clientProfileId, docId: doc?.id };
      await axios
        .post(ClientURL.DELETE_CLIENT_DOCUMENT, params, {
          headers: { Authorization: localStorage.getItem("loginToken") },
        })
        .then((res) => {
          if (res?.data?.status === "success") {
            ClientStore.getClientDocument(doc?.clientProfileId);
            AgencyStore.setIsLoading(false);
            AgencyStore.showAlert(
              "success",
              "The document has been successfully deleted."
            );
          } else {
            AgencyStore.setIsLoading(false);
            AgencyStore.showAlert("error", "Failed to delete the document.");
          }
        })
        .catch((err) => {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to delete the document.");
        });
    };

    const updateApprovalForEmpDoc = async (doc) => {
      AgencyStore.setIsLoading(true);

      const newIsAdminApproved = !doc.isAdminApproved;

      const params = {
        employeeProfileId: employeeId,
        docId: doc.id,
        isAdminApproved: newIsAdminApproved,
      };

      await axios
        .post(CareGiverURL.UPDATE_APPROVAL_EMP_DOC, params, {
          headers: { Authorization: localStorage.getItem("loginToken") },
        })
        .then((res) => {
          if (res?.data?.status === "success") {
            AgencyStore.setIsLoading(false);
            doc.isAdminApproved = newIsAdminApproved;
            AgencyStore.showAlert(
              "success",
              `The document has been successfully ${
                newIsAdminApproved ? "verified" : "unverified"
              }.`
            );
          } else {
            AgencyStore.setIsLoading(false);
            AgencyStore.showAlert(
              "error",
              `Failed to ${
                newIsAdminApproved ? "verify" : "unverify"
              } the document.`
            );
          }
        })
        .catch((err) => {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to update the document's approval status."
          );
        });
    };

    const [responseData, setResponseData] = useState({});
    const careGiverAllDocList = CareGiverStore?.careGiverAllDocList;
    useEffect(() => {
      // console.log(immigrationStatus);
      if (govResultSet === "govResultSet") {
        setResponseData(careGiverAllDocList?.govResultSet);
      } else if (confidentialResultSet === "confidentialResultSet") {
        setResponseData(careGiverAllDocList?.confidentialResultSet);
      } else if (
        applicationResumeAndHireResultSet == "applicationResumeAndHireResultSet"
      ) {
        setResponseData(careGiverAllDocList?.applicationResumeAndHireResultSet);
      } else if (clientDocument == "clientDocument") {
        const clientAllDocList = ClientStore?.clientAllDocList?.resultMap;
        const newData = Object.fromEntries(
          Object.entries(clientAllDocList).filter(
            ([key]) => key !== "Client Info Package" && key !== "Intake Form"
          )
        );
        setResponseData(newData);
      } else if (clientInfoPacket == "clientInfoPacket") {
        const clientAllDocList = ClientStore?.clientAllDocList?.resultMap;
        const newData = Object.fromEntries(
          Object.entries(clientAllDocList).filter(
            ([key]) => key == "Client Info Package" || key == "Intake Form"
          )
        );
        setResponseData(newData);
      }
    }, [
      govResultSet,
      applicationResumeAndHireResultSet,
      confidentialResultSet,
      careGiverAllDocList,
      ClientStore?.clientAllDocList,
    ]);

    return (
      <div className="docviewMainContainer">
        {CareGiverStore.isUniqueCodeEntered === false ? (
          <div className="listMainDivContainer enterCodeContainer">
            <div>
              <FormikController
                name="uniqueCode"
                error
                label="Unique Code"
                type="text"
                control="textfield"
                {...formik.getFieldProps("uniqueCode")}
                touch={formik.touched.uniqueCode}
                errorname={formik.errors.uniqueCode}
              />
            </div>
            <FormikController
              name="Submit code"
              control="buttons"
              onClick={formik.handleSubmit}
              className="buttonSelected fixedWidth"
              fullWidth="false"
            />
          </div>
        ) : (
          <div className="listMainDivContainer">
            {Object.entries(responseData).map(([key, documents]) => (
              <>
                {!(
                  immigrationStatus === "US Citizen" &&
                  (key === "Employment Authorization Card" ||
                    key === "Green Card") &&
                  documents.length === 0
                ) ? (
                  <div key={key} className="docDetailsMainContainer">
                    <div className="docTypeTitle">
                      <div>{key}</div>
                      <div
                        className="docAddContainer"
                        onClick={() => {
                          addNewFile(key);
                        }}
                      >
                        <AiOutlinePlusCircle className="docAddIcon" /> Add New
                      </div>
                    </div>
                    {documents.map((document) => (
                      <div
                        key={document.id}
                        className={`docDetailsContainer ${
                          document.isAdminApproved == true ? "" : "notApproved"
                        }`}
                      >
                        <div>
                          <div
                            className={`docTitle ${
                              document.isAdminApproved == true
                                ? ""
                                : "notApprovedDocTitle"
                            } `}
                          >
                            {document.fileName}
                          </div>
                          <div className="docSubTitlemainContainer">
                            <div>Uploaded Date:{document.docUploadedDate}</div>
                            {document.fileExpiryDate && (
                              <div>Expiry Date: {document.fileExpiryDate}</div>
                            )}
                            <ConfirmPopUp
                              title="Delete"
                              content="You are about to perform a critical action that cannot be undone. Please confirm your decision below."
                              onConfirm={() => {
                                if (
                                  clientDocument == "clientDocument" ||
                                  clientInfoPacket == "clientInfoPacket"
                                ) {
                                  deleteClientDocument(document);
                                } else {
                                  deleteEmployeeDocument(document);
                                }
                              }}
                              clickHTML={
                                <div className="docDeleteText">Delete</div>
                              }
                            />
                            <div
                              className="docDownloadText"
                              onClick={() => {
                                downloadPDF(
                                  document.filePath,
                                  document.fileName
                                );
                              }}
                            >
                              Download
                            </div>
                            {document.isAdminApproved == false ? (
                              <ConfirmPopUp
                                title="Update Approval"
                                content="Are you sure you want to update the approval status of this document?"
                                onConfirm={() => {
                                  updateApprovalForEmpDoc(document);
                                }}
                                clickHTML={
                                  <div className="docDownloadText">Verify</div>
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          className="docViewText"
                          onClick={() => {
                            openPdf(document.filePath);
                          }}
                        >
                          View
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default DocViewerList;
