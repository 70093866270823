import React, { useEffect, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import { nurseVisitType } from "../../../Constants/Constants";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import { home_safety } from "../../../Constants/FileData";
import * as Yup from "yup";
import { setDateInFomat } from "../../../Utils/Utils";
import {
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { headerTextStyles } from "../../../Constants/Styles";
import { useParams } from "react-router-dom";
import ClientStore from "../../../Store/ClientStore";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import AgencyStore from "../../../Store/AgencyStore";
import { format } from "date-fns";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";

const AddHomeSafety = ({ type, handleClose }) => {
  const [saveUpadateState, setSaveUpadateState] = useState("");
  const handlesaveUpadateState = (value) => {
    setSaveUpadateState(value);
  };
  const { clientId } = useParams();
  const defaultValue = "n/a";

  const [checkValue, setCheckValue] = useState({
    environment: {
      "Safe and adequate food and water supplies": defaultValue,
      "Stove and means for refrigerator present": defaultValue,
      "Adequate heat and ventilation": defaultValue,
      "Free from infestation": defaultValue,
      "Pathways free of obstacles such as loose rugs, furniture, etc.":
        defaultValue,
      "Clean area exists in which to store medical supplies": defaultValue,
      "Is cautious with heating pads": defaultValue,
      "Has a working smoke detector": defaultValue,
      "If uses oxygen, appropriate signs posted": defaultValue,
    },

    fireElectrical: {
      "Fire exists available;warning devices installed": defaultValue,
      "No overuse of extension cords/adequate electrical outlets available":
        defaultValue,
      "Turns off oven and stove burners": defaultValue,
      "Emergency telephone numbers posted by phone": defaultValue,
      "Turns pot handles to back of stove": defaultValue,
      "Uses space heaters cautiously": defaultValue,
      "Does not smoke in bed": defaultValue,
      "Oxygen precautions used": defaultValue,
    },

    bathroomSafety: {
      "No throws rugs": defaultValue,
      "Safety bars present and in good condition": defaultValue,
      "Lighting is adequate": defaultValue,
      "Shower chair is sturdy and in good working condition": defaultValue,
    },

    medicationUse: {
      "Keeps all medications in original bottle or med box": defaultValue,
      "Has a medication schedule": defaultValue,
      "Home safety Instructions Given": defaultValue,
    },
  });

  const handleChange = (desc, value) => {
    setCheckValue((prev) => {
      const group = Object.keys(prev).find((key) =>
        Object.keys(prev[key]).includes(desc)
      );
      return {
        ...prev,
        [group]: {
          ...prev[group],
          [desc]: value,
        },
      };
    });
  };

  const [relationRN, setRelationRN] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  const formik = useFormik({
    initialValues: {
      relationRN: "",
      visitType: "",
      signatureDate: "",
      clientLivesWith: "",
      manualDate: "",
    },
    validationSchema: Yup.object({
      relationRN: YupValidation.requiredOnly,
      visitType: YupValidation.requiredOnly,
      signatureDate: YupValidation.requiredOnly,
      clientLivesWith: YupValidation.requiredOnly,
      manualDate: YupValidation.requiredOnly,
    }),

    onSubmit: (values) => {
      if (saveUpadateState == "save") {
        saveHomeSafety(values);
      } else updateHomeSafety(values);
    },
  });

  useEffect(() => {
    const nurseData = [];

    ClientStore.clientEmployeeRelationData.forEach((item) => {
      const formattedItem = {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
      if (item.employeeCategory === "RN") {
        nurseData.push(formattedItem);
      }
    });
    setRelationRN(nurseData);
  }, []);
  const result = type == "save" ? "" : ClientStore.safetyRecordList;
  const saveHomeSafety = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: clientId,
      rnProfileId: values.relationRN,
      visitType: values.visitType,
      manualDate: format(new Date(values.manualDate), "yyyy-MM-dd"),
      environment: checkValue.environment,
      fireElectrical: checkValue.fireElectrical,
      bathroomSafety: checkValue.bathroomSafety,
      medicationUse: checkValue.medicationUse,
      signatureDate: format(new Date(values.signatureDate), "yyyy-MM-dd"),
      clientLivesWith: values.clientLivesWith,
    };
    console.log("params", params);
    axios
      .post(ClientURL.SAVE_HOME_SAFETY, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.showAlert(
            "success",
            "Successfully Saved Home Safety Records"
          );
          setIsSaved(true);
          ClientStore.setSafetyRecord(res?.data?.result);
          AgencyStore.setIsLoading(false);
          handleClose();
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to saved Home Safety Record List"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Please select one description item of each to save the records"
        );
      });
  };

  const setHomeSafetyData = () => {
    if (result) {
      formik.setValues({
        clientLivesWith: result.clientLivesWith || "",
        visitType: result.visitType || "",
        relationRN: result.evalCompletedBy || "",
        manualDate: setDateInFomat(result.manualDate) || "",
        signatureDate: setDateInFomat(result.signatureDate) || "",
      });
    }
  };
  useEffect(() => {
    if (clientId) {
      setHomeSafetyData();
    }
  }, [clientId]);

  const updateHomeSafety = (values) => {};

  return (
    <>
      <>
        <div className="homeSafetyContainer">
          <div className="underlineClick" onClick={handleClose}>
            Back
          </div>
          <div className="safetySelectInput">
            <div>Visit Type:</div>
            <div className="clientListinputFiled">
              <FormikController
                control="selects"
                error
                selectitem={nurseVisitType}
                {...formik.getFieldProps("visitType")}
                touch={formik.touched.visitType}
                errorname={formik.errors.visitType}
                disabled={isSaved ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="medicationWrapper">
          <div className="medicationSection">
            <div className="heading">
              {type == "save"
                ? "Home Safety Assessment *"
                : "Update Home Safety Assessment *"}
            </div>
            <div className="detailWrapper">
              <Grid container spacing={2}>
                <Grid item xs={6} className="rowSection">
                  <div className="nameList">Client's Name:</div>
                  <div className="medicationForm">
                    <FormikController
                      value={
                        ClientStore.ClientAllData.client?.firstName +
                        "  " +
                        ClientStore.ClientAllData.client?.lastName
                      }
                      variant="standard"
                      control="textfield"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className="rowSection">
                  <div className="nameDesc"> MR#</div>
                  <div className="medicationForm">
                    <FormikController
                      control="textfield"
                      value={ClientStore.ClientAllData.client?.mra}
                      variant="standard"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className="rowSection">
                  <div className="nameDesc">Address</div>
                  <div className="medicationForm">
                    <FormikController
                      control="textfield"
                      variant="standard"
                      value={
                        ClientStore.ClientAllData.client?.clientAddress?.state +
                        " " +
                        ClientStore.ClientAllData.client?.clientAddress?.city +
                        " " +
                        ClientStore.ClientAllData.client?.clientAddress
                          ?.zipCode +
                        " " +
                        ClientStore.ClientAllData.client?.clientAddress
                          ?.street +
                        " "
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className="rowSection">
                  <div className="nameDesc">Client Lives With</div>
                  <div className="medicationForm">
                    <FormikController
                      control="textfield"
                      variant="standard"
                      {...formik.getFieldProps("clientLivesWith")}
                      touch={formik.touched.clientLivesWith}
                      errorname={formik.errors.clientLivesWith}
                      disabled={isSaved ? true : false}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className="rowSection">
                  <div className="nameList">Evaluation Completed By:</div>
                  <div className="medicationForm">
                    <FormikController
                      control="selectByInput"
                      options={relationRN}
                      handleChange={(event, value) => {
                        formik.setFieldValue(
                          "relationRN",
                          value ? value.value : ""
                        );
                      }}
                      touch={formik.touched.relationRN}
                      errorname={formik.errors.relationRN}
                      disabled={isSaved ? true : false}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} className="rowSection">
                  Date
                  <div className="medicationForm">
                    <FormikController
                      control="textfield"
                      variant="standard"
                      {...formik.getFieldProps("manualDate")}
                      type="date"
                      touch={formik.touched.manualDate}
                      errorname={formik.errors.manualDate}
                      disabled={isSaved ? true : false}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <TableContainer>
              <Table className="table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className="tablecell" sx={headerTextStyles}>
                      Item No
                    </TableCell>
                    <TableCell className="tablecell" sx={headerTextStyles}>
                      Description
                    </TableCell>
                    <TableCell className="tablecell" sx={headerTextStyles}>
                      Yes
                    </TableCell>
                    <TableCell className="tablecell" sx={headerTextStyles}>
                      No
                    </TableCell>
                    <TableCell className="tablecell" sx={headerTextStyles}>
                      N/A
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(home_safety).map(([category, items]) => (
                    <React.Fragment key={category}>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {category.replace(/([A-Z])/g, " $1").toUpperCase()}
                        </TableCell>
                        <TableCell colSpan={4} />{" "}
                      </TableRow>
                      {type == "save" ? (
                        <>
                          {items.map((item, index) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell className="tablecell">
                                  {item.id}
                                </TableCell>
                                <TableCell className="tablecell">
                                  {item.description}
                                </TableCell>
                                <TableCell className="tablecell">
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          checkValue[category]?.[
                                            item.description
                                          ] === "yes"
                                        }
                                        name={item.description}
                                        value="yes"
                                        onChange={() => {
                                          handleChange(item.description, "yes");
                                        }}
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell className="tablecell">
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          checkValue[category]?.[
                                            item.description
                                          ] === "no"
                                        }
                                        onChange={() =>
                                          handleChange(item.description, "no")
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell className="tablecell">
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={
                                          checkValue[category]?.[
                                            item.description
                                          ] === "n/a"
                                        }
                                        onChange={() =>
                                          handleChange(item.description, "n/a")
                                        }
                                      />
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={2}>
              <Grid item xs={12} className="rowSection">
                Recommendations:CareGiver Aide to review copy of the Home Safety
                Assessment on Information Provide. Revise as appropriate{" "}
              </Grid>
              <Grid item xs={12} className="rowSection" pt={2}>
                As of the date of this evaluation, I attest that this home is
                safe environment for nursing care.
              </Grid>
              <Grid item xs={6} className="rowSection">
                <div className="nameDesc">RN Signature:</div>
                <div className="medicationForm">
                  <FormikController control="textfield" variant="standard" />
                </div>
              </Grid>
              <Grid item xs={6} className="rowSection">
                Date:
                <div className="medicationForm">
                  <FormikController
                    control="textfield"
                    variant="standard"
                    {...formik.getFieldProps("signatureDate")}
                    touch={formik.touched.signatureDate}
                    errorname={formik.errors.signatureDate}
                    type="date"
                    disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
              {isSaved == true ? (
                ""
              ) : (
                <div className="formButtonSave">
                  <ConfirmPopUp
                    buttonLabel={type == "save" ? "Save " : "Update"}
                    title={type == "save" ? "Save " : "Update"}
                    content=" Please confirm your decision below."
                    onConfirm={() => {
                      if (type == "save") {
                        handlesaveUpadateState("save");
                        formik.handleSubmit();
                      } else {
                        handlesaveUpadateState("Update");
                        formik.handleSubmit();
                      }
                    }}
                    clickHTML={
                      <FormikController
                        control="buttons"
                        name={
                          type == "save"
                            ? "Save Home Safety"
                            : "Update Home Safety"
                        }
                        className="buttonSelectedGreen fixedWidth"
                        fullWidth="false"
                      />
                    }
                  />
                </div>
              )}
            </Grid>
          </div>
        </div>
      </>
    </>
  );
};

export default AddHomeSafety;
