import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import "../../../CSS/AccountAccess.css";
import PopUpCareGiverSecurity from "./PopUpCareGiverSecurity";
import AdminEmployeeStore from "../../../Store/AdminEmployeeStore";
import { PaperComponent } from "../../../Utils/Utils";

const ActionPopUp = ({ onConfirm, clickHTML, type }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    AdminEmployeeStore.setAdminRoles("");
  };

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="accountAndAccessDialog"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Account
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="action-popup-content">
          <PopUpCareGiverSecurity />
        </div>
      </Dialog>
    </div>
  );
};

export default React.memo(ActionPopUp);
