import React, { useEffect } from "react";
import "../../../CSS/CareGiverSecurity.css";
import FormikController from "../../../components/FormikController/FormikController";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import CommunityOutReachCoordinatorCreateAccount from "./CommunityOutReachCoordinatorCreateAccount";
import AgencyStore from "../../../Store/AgencyStore";
import axios from "axios";
import { CareGiverURL } from "../../../Url/Url";
import { useParams } from "react-router-dom";
import CommunityOutReachCoordinatorUserView from "./CommunityOutReachCoordinatorUserView";
import GlobelStore from "../../../Store/GlobelStore";

const CommunityOutReachCoordinatorSecurity = observer(() => {
  const { employeeId } = useParams();

  const enableDisableEmployeeAccount = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      employeeUserId: CareGiverStore.isCareGiverAccountCreated,

      enable: values,
    };

    axios
      .post(CareGiverURL.ENABLEDISABLE_EMPLOYEEACCOUNT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          CareGiverStore.getEmployeeProfile(employeeId);

          const actionMessage = values
            ? "successfully activated"
            : "successfully deactivated";

          AgencyStore.showAlert("success", `User ID has been ${actionMessage}`);
        } else {
          AgencyStore.showAlert("error", "Failed to change action");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to change action");
      });
  };

  useEffect(() => {
    CareGiverStore.setIsCareGiverAccountCreated(
      CareGiverStore.careGiverProfileData.userId
    );
  }, [employeeId, CareGiverStore.careGiverProfileData]);
  return (
    <>
      {CareGiverStore.isCareGiverAccountCreated == 0 ? (
        <div>
          <CommunityOutReachCoordinatorCreateAccount />
        </div>
      ) : (
        <>
          <div className="mainContainer">
            <CommunityOutReachCoordinatorUserView />
          </div>
          <div className="mainContainer">
            {CareGiverStore.employeeAllData.accountDetails.isAccountEnable ==
            true ? (
              <>
                <div className="CGSHeader">Deactivate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to deactivate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Deactivate Account"
                  title="Deactivate Account"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    enableDisableEmployeeAccount(false);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Deactivate Account"
                        control="buttons"
                        className="buttonSelectedRed menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            ) : (
              <>
                <div className="CGSHeader">Activate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to Activate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Activate Account"
                  title="Activate"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    enableDisableEmployeeAccount(true);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Activate Account"
                        control="buttons"
                        className="buttonSelected menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default CommunityOutReachCoordinatorSecurity;
