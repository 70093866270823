import { observer } from "mobx-react";
import React, { useEffect } from "react";

import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";

import { YupValidation } from "../../../Constants/ValidationConstants";
import GlobelStore from "../../../Store/GlobelStore";
import "../../../CSS/AccountAccess.css";
import { useFormik } from "formik";

const PopUpCareGiverUserView = observer(() => {
  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
      fullName: YupValidation.fullName,
    }),
    onSubmit: (values) => {
      // createCareGiverAccount(values);
    },
  });

  const setProfileDataFormik = () => {
    const result = GlobelStore?.employeeData;

    const fullName = `${result?.fullName || ""}`;

    formik.setValues({
      email: result?.email || "",
      fullName: fullName.trim(),
    });
  };

  useEffect(() => {
    if (GlobelStore?.employeeData) {
      setProfileDataFormik();
    }
  }, [GlobelStore?.employeeData]);

  return (
    <div className="PopUpCareGiverUserViewMainContainer">
      <div className="CGSHeader">Account</div>
      <div className="">
        <div className="">
          <div>
            <FormikController
              name="fullName"
              error
              label="Full Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("fullName")}
              touch={formik.touched.fullName}
              errorname={formik.errors.fullName}
              disabled="true"
            />
          </div>
        </div>
        <div className="">
          <div>
            <FormikController
              name="email"
              error
              label="User Name"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PopUpCareGiverUserView;
