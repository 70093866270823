import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { observer } from "mobx-react";
import "../../CSS/Alert.css";

const customAlert = React.forwardRef((props, ref) => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const [openErrorMsg, setOpenErrorMsg] = React.useState("");
  const [openSuccessMsg, setOpenSuccessMsg] = React.useState("");
  const [openWarningMsg, setOpenWarningMsg] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    success(msg) {
      setOpenSuccess(true);
      setOpenSuccessMsg(msg);
    },
    error(msg) {
      setOpenError(true);
      setOpenErrorMsg(msg);
    },
    warning(msg) {
      setOpenWarning(true);
      setOpenWarningMsg(msg);
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
    setOpenWarning(false);
  };

  return (
    <Stack spacing={2} sx={{ position: "fixed" }} style={{ zIndex: 1000 }}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: 80, right: 18 }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          className="successMainContainer"
        >
          <div className="alertTextContainer">
            <span className="alertTitle">Success: &nbsp;</span>
            <span className="alertMessage">{openSuccessMsg}</span>
          </div>
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: 80, right: 18 }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="error"
          className="errorMainContaner"
        >
          <div className="alertTextContainer">
            <span className="errorTitle">Error: &nbsp;</span>
            <span className="alertMessage">{openErrorMsg}</span>
          </div>
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={openWarning}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: 80, right: 18 }}
      >
        <MuiAlert
          onClose={handleClose}
          severity="warning"
          className="alertPopup"
          sx={{
            width: "100%",
            backgroundColor: "#FFC107",
            color: "#333333",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: "bold" }}>Warning: &nbsp;</span>{" "}
            {openWarningMsg}
          </div>
        </MuiAlert>
      </Snackbar>
    </Stack>
  );
});

export default observer(customAlert);
