import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import "../../../CSS/AccountAccess.css";
import AdminEmployeeStore from "../../../Store/AdminEmployeeStore";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import NotificationStore from "../../../Store/NotificationStore";
import { observer } from "mobx-react";
import FormikController from "../../../components/FormikController/FormikController";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

const ActionPopUp = ({ onConfirm, clickHTML, type }) => {
  const [open, setOpen] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    AdminEmployeeStore.setAdminRoles("");
    NotificationStore.setEmpSingleNotiDetailsAllData([]);
  };

  useEffect(() => {
    if (NotificationStore?.empSingleNotiDetailsAllData?.resultSet) {
      setEmployeeDetails(
        NotificationStore?.empSingleNotiDetailsAllData?.resultSet
      );
    }
  }, [NotificationStore?.empSingleNotiDetailsAllData?.resultSet]);

  const getCategoryProfileLink = (category, profileId) => {
    switch (category) {
      case "CareGiver":
        return `/agency/careGiverProfile/${profileId}`;
      case "AdminEmployee":
        return `/agency/adminProfile/${profileId}`;
      case "CommunityCoordinator":
        return `/agency/communityOutReachCoordinatorProfile/${profileId}`;
      case "RN":
        return `/agency/Nurse_Profile/${profileId}`;
      default:
        return `/agency/Client_profile/${profileId}`;
    }
  };

  const renderProfileLink = (category, profileId, text) => (
    <Link
      to={getCategoryProfileLink(category, profileId)}
      className="gridItemName"
    >
      {text}
    </Link>
  );

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="accountAndAccessDialog"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Details
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="action-popup-content">
          <div className="notificationDetails">
            <div className="notificationTitle">
              <div className="formInputContainer">
                <FormikController
                  name="title"
                  label="Title"
                  type="text"
                  control="textfield"
                  disabled
                  value={
                    NotificationStore?.empSingleNotiDetailsAllData?.summary
                      ?.notification?.title
                  }
                />
              </div>
            </div>
            <div className="notificationDescription">
              <div className="formInputContainer">
                <FormikController
                  name="description"
                  multiline
                  label="Description"
                  type="text"
                  minRows={2}
                  maxRows={4}
                  control="textfield"
                  disabled
                  value={
                    NotificationStore?.empSingleNotiDetailsAllData?.summary
                      ?.notification?.description
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className="ClientListMainContainer">
              <div className="ESNDheaderContainer">
                <div className="headerItem">Unique ID</div>
                <div className="headerItem">Name</div>
                <div className="headerItem headerItemRight">Phone</div>
              </div>

              {employeeDetails.length === 0 ? (
                <div className="noRecordsMessage">No records available</div>
              ) : (
                employeeDetails.map((item, index) => (
                  <div className="ESNDgridContainer" key={index}>
                    <div className="gridItem">
                      {renderProfileLink(
                        NotificationStore?.empSingleNotiDetailsAllData?.summary
                          ?.notification?.employeeCategory,
                        item.profileId,
                        type === "client"
                          ? item.clientMra
                          : item.employeeUniqueId
                      )}
                    </div>

                    <div className="gridItem">
                      <div className="avatarNameContainer">
                        <Avatar {...stringAvatar(`${item.fullName} `)} />
                        <div className="gridItemNameEmail">
                          {renderProfileLink(
                            NotificationStore?.empSingleNotiDetailsAllData
                              ?.summary?.notification?.employeeCategory,
                            item.employeeProfileId,
                            `${item.fullName} `
                          )}
                          <div className="gridItemEmail">{item.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className="SESgridItem">{item.phoneNo}</div>
                  </div>
                ))
              )}
            </div>
          </div>{" "}
        </div>
      </Dialog>
    </div>
  );
};

export default observer(ActionPopUp);
