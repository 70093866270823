import React, { useEffect } from "react";
import "../../../CSS/CareGiverSecurity.css";
import FormikController from "../../../components/FormikController/FormikController";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import PopUpCareGiverCreateAccount from "./PopUpCareGiverCreateAccount";
import PopUpCareGiverUserView from "./PopUpCareGiverUserView";
import "../../../CSS/AccountAccess.css";
import PopUpAdminEmployeeUserView from "./PopUpAdminEmployeeUserView";
import GlobelStore from "../../../Store/GlobelStore";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import ClientStore from "../../../Store/ClientStore";

const PopUpCareGiverSecurity = observer(() => {
  const enableDisableEmployeeAccount = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      employeeUserId: CareGiverStore.isCareGiverAccountCreated,

      enable: values,
    };

    axios
      .post(CareGiverURL.ENABLEDISABLE_EMPLOYEEACCOUNT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          if (GlobelStore.accountAccessPopUpSelectedOn == "careGiver") {
            GlobelStore.getEmployeeList("CareGiver");
          } else if (
            GlobelStore.accountAccessPopUpSelectedOn == "adminEmployee"
          ) {
            GlobelStore.getEmployeeList("AdminEmployee");
          } else if (GlobelStore.accountAccessPopUpSelectedOn == "nurse") {
            GlobelStore.getEmployeeList("RN");
          } else {
            GlobelStore.getEmployeeList("CommunityCoordinator");
          }
          const actionMessage = values
            ? "successfully activated"
            : "successfully deactivated";
          AgencyStore.showAlert("success", `User ID has been ${actionMessage}`);
        } else {
          AgencyStore.showAlert("error", "Failed to change action");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to change action");
      });
  };

  const enableDisableClientAccount = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientUserId: CareGiverStore.isCareGiverAccountCreated,

      enable: values,
    };

    axios
      .post(ClientURL.ENABLEDISABLE_CLIENT_ACCOUNT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          // ClientStore.getClientProfile(ClientStore.ClientAllData.client.id);
          ClientStore.getClientList();

          const actionMessage = values
            ? "successfully activated"
            : "successfully deactivated";

          AgencyStore.showAlert("success", `User ID has been ${actionMessage}`);
        } else {
          AgencyStore.showAlert("error", "Failed to change action");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to change action");
      });
  };

  return (
    <>
      {CareGiverStore.isCareGiverAccountCreated == 0 ? (
        <div>
          <PopUpCareGiverCreateAccount />
        </div>
      ) : (
        <>
          <div>
            {GlobelStore.accountAccessPopUpSelectedOn === "careGiver" ||
            GlobelStore.accountAccessPopUpSelectedOn === "client" ||
            GlobelStore.accountAccessPopUpSelectedOn === "nurse" ? (
              <PopUpCareGiverUserView />
            ) : (
              <PopUpAdminEmployeeUserView />
            )}
          </div>
          <div className="deactivateAccountContainer">
            {GlobelStore.employeeData.isAccountEnable == true ? (
              // {GlobelStore?.employeeData?.accountDetails?.isAccountEnable ==
              // true ? (
              <>
                <div className="CGSHeader">Deactivate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to deactivate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Deactivate Account"
                  title="Deactivate Account"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    if (GlobelStore.accountAccessPopUpSelectedOn === "client") {
                      enableDisableClientAccount(false);
                    } else {
                      enableDisableEmployeeAccount(false);
                    }
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Deactivate Account"
                        control="buttons"
                        className="buttonSelectedRed menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            ) : (
              <>
                <div className="CGSHeader">Activate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to Activate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Activate Account"
                  title="Activate"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    if (GlobelStore.accountAccessPopUpSelectedOn === "client") {
                      enableDisableClientAccount(true);
                    } else {
                      enableDisableEmployeeAccount(true);
                    }
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Activate Account"
                        control="buttons"
                        className="buttonSelected menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default PopUpCareGiverSecurity;
