import Draggable from "react-draggable";
import { Paper } from "@mui/material";
import { StatusTypeMap, posTypeMap, roleNames } from "../Constants/Constants";

export const setDateInFomat = (inputDateString) => {
  var dateObject = new Date(inputDateString);
  var year = dateObject.getFullYear();
  var month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  var day = dateObject.getDate().toString().padStart(2, "0");
  var outputDateString = year + "-" + month + "-" + day;
  return outputDateString;
};

export const splitName = (name) => {
  const maxLength = 15;
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

export const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export const getRolesName = (item) => {
  const roleName = Object.keys(roleNames).find((role) => item.includes(role));
  return roleName ? roleNames[roleName] : item;
};

export const getStatusTypeName = (label) => {
  var matchingFileType;
  StatusTypeMap.forEach((file) => {
    const key = file.uniqueName;
    if (key == label) {
      matchingFileType = file.displayName;
    }
  });

  return matchingFileType;
};

export const getPosTypeId = (label) => {
  var matchingFileType;
  posTypeMap.forEach((file) => {
    const key = file.displayName;
    if (key == label) {
      matchingFileType = file.uniqueName;
    }
  });

  return matchingFileType;
};

export const getPosTypeName = (label) => {
  var matchingFileType;
  posTypeMap.forEach((file) => {
    const key = file.uniqueName;
    if (key == label) {
      matchingFileType = file.displayName;
    }
  });

  return matchingFileType;
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function todayDate() {
  return new Date();
}

export function twoWeeksBackDate() {
  const twoWeeksBack = new Date();
  twoWeeksBack.setDate(todayDate().getDate() - 14);
  return twoWeeksBack;
}

export const formatDate = (date) => {
  if (typeof date === "string") {
    date = new Date(date); // Convert string to Date
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatTimeHHMM = (time) => {
  if (!time) return ""; // Return an empty string or a default value if time is not defined

  const [hours, minutes] = time.split(":");
  return `${hours}:${minutes}`;
};

export const formatDateTimeForAPI = (datetime) => {
  if (!datetime) return "";
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = "00"; // Always append seconds as "00"

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
