import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormikController from "../../components/FormikController/FormikController";
import { YupValidation } from "../../Constants/ValidationConstants";
import AgencyStore from "../../Store/AgencyStore";
import ClientStore from "../../Store/ClientStore";
import { PaperComponent } from "../../Utils/Utils";
import {
  NurseAndCareGiverURL,
  checkExpiryDate,
  getFileTypeId,
} from "../../Url/Url";
import CareGiverStore from "../../Store/CareGiverStore";
import { AiOutlineClose } from "react-icons/ai";

const NurseAndCareGiverPopUp = ({
  uniqueNameType,
  onConfirm,
  onCancel,
  clickHTML,
}) => {
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      fileData: "",
      expiryDate: "",
    },
    validationSchema: Yup.object({
      fileData: YupValidation.file_type,
      expiryDate: checkExpiryDate.includes(getFileTypeId(uniqueNameType))
        ? YupValidation.expiryDate
        : YupValidation.expiryDate_without_require,
    }),
    onSubmit: (values) => {
      saveSingleFile(values);
    },
  });

  const saveSingleFile = async (values) => {
    AgencyStore.setIsLoading(true);

    const params = new FormData();

    params.append("document", values.fileData);
    params.append("docUniqueName", getFileTypeId(uniqueNameType));
    if (values.expiryDate) {
      params.append("docExpiryDate", values.expiryDate);
    }

    await axios
      .post(NurseAndCareGiverURL.SAVE_SINGLEFIle, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          CareGiverStore.getEmployeeDocs();

          AgencyStore.showAlert("success", uniqueNameType + " Saved");
          handleClose();
          formik.resetForm();
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Fail to save " + uniqueNameType);
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Fail to save " + uniqueNameType);
      });
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="xs"
      >
        <div className="borderBottom">
          <div>
            <DialogTitle
              id="draggable-dialog-title"
              className="DialogTitleContainer"
            >
              Upload File
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
              <div className="fileUploadMainContainer">
                <div className="formInputContainer">
                  <FormikController
                    name="fileData"
                    label={uniqueNameType}
                    type="file"
                    accept=".pdf"
                    control="file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "fileData",
                        event.currentTarget.files[0]
                      );
                    }}
                    touch={formik.touched.fileData}
                    errorname={formik.errors.fileData}
                  />
                </div>
                {checkExpiryDate.includes(getFileTypeId(uniqueNameType)) ? (
                  <div className="formInputContainer">
                    <FormikController
                      name="expiryDate"
                      error
                      label="Expiry Date"
                      type="date"
                      control="textfield"
                      {...formik.getFieldProps("expiryDate")}
                      touch={formik.touched.expiryDate}
                      errorname={formik.errors.expiryDate}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <FormikController
            name="Upload"
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelectedGreen fixedWidth"
            fullWidth="false"
          />
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              handleClose();
            }}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(NurseAndCareGiverPopUp);
