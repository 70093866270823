import { useState } from "react";

const useHandleState = (initialState) => {
  const [state, setState] = useState(initialState);
  const handleChange = (key) => (event, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleTrue = () => {
    setState(true);
  };

  const handleFalse = () => {
    setState(false);
  };

  return { state, setState, handleChange, handleTrue, handleFalse };
};

export default useHandleState;
