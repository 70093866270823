import { observer } from "mobx-react";
import React, { useEffect } from "react";

import "../../CSS/Profile.css";
import "../../CSS/NurseAndCareGiver.css";

import Profile from "./Profile";
import CareGiverStore from "../../Store/CareGiverStore";

import PageTitle from "../../Agency/PageTitle";

import Icon from "../../components/Icons/Icon";

const ProfileMainViewPage = observer(() => {
  const [value, setValue] = React.useState("1");

  const initialPanelsState = {
    panel1: true,
  };

  const [expandedPanels, setExpandedPanels] =
    React.useState(initialPanelsState);

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanels((prevExpanded) => ({
      ...prevExpanded,
      [panel]: newExpanded,
    }));
  };

  const employeeId = localStorage.getItem("id");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    CareGiverStore.getProfile();
  }, []);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle=" My Detail"
          subHeaderTitle="Profile/My Detail"
        />
      </div>
      <div className="profileMainContainer">
        <div className="NACGMainDiv">
          <Profile />
        </div>
      </div>
    </div>
  );
});

export default ProfileMainViewPage;
