import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "../../../CSS/Calendar.css";
import "../../../CSS/FullCalenderEvents.css";

const FullcalendarEvent = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    alert(info.event.title);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const events = [
    {
      title: "Visit customer 1",
      start: "2023-10-06T10:00:00",
      end: "2023-10-06T12:00:00",
    },
    {
      title: "Visit customer2",
      start: "2023-10-05T14:30:00",
      end: "2023-10-05T16:00:00",
    },
    {
      title: "Visit customer3",
      start: "2023-10-05T14:30:00",
      end: "2023-10-05T16:00:00",
    },
  ];

  return (
    <FullCalendar
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridDay,listMonth",
      }}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      events={events}
      eventClick={handleEventClick}
    />
  );
};

export default FullcalendarEvent;
