import { observer } from "mobx-react";
import React from "react";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import CareGiverStore from "../../../Store/CareGiverStore";
import { YupValidation } from "../../../Constants/ValidationConstants";

const CareGiverUserView = observer(() => {
  const formik = useFormik({
    initialValues: {
      email: CareGiverStore.careGiverProfileData?.email || "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
    }),
  });

  return (
    <div>
      <div className="CGSHeader">Account</div>
      <div className="changePasswordFormMainContainer">
        <div className="formMainUniqueContainer">
          <div>
            <FormikController
              name="email"
              label="User Name"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CareGiverUserView;
