import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import SearchBox from "../../../components/Search/SearchBox";
import CareGiverStore from "../../../Store/CareGiverStore";
import ClientListTable from "./ClientListTable";
import PageTitle from "../../PageTitle";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";
import GlobelStore from "../../../Store/GlobelStore";
import {
  clientStatusFilter,
  county,
  leadsStatusDropDown,
} from "../../../Constants/Constants";

const ClientList = observer(() => {
  const [cocList, setCocList] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const navigate = useNavigate();
  const [
    handleSearchChange,
    handleActiveCheckBox,
    handleActiveDropDown,
    handleDropDownValue,
  ] = useSearchData();
  const handleAddAdminEmployee = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/add_client");
  };

  useEffect(() => {
    const response = CommunityCoordinatorStore.communityCoordinatorList;
    const finalData = response.map((item) => ({
      value: item.id,
      label: `${item.fullName} (${item.employeeId})`,
    }));
    setCocList(finalData);
  }, [CommunityCoordinatorStore?.communityCoordinatorList]);

  useEffect(() => {
    GlobelStore.getEmployeeList("CommunityCoordinator");
  }, []);

  const handleAddFilter = () => {
    $(".allFlterContainer").toggle("slow");
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Client"
          subHeaderTitle=" Customer/Client"
        />
        <div>
          <div className="actionButonContainer">
            <FormikController
              name="Add Client"
              control="buttons"
              className="roundedButton menuItemButton addCareGiverButton addButton"
              startIcon={<Icon iconName="groupAdd" />}
              onClick={() => handleAddAdminEmployee()}
            />
          </div>
        </div>
      </div>
      <div className="actionButtonSearchMainContainer">
        <div className="leadSearchMainCotainer">
          <div className="searchFilterBtnContainer">
            <div className="searchContainer">
              <SearchBox
                handleChange={(e) => {
                  const newValue = e.target.value;
                  handleDropDownValue("searchQuery", newValue, "Client");
                }}
              />
            </div>
            <div className="activeAddButtonContainer">
              <div>
                <div
                  className="underlineClick"
                  onClick={() => {
                    handleAddFilter();
                  }}
                >
                  <Icon
                    iconName="downArrow"
                    className={`downArrowIcon ${
                      isFilterOpen ? "rotateArrow" : ""
                    }`}
                  />
                  {`${isFilterOpen ? "Close Filter" : "Open Filter"}`}
                </div>
              </div>
            </div>
          </div>
          <div className="allFlterContainer">
            <div className="fiterMainContainer">
              <div className="countyDropdownContainer">
                <div class="title">County</div>
                <FormikController
                  control="selectByInput"
                  options={county}
                  handleChange={(event, value) => {
                    if (value?.value) {
                      handleDropDownValue("county", value?.value, "Client");
                    } else {
                      handleDropDownValue("county", "", "Client");
                    }
                  }}
                />
              </div>
              <div className="statusDropdownContainer">
                <div class="title">Status</div>
                <FormikController
                  control="selectByInput"
                  options={clientStatusFilter}
                  handleChange={(event, value) => {
                    if (value?.value) {
                      handleDropDownValue("status", value?.value, "Client");
                    } else {
                      handleDropDownValue("status", "", "Client");
                    }
                  }}
                />
              </div>
              <div className="cocDropdownContainer">
                <div class="title">COC incharge</div>
                <FormikController
                  control="selectByInput"
                  options={cocList}
                  handleChange={(event, value) => {
                    if (value?.value) {
                      handleDropDownValue(
                        "cocIncharge",
                        value?.value,
                        "Client"
                      );
                    } else {
                      handleDropDownValue("cocIncharge", "", "Client");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientListTable customerType="client" />
    </div>
  );
});

export default ClientList;
