import { styled } from "@mui/material/styles";
import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import "../../CSS/AddClient.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Accordions = React.memo((props) => {
  const { title, accordianComponent, expanded, OnhandleChange } = props;
  return (
    <>
      <Accordion
        className="accordionMainContainer"
        expanded={expanded}
        onChange={OnhandleChange}
      >
        <AccordionSummary
          className="accordionSummaryMainContainer"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <span className="accordionTitle">{title}</span>
        </AccordionSummary>
        <AccordionDetails>{accordianComponent}</AccordionDetails>
      </Accordion>
    </>
  );
});

export default Accordions;
