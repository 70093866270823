import React, { useEffect } from "react";
import { observer } from "mobx-react";
import NurseAndCareGiverDocViewerList from "../../Agency/Pages/DocViewerList/NurseAndCareGiverDocViewerList";
import CareGiverStore from "../../Store/CareGiverStore";

const DocConfidential = () => {
  useEffect(() => {
    CareGiverStore.getEmployeeDocs();
  }, []);

  return (
    <div>
      <NurseAndCareGiverDocViewerList confidentialResultSet="confidentialResultSet" />
    </div>
  );
};

export default observer(DocConfidential);
