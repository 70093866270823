import { Button } from "@mui/material";
import React from "react";
import "../../CSS/Button.css";
const Buttons = (props) => {
  const { name, ...otherProps } = props;

  const buttonConfig = {
    fullWidth: true,
    variant:"contained",
    ...otherProps
    
  };
  return (
    <>
      <Button {...buttonConfig} className={otherProps.className} >
        <span className="capitalized-text">{name}</span>
      </Button>
    </>
  );
};

export default Buttons;
