import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import "../../../CSS/AccountAccess.css";
import { observer } from "mobx-react";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import * as Yup from "yup";
import AgencyStore from "../../../Store/AgencyStore";
import ClientStore from "../../../Store/ClientStore";
import { ManualEmailSmsURL } from "../../../Url/Url";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DialogActions } from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SendEmailSMS = ({ onConfirm, clickHTML, type, selectedIds }) => {
  const { clientId } = useParams();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [sendEmail, setSendEmail] = useState(false); // State for Email switch
  const [sendSms, setSendSms] = useState(false); // State for SMS switch

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },

    validationSchema: Yup.object({
      title: YupValidation.requiredOnly,
      description: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (type == "client") {
        sendEmailSmsClient(values);
      } else {
        sendEmailSmsEmployee(values);
      }
    },
  });

  const sendEmailSmsEmployee = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      receiverIds: selectedIds,
      title: values.title,
      description: values.description,
      sendEmail: sendEmail,
      sendSms: sendSms,
    };
    axios
      .post(ManualEmailSmsURL.SEND_EMAIL_SMS_EMPLOYEE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully Send");
          setOpen(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to Send");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Send");
      });
  };

  const sendEmailSmsClient = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      receiverIds: selectedIds,
      title: values.title,
      description: values.description,
      sendEmail: sendEmail,
      sendSms: sendSms,
    };
    axios
      .post(ManualEmailSmsURL.SEND_EMAIL_SMS_CLIENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully Send");
          setOpen(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to Send");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Send");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = ClientStore.individualDocterCaseNoteData;

    formik.setValues({
      caseNote: result?.caseNote || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [clientId, ClientStore.individualDocterCaseNoteData]);

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Email/SMS
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content">
          <div className="marginTop clientDoctorCaseNoteInputContainer">
            <div className="formInputContainer">
              <FormikController
                name="title"
                error
                label="Title"
                type="text"
                control="textfield"
                {...formik.getFieldProps("title")}
                touch={formik.touched.title}
                errorname={formik.errors.title}
              />
            </div>
            <div className="formInputContainer">
              <FormikController
                name="description"
                error
                multiline
                label="Description"
                type="text"
                minRows={2}
                maxRows={4}
                control="textfield"
                {...formik.getFieldProps("description")}
                touch={formik.touched.description}
                errorname={formik.errors.description}
                fullWidth="true"
              />
            </div>
            <div>
              <div className="switchLabel">
                <input
                  type="checkbox"
                  checked={sendEmail}
                  onChange={(event) => setSendEmail(event.target.checked)}
                />
                Email
              </div>
            </div>
          </div>
        </div>
        <DialogActions className="DialogActionsContainer">
          <div>
            {ClientStore?.caseNotestatus === "update" ? (
              <FormikController
                name="Update Case Note"
                control="buttons"
                onClick={() => {
                  setStatus("update");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            ) : (
              <FormikController
                name="Send Email/SMS"
                control="buttons"
                onClick={() => {
                  setStatus("save");
                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(SendEmailSMS);
