import React from "react";
import { BiPlus } from "react-icons/bi";
import { RiAdminFill, RiDashboard2Fill } from "react-icons/ri";
import { FaHandHoldingHeart } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { FaUserNurse } from "react-icons/fa";
import { FaWheelchair } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdLockPerson } from "react-icons/md";
import { ImUsers } from "react-icons/im";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaUserMd } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { BiSolidCalendar } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import { PiNotePencilBold } from "react-icons/pi";
import { PiSignatureDuotone } from "react-icons/pi";
import { FaAnglesDown } from "react-icons/fa6";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";

const Icon = ({ iconName, className }) => {
  switch (iconName) {
    case "admin":
      return <RiAdminFill className={className} />;
    case "careGiver":
      return <FaHandHoldingHeart className={className} />;
    case "coordinator":
      return <ImUsers className={className} />;
    case "dashboard":
      return <RiDashboard2Fill className={className} />;
    case "notifications":
      return <IoNotifications className={className} />;
    case "mail":
      return <IoMdMail className={className} />;
    case "client":
      return <FaWheelchair className={className} />;
    case "nurse":
      return <FaUserNurse className={className} />;
    case "schedule":
      return <BiSolidCalendar className={className} />;
    case "access":
      return <MdLockPerson className={className} />;
    case "log":
      return <PiNotePencilBold className={className} />;
    case "document":
      return <IoDocumentTextOutline className={className} />;
    case "account":
      return <BsShieldLockFill className={className} />;
    case "profile":
      return <FaUserCircle className={className} />;
    case "menu":
      return <RxHamburgerMenu className={className} />;
    case "doctor":
      return <FaUserMd className={className} />;
    case "add":
      return <BiPlus />;
    case "groupAdd":
      return <AiOutlineUsergroupAdd />;
    case "visible":
      return <MdOutlineVisibility className={className} />;
    case "invisible":
      return <MdOutlineVisibilityOff className={className} />;
    case "circleAdd":
      return <IoIosAddCircleOutline className={className} />;
    case "signature":
      return <PiSignatureDuotone className={className} />;
    case "downArrow":
      return <FaAnglesDown className={className} />;
    case "edit":
      return <FaRegPenToSquare className={className} />;
    case "delete":
      return <RiDeleteBin6Line className={className} />;
    case "view":
      return <FaRegEye className={className} />;
    default:
      return null;
  }
};
export default Icon;
