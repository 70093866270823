import React, { useEffect } from "react";
import "../../../CSS/CareGiverSecurity.css";
import FormikController from "../../../components/FormikController/FormikController";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import axios from "axios";
import ClientCreateAccount from "./ClientCreateAccount";
import ClientUserView from "./ClientUserView";
import ClientStore from "../../../Store/ClientStore";

const ClientSecurity = observer(() => {
  const { clientId } = useParams();

  const enableDisableClientAccount = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientUserId: CareGiverStore.isCareGiverAccountCreated,

      enable: values,
    };

    axios
      .post(ClientURL.ENABLEDISABLE_CLIENT_ACCOUNT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfile(clientId);

          const actionMessage = values
            ? "successfully activated"
            : "successfully deactivated";

          AgencyStore.showAlert("success", `User ID has been ${actionMessage}`);
        } else {
          AgencyStore.showAlert("error", "Failed to change action");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to change action");
      });
  };

  useEffect(() => {
    CareGiverStore.setIsCareGiverAccountCreated(
      ClientStore.ClientAllData.client.userId
    );
  }, [clientId, ClientStore.ClientAllData]);
  return (
    <>
      {CareGiverStore.isCareGiverAccountCreated == 0 ? (
        <div>
          <ClientCreateAccount />
        </div>
      ) : (
        <>
          <div className="mainContainer">
            <ClientUserView />
          </div>
          <div className="mainContainer">
            {ClientStore.ClientAllData.accountDetails.isAccountEnable ==
            true ? (
              <>
                <div className="CGSHeader">Deactivate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to deactivate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Deactivate Account"
                  title="Deactivate Account"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    enableDisableClientAccount(false);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Deactivate Account"
                        control="buttons"
                        className="buttonSelectedRed menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            ) : (
              <>
                <div className="CGSHeader">Activate Account</div>
                <div className="confirmationSection">
                  Are you sure you want to Activate your account?
                </div>
                <ConfirmPopUp
                  buttonLabel="Activate Account"
                  title="Activate"
                  content=" Please confirm your decision below."
                  onConfirm={() => {
                    enableDisableClientAccount(true);
                  }}
                  buttonClassName="DeactivateAccount" // Pass the class name
                  clickHTML={
                    <div className="buttonContainer">
                      <FormikController
                        name="Activate Account"
                        control="buttons"
                        className="buttonSelected menuItemButton marginGap"
                        fullWidth={false}
                      />
                    </div>
                  }
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ClientSecurity;
