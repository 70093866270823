import React from "react";
import { View, Text, Page, Document } from "@react-pdf/renderer";
import CareGiverStore from "../../../Store/CareGiverStore";

import { styles } from "./CommonUtilsPdf";
import PdfFooter from "./PdfFooter";

const TableFormPdf = () => {
  let id = 1;

  const ChangeLogTitle = () => (
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>Manual Change Log</Text>
    </View>
  );
  const TableHead = () => (
    <View style={styles.theader} fixed>
      <View style={styles.theaders}>
        <Text style={styles.tableItem}>Id</Text>
      </View>
      <View style={styles.theaders}>
        <Text style={styles.tableItem}>Log Date Change</Text>
      </View>
      <View style={styles.theaders}>
        <Text style={styles.tableItem}>Who Change</Text>
      </View>
      <View style={styles.theaders}>
        <Text style={styles.tableItem}>What Change</Text>
      </View>
      <View style={styles.theaders}>
        <Text style={styles.tableItem}>Case Notes</Text>
      </View>
    </View>
  );

  const TableBody = () =>
    CareGiverStore.employeeChangeLog.map((item) => {
      return (
        <View style={styles.theader}>
          <View style={styles.tbody}>
            <Text>{id++}</Text>
          </View>
          <View style={styles.tbody}>
            <Text> {new Date(item.changeDate).toLocaleDateString()} </Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.whoChanged}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.whatChanged}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.caseNote}</Text>
          </View>
        </View>
      );
    });

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <ChangeLogTitle />
          <TableHead />
          <TableBody />
          <PdfFooter />
        </Page>
      </Document>
    </>
  );
};

export default TableFormPdf;
