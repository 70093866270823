import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { LogURL } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles } from "../../../Constants/Constants";
import {
  fullNameCellRenderer,
  createColumnDef,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";

const LogListTable = observer(() => {
  const [changeLogData, setChangeLogData] = useState([]);
  const [onGridReady] = useOnGridReady(CareGiverStore, "careGiverAgGrid");
  const columnDefs = useMemo(
    () => [
      createColumnDef(
        "modifierEmail",
        "Modifier",
        leftStyles,
        fullNameCellRenderer,
        "",
        90
      ),
      createColumnDef(
        "changedSection",
        "Section",
        leftStyles,
        fullNameCellRenderer,
        "",
        50
      ),
      createColumnDef(
        "changedDescription",
        "Description",
        leftStyles,
        fullNameCellRenderer,
        "",
        130
      ),
      createColumnDef(
        "modificationOf",
        "Modification of",
        leftStyles,
        fullNameCellRenderer,
        "",
        70
      ),
      createColumnDef(
        "victimEmail",
        "Email",
        leftStyles,
        fullNameCellRenderer,
        "",
        100
      ),
      createColumnDef(
        "victimUniqueCode",
        "ID",
        leftStyles,
        fullNameCellRenderer,
        "",
        30
      ),
      createColumnDef(
        "modifiedDate",
        "Modified Date",
        leftStyles,
        fullNameCellRenderer,
        "",
        70
      ),
    ],
    []
  );

  const getChangeLogs = async () => {
    AgencyStore.setIsLoading(true);
    await axios
      .get(LogURL.GET_LOG_LIST, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status === "success") {
          setChangeLogData(res?.data?.resultSet);
        } else {
          AgencyStore.showAlert(
            "error",
            "An error  while retrieving the change log."
          );
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "An error occurred while retrieving the change log."
        );
      });
  };

  useEffect(() => {
    getChangeLogs();
  }, []);

  if (changeLogData.length > 0) {
    CareGiverStore.setTableHeight(changeLogData.length);
  }

  return (
    <FormikController
      rowData={changeLogData}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
});

export default LogListTable;
