import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";
import SearchBox from "../../../components/Search/SearchBox";
import FormikController from "../../../components/FormikController/FormikController";
import SendEmailSMS from "./SendEmailSMS";
import SpamError from "./SpamError";
import { CellRenderer, useOnGridReady } from "../../../Utils/AgGridUtils";
import useSearchData from "../../../components/useSearchData";
import { FaCommentSms } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const CommunityOutReachCoordinatorList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  const [showError, setShowError] = useState(false);
  const [onGridReady, onFirstDataRendered, selectedIds] = useOnGridReady(
    CommunityCoordinatorStore,
    "communityCoordinatorAgGrid",
    true
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      navigate("/agency/communityOutReachCoordinatorProfile/" + employeeId);
    };
    return (
      <div onClick={onFullNameClick} class="ag-inner-cell" title={params.value}>
        {params.value}
      </div>
    );
  };
  const columnDefs = useMemo(
    () => [
      {
        field: "id",
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 60,
        cellRenderer: () => {
          return null;
        },
      },
      {
        field: "employeeId",
        headerName: "#ID",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "fullName",
        headerName: "FullName",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "status",
        headerName: "Status",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{ color: params.value == "Active" ? "green" : "red" }}
            >
              {params.value}
            </div>
          );
        },
      },
      {
        field: "phoneNo",
        headerName: "Phone No.",
        filter: true,

        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: CellRenderer,
      },
      {
        field: "email",
        headerName: "Email",
        filter: true,

        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: CellRenderer,
      },
    ],
    []
  );

  function isRowSelectable(rowNode) {
    if (rowNode.data && rowNode.data.status == "Not Working") {
      return false;
    }
    return true;
  }
  const getRowStyle = (params) => {
    if (params.data.status == "Not Working") {
      return { background: "lightgray" };
    }
    return null;
  };

  const handleSendEmailSMS = () => {
    if (selectedIds.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };
  return (
    <div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "CommunityCoordinator");
              }}
            />
          </div>

          <div className="manualNotificationBtnContainer">
            <div className="CDCNunderlineClickBtnContainer">
              {showError && <SpamError />}
              {selectedIds.length === 0 ? (
                <div
                  className="underlineClick"
                  onClick={() => {
                    handleSendEmailSMS();
                  }}
                >
                  <FaCommentSms />
                  Send Email/SMS
                </div>
              ) : (
                <SendEmailSMS
                  type="docterCaseNote"
                  selectedIds={selectedIds}
                  clickHTML={
                    <div
                      className="underlineClick"
                      onClick={() => {
                        handleSendEmailSMS();
                      }}
                    >
                      <FaCommentSms />
                      Send Email/SMS
                    </div>
                  }
                />
              )}
            </div>

            <div>
              <input
                id="careGiver_active"
                type="checkbox"
                onChange={(e) => {
                  handleActiveCheckBox(e, "Active", "CommunityCoordinator");
                }}
              />
              <span className="checkBoxTitle">Active</span>
            </div>
          </div>
        </div>
      </div>

      <FormikController
        rowData={CommunityCoordinatorStore.communityCoordinatorList}
        control="agGrid"
        gridOptions={{
          isRowSelectable: isRowSelectable,
        }}
        getRowStyle={getRowStyle}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        rowSelection="multiple"
        rowMultiSelectWithClick={true}
      />
    </div>
  );
});

export default CommunityOutReachCoordinatorList;
