import React from "react";
import LogListTable from "./LogListTable";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";

import CareGiverStore from "../../../Store/CareGiverStore";
import SearchBox from "../../../components/Search/SearchBox";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";

const LogList = observer(() => {
  const handleSearchChange = (e) => {
    CareGiverStore.setCareGiverSearch(e.target.value);
    getQuickFilter();
  };

  const getQuickFilter = () => {
    let finalData =
      CareGiverStore.careGiverSearch +
      " " +
      CareGiverStore.careGiverActiveCheckState;
    CareGiverStore.careGiverAgGrid?.setQuickFilter(finalData);
    CareGiverStore.setTableHeight(
      CareGiverStore.careGiverAgGrid.getDisplayedRowCount()
    );
  };

  const handleActiveCheckBox = (e) => {
    if (e.target.checked) {
      let userName = localStorage.getItem("userName");
      CareGiverStore.setCareGiverActiveCheckState(userName.split("@")[0]);
    } else {
      CareGiverStore.setCareGiverActiveCheckState("");
    }
    getQuickFilter();
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu"/>}
          headerTitle="Change Log"
          subHeaderTitle="Other/Change Log"
        />
      </div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox handleChange={handleSearchChange} />
          </div>
          <div>
            <input
              id="careGiver_active"
              type="checkbox"
              onChange={(e) => {
                handleActiveCheckBox(e);
              }}
            />{" "}
            <span className="checkBoxTitle">My changes</span>
          </div>
        </div>
      </div>
      <LogListTable />
    </div>
  );
});

export default LogList;
