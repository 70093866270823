import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AgencyStore from "../../../Store/AgencyStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import axios from "axios";
import { CareGiverURL, ClientURL } from "../../../Url/Url";
import ClientStore from "../../../Store/ClientStore";
import NurseAndCareGiverPopUp from "../../../NurseAndCareGiver/Document/NurseAndCareGiverPopUp";

const NurseAndCareGiverDocViewerList = observer(
  ({
    govResultSet,
    applicationResumeAndHireResultSet,
    confidentialResultSet,
  }) => {
    // const employeeId = id ? id : useParams()?.employeeId;
    // CareGiverStore.setSelectedEmloyeeId(employeeId);
    const downloadPDF = (data, fileName) => {
      AgencyStore.downloadFile(data, fileName);
    };
    const openPdf = (data) => {
      AgencyStore.openPDF(data);
    };

    // const formik = useFormik({
    //   initialValues: {
    //     uniqueCode: "",
    //   },

    //   validationSchema: Yup.object({
    //     uniqueCode: Yup.string().required("Unique Code Required"),
    //   }),
    //   onSubmit: (values) => {
    //     if (clientDocument == "clientDocument") {
    //       if (ClientStore?.ClientAllData?.client?.mra === values?.uniqueCode) {
    //         CareGiverStore.setIsUniqueCodeEntered(true);
    //       } else {
    //         CareGiverStore.setIsUniqueCodeEntered(false);
    //         AgencyStore.showAlert("error", "Invalid Unique code");
    //       }
    //     } else {
    //       if (
    //         CareGiverStore.careGiverProfileData.employeeId === values.uniqueCode
    //       ) {
    //         CareGiverStore.setIsUniqueCodeEntered(true);
    //       } else {
    //         CareGiverStore.setIsUniqueCodeEntered(false);
    //         AgencyStore.showAlert("error", "Invalid Unique code");
    //       }
    //     }
    //   },
    // });

    const addNewFile = (data) => {
      CareGiverStore.setFileUploadPopUp(true);
      CareGiverStore.setSelectedDoc(data);
    };

    // const deleteEmployeeDocument = async (doc) => {
    //   AgencyStore.setIsLoading(true);
    //   const params = { employeeProfileId: employeeId, docId: doc.id };
    //   await axios
    //     .post(CareGiverURL.DELETE_EMPLOYEEDOC, params, {
    //       headers: { Authorization: localStorage.getItem("loginToken") },
    //     })
    //     .then((res) => {
    //       if (res?.data?.status === "success") {
    //         CareGiverStore.getConfidentialDoc(employeeId);
    //         AgencyStore.setIsLoading(false);
    //         AgencyStore.showAlert(
    //           "success",
    //           "The document has been successfully deleted."
    //         );
    //       } else {
    //         AgencyStore.setIsLoading(false);
    //         AgencyStore.showAlert("error", "Failed to delete the document.");
    //       }
    //     })
    //     .catch((err) => {
    //       AgencyStore.setIsLoading(false);
    //       AgencyStore.showAlert("error", "Failed to delete the document.");
    //     });
    // };

    // const deleteClientDocument = async (doc) => {
    //   AgencyStore.setIsLoading(true);

    //   const params = { clientProfileId: doc?.clientProfileId, docId: doc?.id };
    //   await axios
    //     .post(ClientURL.DELETE_CLIENT_DOCUMENT, params, {
    //       headers: { Authorization: localStorage.getItem("loginToken") },
    //     })
    //     .then((res) => {
    //       if (res?.data?.status === "success") {
    //         ClientStore.getClientDocument(doc?.clientProfileId);
    //         AgencyStore.setIsLoading(false);
    //         AgencyStore.showAlert(
    //           "success",
    //           "The document has been successfully deleted."
    //         );
    //       } else {
    //         AgencyStore.setIsLoading(false);
    //         AgencyStore.showAlert("error", "Failed to delete the document.");
    //       }
    //     })
    //     .catch((err) => {
    //       AgencyStore.setIsLoading(false);
    //       AgencyStore.showAlert("error", "Failed to delete the document.");
    //     });
    // };

    const [responseData, setResponseData] = useState({});
    const careGiverAllDocList = CareGiverStore?.careGiverAllDocList;
    useEffect(() => {
      if (govResultSet === "govResultSet") {
        setResponseData(careGiverAllDocList?.govResultSet);
      } else if (confidentialResultSet === "confidentialResultSet") {
        setResponseData(careGiverAllDocList?.confidentialResultSet);
      } else if (
        applicationResumeAndHireResultSet == "applicationResumeAndHireResultSet"
      ) {
        setResponseData(careGiverAllDocList?.applicationResumeAndHireResultSet);
      }
    }, [
      govResultSet,
      applicationResumeAndHireResultSet,
      confidentialResultSet,
      careGiverAllDocList,
      ClientStore?.clientAllDocList,
    ]);

    return (
      <div className="docviewMainContainer">
        {/* {CareGiverStore.isUniqueCodeEntered === false ? (
          <div className="listMainDivContainer enterCodeContainer">
            <div>
              <FormikController
                name="uniqueCode"
                error
                label="Unique Code"
                type="text"
                control="textfield"
                {...formik.getFieldProps("uniqueCode")}
                touch={formik.touched.uniqueCode}
                errorname={formik.errors.uniqueCode}
              />
            </div>
            <FormikController
              name="Submit code"
              control="buttons"
              onClick={formik.handleSubmit}
              className="buttonSelected fixedWidth"
              fullWidth="false"
            />
          </div>
        ) : ( */}

        {responseData && (
          <div className="listMainDivContainer">
            {Object.entries(responseData).map(([key, documents]) => (
              <div key={key} className="docDetailsMainContainer">
                <div className="docTypeTitle">
                  <div>{key}</div>
                  <NurseAndCareGiverPopUp
                    uniqueNameType={key}
                    clickHTML={
                      <div className="docAddContainer">
                        <AiOutlinePlusCircle className="docAddIcon" /> Add New
                      </div>
                    }
                  />
                </div>
                {documents.map((document) => (
                  <div
                    key={document.id}
                    className={`docDetailsContainer ${(document.isAdminApproved =
                      true ? "" : "notApproved")}`}
                  >
                    <div>
                      <div className="docTitle">{document.fileName}</div>
                      <div className="docSubTitlemainContainer">
                        <div>
                          Uploaded Date:{" "}
                          {new Date(
                            document.docUploadedDate
                          )?.toLocaleDateString()}
                        </div>

                        {document.fileExpiryDate && (
                          <div>
                            Expiry Date:{" "}
                            {new Date(
                              document.fileExpiryDate
                            )?.toLocaleDateString()}
                          </div>
                        )}

                        {/* <ConfirmPopUp
                          title="Delete"
                          content="You are about to perform a critical action that cannot be undone. Please confirm your decision below."
                          onConfirm={() => {
                            // if (clientDocument == "clientDocument") {
                            //   deleteClientDocument(document);
                            // } else {
                            //   deleteEmployeeDocument(document);
                            // }
                          }}
                          clickHTML={
                            <div className="docDeleteText">Delete</div>
                          }
                        /> */}

                        <div
                          className="docDownloadText"
                          onClick={() => {
                            downloadPDF(document.filePath, document.fileName);
                          }}
                        >
                          Download
                        </div>
                      </div>
                    </div>
                    <div
                      className="docViewText"
                      onClick={() => {
                        openPdf(document.filePath);
                      }}
                    >
                      View
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default NurseAndCareGiverDocViewerList;
