import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "../../CSS/Calendar.css";
import "../../CSS/FullCalenderEvents.css";
import { observer } from "mobx-react";
import CalenderStore from "../../Store/CalenderStore";
import ClientStore from "../../Store/ClientStore";
import AgencyStore from "../../Store/AgencyStore";
// import AgencyStore from "../../../Store/AgencyStore";

const FullcalendarEvent = () => {
  const [events, setEvents] = useState([]);
  const handleEventClick = (info) => {
    CalenderStore.setSpecificCalendarAllData(
      info.event.extendedProps.customData
    );
    ClientStore.getEmployeeClientRelation(
      info.event.extendedProps.customData.employeeProfileId
    );
    CalenderStore.setIsSwipeableTemporaryDrawer("update");
    CalenderStore.setOpenDrawer(true);
  };

  useEffect(() => {
    // const calenderData = CalenderStore?.employeeAllCalendarData?.resultSet;
    const calenderData = CalenderStore?.specificEmployeeCalendar?.resultSet;

    if (calenderData) {
      const calenderDataFinalData = calenderData.map((item) => ({
        title: item.title,
        start: item.dateTime,
        end: item.dateTime,
        customData: item,
      }));
      setEvents(calenderDataFinalData);
    }
  }, [
    CalenderStore?.specificEmployeeCalendar?.resultSet,
    AgencyStore?.isLeftContainerVisible,
  ]);

  return (
    <FullCalendar
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridDay,listMonth",
      }}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      events={events}
      eventClick={handleEventClick}
      dayMaxEvents={true} // Enable eventLimit for all non-TimeGrid views
      views={{
        timeGrid: {
          dayMaxEvents: 2, // Adjust to 6 only for timeGridWeek/timeGridDay
        },
      }}
      eventContent={(eventInfo) => {
        const customData = eventInfo.event.extendedProps.customData;
        const isExpired = new Date(customData.dateTime) < new Date();
        const isUpcoming = new Date(customData.dateTime) > new Date();
        const visitType = customData?.visitType;

        const eventClasses = ["event-container"];
        if (isExpired || customData.status == "CANCLED") {
          eventClasses.push("expired-event");
        } else if (isUpcoming && visitType == "INITIAL_ASSESSMENT") {
          eventClasses.push("upcoming-event InitialVisit");
        } else if (isUpcoming && visitType == "THREE_MONTHS_VISIT") {
          eventClasses.push("upcoming-event QuaterlyVisit");
        } else if (isUpcoming && visitType == "ANNUAL") {
          eventClasses.push("upcoming-event AnuallVisit");
        } else if (isUpcoming && visitType == "WHEN_THEY_MOVE") {
          eventClasses.push("upcoming-event ClientMoves");
        } else if (isUpcoming && visitType == "CARE_GIVER_CHANGE") {
          eventClasses.push("upcoming-event CaregiverChanged");
        }

        return (
          <div className={eventClasses.join(" ")}>
            <div className="event-title">
              {eventInfo.event.title +
                " (" +
                customData.clientLastName +
                " " +
                customData.clientFirstName +
                ")"}
            </div>
          </div>
        );
      }}
    />
  );
};

export default observer(FullcalendarEvent);
