import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import { observer } from "mobx-react";
import { useFormik } from "formik";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import * as Yup from "yup";
import FormikController from "../../../../components/FormikController/FormikController";
import { Autocomplete, DialogActions, TextField } from "@mui/material";
import ClientStore from "../../../../Store/ClientStore";
import CareGiverStore from "../../../../Store/CareGiverStore";
import { CareGiverURL } from "../../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../../Store/AgencyStore";
import { useParams } from "react-router-dom";
import GlobelStore from "../../../../Store/GlobelStore";
import {
  formatDate,
  formatDateTimeForAPI,
  formatTimeHHMM,
  todayDate,
  twoWeeksBackDate,
} from "../../../../Utils/Utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AddClockInOut = ({ onConfirm, clickHTML, type, selectedIds }) => {
  const [open, setOpen] = useState(false);
  const { employeeId } = useParams();
  const [status, setStatus] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientFullName, setClientFullName] = useState("");

  const today = todayDate();
  const twoWeeksBack = twoWeeksBackDate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setClientFullName("");

    GlobelStore?.setEmployeeData({});
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      clientId: "",
      clockInDateTime: "",
      clockOutDateTime: "",
    },

    validationSchema: Yup.object({
      clientId: YupValidation.requiredOnly,
      clockInDateTime: YupValidation.requiredOnly,
      clockOutDateTime: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (status === "save") {
        addClockInClockOut(values);
      } else {
        updateEmpClockInOutDetail(values);
      }
    },
  });

  const addClockInClockOut = async (values) => {
    console.log(values);
    AgencyStore.setIsLoading(true);
    const params = {
      employeeId: employeeId,
      clientId: values.clientId,
      clockInDateTime: formatDateTimeForAPI(values.clockInDateTime),
      clockOutDateTime: formatDateTimeForAPI(values.clockOutDateTime),
    };

    await axios
      .post(CareGiverURL.ADD_EMP_CLOCKINOUT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpClockInOutDetails(
            employeeId,
            formatDate(twoWeeksBack),
            formatDate(today)
          );
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully added");
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to add Clock In/Out");
        }
        handleClose();
        formik.resetForm();
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to add Clock In/Out");
      });
  };

  const updateEmpClockInOutDetail = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: GlobelStore?.employeeData?.id,
      employeeId: GlobelStore?.employeeData?.employeeId,
      clientId: values.clientId,
      clockInDateTime: formatDateTimeForAPI(values.clockInDateTime),
      clockOutDateTime: formatDateTimeForAPI(values.clockOutDateTime),
    };

    await axios
      .post(CareGiverURL.UPDATE_EMP_CLOCKINOUT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpClockInOutDetails(
            employeeId,
            formatDate(twoWeeksBack),
            formatDate(today)
          );
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully Updated");
          formik.resetForm();
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Clock In/Out");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to updated Clock In/Out");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = GlobelStore?.employeeData || {};
    const clockInDateTime = result?.clockInDateTime
      ? formatTimeHHMM(result.clockInDateTime)
      : "";
    const clockOutDateTime = result?.clockOutDateTime
      ? formatTimeHHMM(result.clockOutDateTime)
      : "";
    formik.setValues({
      clientId: result?.clientId || "",
      clockInDateTime: clockInDateTime,
      clockOutDateTime: clockOutDateTime,
    });
    const clientResponse = ClientStore?.employeeClientRelationAllData;

    const clientData = clientResponse.find(
      (item) => item.id === result?.clientId
    );

    const clientFinalLabel = clientData
      ? `${clientData.lastName} ${clientData.firstName} (${clientData.mra})`
      : "";
    setClientFullName(clientFinalLabel);
  };

  useEffect(() => {
    setProfileDataFormik();
  }, [GlobelStore.employeeData?.clientId]);

  useEffect(() => {
    const clientResponse = ClientStore?.employeeClientRelationAllData;
    const clientFinalData = clientResponse.map((item) => ({
      value: item.id,
      label: `${item.lastName} ${item.firstName} (${item.mra})`,
    }));
    setClientList(clientFinalData);
  }, [ClientStore?.employeeClientRelationAllData]);

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Clock In/Out
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content">
          <div className="clientDoctorCaseNoteInputContainer">
            <div className="formInputContainer">
              <div className="title">{"Client"}</div>

              <Autocomplete
                fullWidth
                size="small"
                options={clientList}
                onChange={(event, value) => {
                  formik.setFieldValue("clientId", value?.value);
                  setClientFullName(value?.label);
                }}
                value={clientFullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
              <div className="error">
                {formik.touched.clientId && formik.errors.clientId
                  ? formik.errors.clientId
                  : ""}
              </div>
            </div>
            <div className="formInputContainer">
              <FormikController
                name="clockInDateTime"
                error
                label="Clock In"
                type="datetime-local"
                control="textfield"
                values={formik.values.clockInDateTime}
                {...formik.getFieldProps("clockInDateTime")}
                touch={formik.touched.clockInDateTime}
                errorname={formik.errors.clockInDateTime}
              />
            </div>
            <div className="formInputContainer">
              <FormikController
                name="clockOutDateTime"
                error
                label="Clock Out"
                // type="time"
                type="datetime-local"
                control="textfield"
                values={formik.values.clockOutDateTime}
                {...formik.getFieldProps("clockOutDateTime")}
                touch={formik.touched.clockOutDateTime}
                errorname={formik.errors.clockOutDateTime}
              />
            </div>
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            {type === "update" ? (
              <FormikController
                name="Update"
                control="buttons"
                onClick={() => {
                  setStatus("update");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            ) : (
              <FormikController
                name="Add Clock In/Out"
                control="buttons"
                onClick={() => {
                  setStatus("save");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            )}
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={() => {
              handleClose();
            }}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(AddClockInOut);
