import axios from "axios";
import { action, makeObservable, observable } from "mobx";
import { ServerURL, hostUrl } from "../Url/Url";

class AgencyStore {
  companyName = "Greater Baltimore Homecare LLC";
  copyRight = "2023";
  selectedMenuItem = localStorage.getItem("selectedMenuItem")
    ? localStorage.getItem("selectedMenuItem")
    : "dashboard";
  isLeftContainerVisible = true;
  isLoading = false;
  branch = [];
  customAlert = null;

  constructor() {
    makeObservable(this, {
      customAlert: observable,
      companyName: observable,
      copyRight: observable,
      selectedMenuItem: observable,
      isLeftContainerVisible: observable,
      isLoading: observable,
      branch: observable,
      setIsLoading: action,
      setSelectedMenuItem: action,
      setIsLeftContainerVisible: action,
      openPDF: action,
      downloadFile: action,
      setBranch: action,
      setCustomAlert: action,
      showAlert: action,
      validateLoginToken: action,
    });
  }

  validateLoginToken = async () => {
    const isAuthenticated = localStorage.getItem("loginToken");

    if (!isAuthenticated) {
      return false;
    }

    const params = {
      loginToken: isAuthenticated,
    };

    try {
      const res = await axios.post(ServerURL.CHECK_TOKEN, params);

      if (res?.data === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  setCustomAlert = (data) => {
    this.customAlert = data;
  };

  showAlert = (type, message) => {
    if (this.customAlert) {
      if (type === "success") {
        this.customAlert.success(message);
      } else if (type === "error") {
        this.customAlert.error(message);
      } else if (type === "warning") {
        this.customAlert.warning(message);
      }
    }
  };

  downloadFile = (url, fileName) => {
    const signedUrl = url;
    fetch(signedUrl, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to download file: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  openPDF = (data) => {
    const pdfUrl = data;
    window.open(pdfUrl, "_blank");
  };

  setBranch = (data) => {
    this.branch = data;
  };

  setIsLoading = (data) => {
    this.isLoading = data;
  };
  setSelectedMenuItem = (data) => {
    this.selectedMenuItem = data;
    localStorage.setItem("selectedMenuItem", data);
  };

  setIsLeftContainerVisible = (data) => {
    this.isLeftContainerVisible = data;
  };
}

export default new AgencyStore();
