import React, { useEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import ClientProfile from "./ClientProfile";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import GlobelStore from "../../../Store/GlobelStore";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import Icon from "../../../components/Icons/Icon";
const AddClient = observer(({ customerType }) => {
  const { clientId } = useParams();

  // const getEmployeeList = async (employeeType) => {
  //   AgencyStore.setIsLoading(true);
  //   const params = {
  //     employeeCategory: employeeType,
  //   };
  //   await axios
  //     .post(CareGiverURL.GET_EMLOYEE_LIST, params, {
  //       headers: { Authorization: localStorage.getItem("loginToken") },
  //     })
  //     .then((res) => {
  //       if (employeeType == "CommunityCoordinator") {
  //       }

  //       AgencyStore.setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       AgencyStore.showAlert("error", "Failed to get employee list ");
  //       AgencyStore.setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getEmployeeList("CommunityCoordinator");
  // }, []);

  return (
    <div>
      {clientId ? (
        ""
      ) : (
        <div className="pageHeaderContainer">
          <PageTitle
            icon={<Icon iconName="menu" />}
            headerTitle={customerType === "client" ? "Client" : "Leads"}
            subHeaderTitle={
              customerType === "client"
                ? "Customer/Client/Profile"
                : "Leads/Profile"
            }
          />
        </div>
      )}

      <ClientProfile />
    </div>
  );
});

export default AddClient;
