import React, { useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { nurseVisitType } from "../../../Constants/Constants";
import { useParams } from "react-router-dom";
import ClientStore from "../../../Store/ClientStore";
import * as Yup from "yup";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import { Grid } from "@mui/material";
import Icon from "../../../components/Icons/Icon";

import AgencyStore from "../../../Store/AgencyStore";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import { format } from "date-fns";

function createData(
  id,
  medication,
  classifications,
  dose,
  frequency,
  funSeAndSpecialConsideration
) {
  return {
    id,
    medication,
    classifications,
    dose,
    frequency,
    funSeAndSpecialConsideration,
  };
}

const initialRows = [
  createData(1, "", "", "", "", ""),
  createData(2, "", "", "", "", ""),
  createData(3, "", "", "", "", ""),
];

const AddMedication = () => {
  const [rows, setRows] = useState(initialRows);

  const addRow = () => {
    const newRowId = rows.length + 1;
    setRows([...rows, createData(newRowId, "", "", "", "", "")]);
  };

  const { clientId } = useParams();

  const formik = useFormik({
    initialValues: {
      visitType: "",
      manualDate: "",
      wt: "",
      allergies: "",
      md: "",
      mdPhoneNo: "",
      pharmacy: "",
      pharmacyPhoneNo: "",
      specialInstructions: "",
      agencyRepresentativeAndTitle: "",
      manualArtDate: "",
      medication: "",
      classifications: "",
      dose: "",
      frequency: "",
      funSeAndSpecialConsideration: "",
    },

    validationSchema: Yup.object({
      visitType: YupValidation.nurseVisitType,
      manualDate: YupValidation.manualDate,
      wt: YupValidation.requiredOnly,
      allergies: YupValidation.allergies,
      md: YupValidation.requiredOnly,
      mdPhoneNo: YupValidation.phone,
      pharmacy: YupValidation.requiredOnly,
      pharmacyPhoneNo: YupValidation.phone,
      specialInstructions: YupValidation.specialInstructions,
      agencyRepresentativeAndTitle: YupValidation.requiredOnly,
      manualArtDate: YupValidation.requiredOnly,
      medication: "",
      classifications: "",
      dose: "",
      frequency: "",
      funSeAndSpecialConsideration: "",
    }),
    onSubmit: (values) => {
      saveMedicationRecord(values);
      // console.log(values);
    },
  });

  const saveMedicationRecord = (values) => {
    const clientMedicationRecordetailsList = rows.map((row) => ({
      medication: values[`medication${row.id}`],
      classifications: values[`classifications${row.id}`],
      dose: values[`dose${row.id}`],
      frequency: values[`frequency${row.id}`],
      funSeAndSpecialConsideration:
        values[`funSeAndSpecialConsideration${row.id}`],
    }));
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: clientId,
      employeeProfileId: 97,
      visitType: values.visitType,
      manualDate: format(new Date(values.manualDate), "yyyy-MM-dd HH:mm:ss"),
      wt: values.wt,
      allergies: values.allergies,
      md: values.md,
      mdPhoneNo: values.mdPhoneNo,
      pharmacy: values.pharmacy,
      pharmacyPhoneNo: values.pharmacyPhoneNo,
      specialInstructions: values.specialInstructions,
      agencyRepresentativeAndTitle: values.agencyRepresentativeAndTitle,
      manualArtDate: format(
        new Date(values.manualArtDate),
        "yyyy-MM-dd HH:mm:ss"
      ),
      clientMedicationRecordetailsList: clientMedicationRecordetailsList,
    };
    axios
      .post(ClientURL.SAVE_CLIENT_MEDICATION_RECORD, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.showAlert(
            "success",
            "Successfully Saved Medication Records List"
          );

          ClientStore.setMedicationRecordList(res?.data?.result);

          AgencyStore.setIsLoading(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to saved Medication Record List"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to saved Medication Record List"
        );
      });
  };
  const [saveUpdateState, setSaveUpdateState] = useState("");

  const handlesaveUpadateState = (value) => {
    setSaveUpdateState(value);
  };

  return (
    <>
      <div className="ClientListContainer">
        <div className="clientListinputFileds">
          <div>Visit Type:</div>
          <div className="clientListinputFiled">
            <FormikController
              control="selects"
              {...formik.getFieldProps("visitType")}
              error
              touch={formik.touched.visitType}
              errorname={formik.errors.visitType}
              selectitem={nurseVisitType}
              // disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="medicationWrapper">
        <div className="medicationSection">
          <div className="heading">Medication Record</div>
          <div className="detailsWrapper">
            <Grid container spacing={3}>
              <Grid item xs={6} className="rowSection">
                <div className="nameList">Patient Name:</div>
                <div className="medicationForm">
                  <FormikController
                    control="textfield"
                    value={
                      ClientStore.ClientAllData.client?.firstName +
                      "  " +
                      ClientStore.ClientAllData.client?.lastName
                    }
                    variant="standard"
                  />
                </div>
              </Grid>

              <Grid item xs={4} className="rowSection">
                Date:
                <div className="medicationForm">
                  <FormikController
                    name="manualDate"
                    error
                    control="textfield"
                    type="datetime-local"
                    {...formik.getFieldProps("manualDate")}
                    touch={formik.touched.manualDate}
                    errorname={formik.errors.manualDate}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>

              <Grid item xs={2} className="rowSection">
                WT:
                <div className="medicationForm">
                  <FormikController
                    name="wt"
                    error
                    control="textfield"
                    {...formik.getFieldProps("wt")}
                    touch={formik.touched.wt}
                    errorname={formik.errors.wt}
                    variant="standard"

                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className="rowSection">
                <div className="nameList">Allergies:</div>
                <div className="medicationForm">
                  <FormikController
                    name="allergies"
                    error
                    control="textfield"
                    {...formik.getFieldProps("allergies")}
                    touch={formik.touched.allergies}
                    errorname={formik.errors.allergies}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>

              <Grid item xs={6} className="rowSection">
                <div className="nameList"> M.D:</div>
                <div className="medicationForm">
                  <FormikController
                    name="md"
                    error
                    control="textfield"
                    {...formik.getFieldProps("md")}
                    touch={formik.touched.md}
                    errorname={formik.errors.md}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
              <Grid item xs={6} className="rowSection">
                {" "}
                Phone:
                <div className="medicationForm">
                  <FormikController
                    name="mdPhoneNo"
                    error
                    control="textfield"
                    {...formik.getFieldProps("mdPhoneNo")}
                    touch={formik.touched.pharmacy}
                    errorname={formik.errors.pharmacy}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
              <Grid item xs={6} className="rowSection">
                {" "}
                <div className="nameList"> Pharmacy:</div>
                <div className="medicationForm">
                  <FormikController
                    name="pharmacy"
                    error
                    control="textfield"
                    {...formik.getFieldProps("pharmacy")}
                    touch={formik.touched.pharmacy}
                    errorname={formik.errors.pharmacy}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
              <Grid item xs={6} className="rowSection">
                {" "}
                Phone:{" "}
                <div className="medicationForm">
                  <FormikController
                    name="pharmacyPhoneNo"
                    error
                    control="textfield"
                    {...formik.getFieldProps("pharmacyPhoneNo")}
                    touch={formik.touched.pharmacyPhoneNo}
                    errorname={formik.errors.pharmacyPhoneNo}
                    variant="standard"
                    // disabled={isSaved ? true : false}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <TableContainer>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell">Medication</TableCell>
                  <TableCell className="tablecell">Classifications</TableCell>
                  <TableCell className="tablecell">DOSE</TableCell>
                  <TableCell className="tablecell">FREQUENCY</TableCell>
                  <TableCell className="tablecell">
                    Functions, SE’s and Special Consideration
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" className="tablecell">
                      <FormikController
                        name={`medication${row.id}`}
                        control="textfield"
                        {...formik.getFieldProps(`medication${row.id}`)}
                        className="textline"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        // disabled={isSaved ? true : false}
                      />
                    </TableCell>
                    <TableCell className="tablecell">
                      <FormikController
                        name={`classifications${row.id}`}
                        control="textfield"
                        {...formik.getFieldProps(`classifications${row.id}`)}
                        className="textline"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        // disabled={isSaved ? true : false}
                      />
                    </TableCell>
                    <TableCell className="tablecell">
                      <FormikController
                        name={`dose${row.id}`}
                        control="textfield"
                        {...formik.getFieldProps(`dose${row.id}`)}
                        className="textline"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        // disabled={isSaved ? true : false}
                      />
                    </TableCell>
                    <TableCell className="tablecell">
                      <FormikController
                        name={`frequency${row.id}`}
                        control="textfield"
                        {...formik.getFieldProps(`frequency${row.id}`)}
                        className="textline"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        // disabled={isSaved ? true : false}
                      />
                    </TableCell>
                    <TableCell className="tablecell">
                      <FormikController
                        name={`funSeAndSpecialConsideration${row.id}`}
                        control="textfield"
                        {...formik.getFieldProps(
                          `funSeAndSpecialConsideration${row.id}`
                        )}
                        className="textline"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        // disabled={isSaved ? true : false}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="medicationContainer">
              <div className="underlineClick" onClick={addRow}>
                <Icon iconName="circleAdd" />
                Add Row
              </div>
            </div>
          </TableContainer>

          <Grid container spacing={2} pb={5}>
            <Grid item xs={12} pb={3}>
              Special Instructions:
              <div className="medicationForm">
                <FormikController
                  name="specialInstructions"
                  error
                  control="textfield"
                  {...formik.getFieldProps("specialInstructions")}
                  touch={formik.touched.specialInstructions}
                  errorname={formik.errors.specialInstructions}
                />
              </div>
            </Grid>

            <Grid item xs={9} className="rowSection">
              Agency Representative and Title:
              <div className="medicationForm">
                <FormikController
                  name="agencyRepresentativeAndTitle"
                  error
                  control="textfield"
                  {...formik.getFieldProps("agencyRepresentativeAndTitle")}
                  touch={formik.touched.agencyRepresentativeAndTitle}
                  errorname={formik.errors.agencyRepresentativeAndTitle}
                  variant="standard"
                  // disabled={isSaved ? true : false}
                />
              </div>
            </Grid>
            <Grid item xs={3} className="rowSection">
              Date:
              <div className="medicationForm">
                <FormikController
                  name="manualArtDate"
                  error
                  control="textfield"
                  type="datetime-local"
                  {...formik.getFieldProps("manualArtDate")}
                  touch={formik.touched.manualArtDate}
                  errorname={formik.errors.manualArtDate}
                  variant="standard"
                  // disabled={isSaved ? true : false}
                />
              </div>
            </Grid>
          </Grid>

          <div className="save">
            <ConfirmPopUp
              buttonLabel="Save "
              title="Save "
              content=" Please confirm your decision below."
              onConfirm={() => {
                handlesaveUpadateState("save");
                formik.handleSubmit();
              }}
              clickHTML={
                <FormikController
                  control="buttons"
                  name="Save Medication Records"
                  className="buttonSelectedGreen fixedWidth"
                  fullWidth="false"
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMedication;
