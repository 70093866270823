import React, { useEffect, useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import { createColumnDef, useOnGridReady } from "../../../Utils/AgGridUtils";
import ClientStore from "../../../Store/ClientStore";
import { useParams } from "react-router-dom";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import CareGiverStore from "../../../Store/CareGiverStore";

const CareGiverPosList = () => {
  const [onGridReady] = useOnGridReady(ClientStore, "safetyAgGrid");
  const { clientId } = useParams();
  const [posList, setPosList] = useState([]);

  const columnDefs = useMemo(
    () => [
      createColumnDef("clientName", "Client Name", leftStyles, "", "", 90),

      createColumnDef("visitType", "Visit Type", leftStyles, "", "", 70),
      createColumnDef("systemEntryDate", "Upload Date", leftStyles, "", "", 80),
      createColumnDef(
        "lastUpdatedDate",
        "Modified Date",
        leftStyles,
        "",
        "",
        80
      ),

      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        width: 100,
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            console.log(params.data);
            ClientStore.setSafetyRecordList(params.data);
            // navigate(`/agency/Client_profile/home_safety/${params.data.id}`);
          };
          const handleDownloadButton = () => {
            // openPdf(<HomeSafetyPdf dataList={params.data} />);
          };
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {/* <div className="manageClick" onClick={() => handleButtonClick()}>
                Edit
              </div> */}
              <div
                className="docDownloadText"
                onClick={() => handleDownloadButton()}
              >
                View
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchposList = async () => {
      try {
        await ClientStore.getCaregiverPOS(clientId);
        getRecordList();
      } catch (error) {
        console.error("Error fetching care giver plan of service list:", error);
      }
    };

    fetchposList();
  }, []);

  const getRecordList = () => {
    const data = [];
    Object.entries(ClientStore.carePosRecordList).forEach(
      ([category, items]) => {
        if (items.length > 0) {
          items.forEach((eachItem) => {
            data.push(eachItem);
          });
        }
      }
    );
    setPosList(data);
    CareGiverStore.setTableHeight(data?.length);
  };
  console.log(posList);

  return (
    <FormikController
      rowData={posList}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default CareGiverPosList;
