import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import "../../CSS/Input.css";

export const Radios = (props) => {
  const { title, radioLabel, ...otherProps } = props;

  return (
    <>
      <div className="radioTitle">{title}</div>
      <RadioGroup {...otherProps}>
        {radioLabel.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
            error={
              otherProps.touch && otherProps.errorname
                ? otherProps.error
                : false
            }
          />
        ))}
      </RadioGroup>
      <div className="error">
        {otherProps.touch && otherProps.errorname ? otherProps.errorname : ""}
      </div>
    </>
  );
};
