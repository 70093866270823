import { Text } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./CommonUtilsPdf";
const PdfFooter = () => {
  return (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
      fixed
    />
  );
};

export default PdfFooter;
