import { observer } from "mobx-react";
import React from "react";
import AgencyStore from "../Store/AgencyStore";
import "../CSS/Footer.css";
const Footer = observer(() => {
  return (
    <div className="footerMainContainer">
      &copy; {AgencyStore.copyRight + " " + AgencyStore.companyName}
    </div>
  );
});

export default Footer;
