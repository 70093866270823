import { observer } from "mobx-react";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import AgencyStore from "../../../Store/AgencyStore";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { Button, InputAdornment } from "@mui/material";
import { ChangePassword } from "../../../Url/Url";
import axios from "axios";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Security = observer(() => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [show, setshow] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const handleClick = (field) => {
    setshow((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: YupValidation.oldPassword,
      newPassword: YupValidation.password,
      confirmPassword: YupValidation.confirmPassword,
    }),
    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const changePassword = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      currentPass: values.oldPassword,
      newPass: values.newPassword,
    };
    axios
      .post(ChangePassword.Change_Password, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "successfully Password Change");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to Change Password");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Change password");
      });
  };
  return (
    <div className="securityMainContainer">
      <Accordion
        className="accordionMainContainer"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          className="accordionSummaryMainContainer"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <span className="accordionTitle">Change Password</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="changePasswordFormMainContainer">
            <div className="currentPasswordFormMainContainer">
              <div>
                <FormikController
                  name="oldPassword"
                  error
                  label="Current Password"
                  control="textfield"
                  type={show.oldPassword ? "text" : "password"}
                  touch={formik.touched.oldPassword}
                  errorname={formik.errors.oldPassword}
                  {...formik.getFieldProps("oldPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => handleClick("oldPassword")}
                      >
                        {show.oldPassword ? (
                          <MdOutlineVisibility
                            className={
                              formik.touched.oldPassword &&
                              formik.errors.oldPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className={
                              formik.touched.oldPassword &&
                              formik.errors.oldPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="newPasswordFormMainContainer">
              <div>
                <FormikController
                  name="newPassword"
                  error
                  label="New Password"
                  control="textfield"
                  type={show.newPassword ? "text" : "password"}
                  touch={formik.touched.newPassword}
                  errorname={formik.errors.newPassword}
                  {...formik.getFieldProps("newPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => handleClick("newPassword")}
                      >
                        {show.newPassword ? (
                          <MdOutlineVisibility
                            className={
                              formik.touched.newPassword &&
                              formik.errors.newPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className={
                              formik.touched.newPassword &&
                              formik.errors.newPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <FormikController
                  name="confirmPassword"
                  error
                  label="Confirm New Password"
                  control="textfield"
                  type={show.confirmPassword ? "text" : "password"}
                  touch={formik.touched.confirmPassword}
                  errorname={formik.errors.confirmPassword}
                  {...formik.getFieldProps("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => handleClick("confirmPassword")}
                      >
                        {show.confirmPassword ? (
                          <MdOutlineVisibility
                            className={
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        ) : (
                          <MdOutlineVisibilityOff
                            className={
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                                ? "errors"
                                : "icon"
                            }
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="changePasswordInfoMainContainer">
            <div className="changePasswordTitle">Password Requirements:</div>
            <div className="changePasswordSubTitle">
              <li>Minimum 8 characters long - the more, the better</li>
              <li>At least one lowercase character</li>
              <li>At least one number, symbol, or whitespace character</li>
            </div>
          </div>
          <div className="changePasswordButtonContainer">
            <FormikController
              name="Save Changes"
              control="buttons"
              type="submit"
              onClick={formik.handleSubmit}
              className="buttonSelected menuItemButton"
              fullWidth={false}
            />

            <Button
              className="buttonUnselected menuItemButton"
              variant="contained"
              onClick={formik.resetForm}
            >
              <span className="capitalized-text">Reset</span>
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default Security;
