import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react";
import CareGiverStore from "../../Store/CareGiverStore";
import FormikController from "../FormikController/FormikController";
import {
  ClientURL,
  NurseAndCareGiverURL,
  checkExpiryDate,
  getFileTypeId,
} from "../../Url/Url";
import { useFormik } from "formik";
import * as Yup from "yup";
import AgencyStore from "../../Store/AgencyStore";
import axios from "axios";
import { YupValidation } from "../../Constants/ValidationConstants";
import ClientStore from "../../Store/ClientStore";
import { PaperComponent } from "../../Utils/Utils";
import { nurseVisitType } from "../../Constants/Constants";
import NurseAndCareGiverEmployeeStore from "../../Store/NurseAndCareGiverEmployeeStore";
import { AiOutlineClose } from "react-icons/ai";

const ClientFileUploadPopUp = observer(() => {
  const userType = localStorage.getItem("userType");

  const formik = useFormik({
    initialValues: {
      fileData: "",
      expiryDate: "",
      uploadDate: "",
      visitType: "",
      // assessmentDocType: CareGiverStore.selectedDoc,
      // assessmentDocType: "",
    },
    validationSchema: Yup.object({
      fileData: YupValidation.file_type,
      uploadDate: YupValidation.expiryDate,
      expiryDate: checkExpiryDate.includes(
        getFileTypeId(CareGiverStore.selectedDoc)
      )
        ? YupValidation.expiryDate
        : YupValidation.expiryDate_without_require,
      visitType: YupValidation.requiredOnly,
      // assessmentDocType: YupValidation.requiredOnly,
    }),
    onSubmit: (values) => {
      if (userType == "RN" || userType == "CareGiver") {
        saveSingleFileClientNursingAssessmentDocForNCPortal(values);
      } else {
        saveSingleFileClientNursingAssessmentDoc(values);
      }
    },
  });

  const saveSingleFileClientNursingAssessmentDoc = async (values) => {
    AgencyStore.setIsLoading(true);

    const clientProfileId = ClientStore.ClientAllData.client.id;
    const params = new FormData();

    if (clientProfileId) {
      params.append("clientProfileId", clientProfileId);

      params.append("uploadDateManual", values.uploadDate);
      params.append("file", values.fileData);
      params.append("visitType", values.visitType);
      params.append(
        "assessmentDocType",
        getFileTypeId(CareGiverStore.selectedDoc)
      );

      // if (values.expiryDate) {
      //   params.append("expiryDate", values.expiryDate);
      // }
    }

    await axios
      .post(ClientURL.UPLOAD_NURSINGASSESSMENT_DOCUMENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          if (CareGiverStore?.selectedDoc == "Nursing Assessment") {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "NURSING_ASSESSMENT"
            );
          } else if (CareGiverStore?.selectedDoc == "Medication Records List") {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "MEDICATION_RECORDS_LIST"
            );
          } else if (CareGiverStore?.selectedDoc == "Home Safety") {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "HOME_SAFETY"
            );
          } else if (CareGiverStore?.selectedDoc == "Client Care Plan") {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CLIENT_CARE_PLAN"
            );
          } else if (
            CareGiverStore?.selectedDoc == "Caregiver Evaluation Form"
          ) {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CAREGIVER_EVALUATION_FORM"
            );
          } else if (
            CareGiverStore?.selectedDoc == "Caregiver Plan of Service"
          ) {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CAREGIVER_PLAN_OF_SERVICE"
            );
          } else if (
            CareGiverStore?.selectedDoc ==
            "Competency Checklist Personal Care Aide"
          ) {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
            );
          } else if (
            CareGiverStore?.selectedDoc ==
            "Participant Review And Caregiver Supervisory Visit"
          ) {
            ClientStore.getNursingAssessmentDocuments(
              clientProfileId,
              "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
            );
          }

          AgencyStore.showAlert(
            "success",
            CareGiverStore.selectedDoc + " Saved"
          );
          ClientStore.setFileUploadPopUp(false);
          formik.resetForm();
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Fail to save " + CareGiverStore.selectedDoc
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Fail to save " + CareGiverStore.selectedDoc
        );
      });
  };

  const saveSingleFileClientNursingAssessmentDocForNCPortal = async (
    values
  ) => {
    AgencyStore.setIsLoading(true);

    const clientProfileId = ClientStore.ClientAllData.client.id;
    const params = new FormData();

    if (clientProfileId) {
      params.append("clientProfileId", clientProfileId);

      params.append("uploadDateManual", values.uploadDate);
      params.append("file", values.fileData);
      params.append("visitType", values.visitType);
      params.append(
        "assessmentDocType",
        getFileTypeId(CareGiverStore.selectedDoc)
      );

      // if (values.expiryDate) {
      //   params.append("expiryDate", values.expiryDate);
      // }
    }

    await axios
      .post(NurseAndCareGiverURL.UPLOAD_NURSINGASSESSMENT_DOCUMENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);

          if (CareGiverStore?.selectedDoc == "Nursing Assessment") {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "NURSING_ASSESSMENT"
            );
          } else if (CareGiverStore?.selectedDoc == "Medication Records List") {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "MEDICATION_RECORDS_LIST"
            );
          } else if (CareGiverStore?.selectedDoc == "Home Safety") {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "HOME_SAFETY"
            );
          } else if (CareGiverStore?.selectedDoc == "Client Care Plan") {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CLIENT_CARE_PLAN"
            );
          } else if (
            CareGiverStore?.selectedDoc == "Caregiver Evaluation Form"
          ) {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CAREGIVER_EVALUATION_FORM"
            );
          } else if (
            CareGiverStore?.selectedDoc == "Caregiver Plan of Service"
          ) {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "CAREGIVER_PLAN_OF_SERVICE"
            );
          } else if (
            CareGiverStore?.selectedDoc ==
            "Competency Checklist Personal Care Aide"
          ) {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
            );
          } else if (
            CareGiverStore?.selectedDoc ==
            "Participant Review And Caregiver Supervisory Visit"
          ) {
            NurseAndCareGiverEmployeeStore.getNursingAssessmentDocuments(
              clientProfileId,
              "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
            );
          }
          AgencyStore.showAlert(
            "success",
            CareGiverStore.selectedDoc + " Saved"
          );
          ClientStore.setFileUploadPopUp(false);
          formik.resetForm();
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Fail to save " + CareGiverStore.selectedDoc
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Fail to save " + CareGiverStore.selectedDoc
        );
      });
  };

  const handleClose = () => {
    ClientStore.setFileUploadPopUp(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={ClientStore.fileUploadPopUp}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
    >
      <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
        <div>
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              Upload File
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>
          <div className="UCPUaction-popup-content">
            <div className="fileUploadMainContainer">
              <div className="formInputContainer">
                <FormikController
                  name="fileData"
                  label={CareGiverStore.selectedDoc}
                  type="file"
                  accept=".pdf"
                  control="file"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "fileData",
                      event.currentTarget.files[0]
                    );
                  }}
                  touch={formik.touched.fileData}
                  errorname={formik.errors.fileData}
                />
              </div>
              <div className="formInputContainer">
                <FormikController
                  name="uploadDate"
                  error
                  label="Visit Date"
                  type="date"
                  control="textfield"
                  {...formik.getFieldProps("uploadDate")}
                  touch={formik.touched.uploadDate}
                  errorname={formik.errors.uploadDate}
                />
              </div>
              {checkExpiryDate.includes(
                getFileTypeId(CareGiverStore.selectedDoc)
              ) ? (
                <div className="formInputContainer">
                  <FormikController
                    name="expiryDate"
                    error
                    label="Expiry Date"
                    type="date"
                    control="textfield"
                    {...formik.getFieldProps("expiryDate")}
                    touch={formik.touched.expiryDate}
                    errorname={formik.errors.expiryDate}
                  />
                </div>
              ) : (
                ""
              )}
              <div>
                <FormikController
                  control="selects"
                  title="Visit Type"
                  {...formik.getFieldProps("visitType")}
                  error
                  touch={formik.touched.visitType}
                  errorname={formik.errors.visitType}
                  selectitem={nurseVisitType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogActions className="DialogActionsContainer">
        <FormikController
          name="Save Changes"
          control="buttons"
          onClick={() => {
            formik.handleSubmit();
          }}
          className="buttonSelectedGreen fixedWidth"
          fullWidth="false"
        />
        <FormikController
          name="Cancel"
          control="buttons"
          onClick={() => {
            ClientStore.setFileUploadPopUp(false);
            formik.resetForm();
          }}
          className="buttonUnselected fixedWidth"
          fullWidth="false"
        />
      </DialogActions>
    </Dialog>
  );
});

export default ClientFileUploadPopUp;
