import React, { useEffect, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import { nurseVisitType } from "../../../Constants/Constants";
import {
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { headerTextStyles } from "../../../Constants/Styles";
import { cgpos } from "../../../Constants/FileData";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import ClientStore from "../../../Store/ClientStore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { useParams } from "react-router-dom";
import { ClientURL } from "../../../Url/Url";
import AgencyStore from "../../../Store/AgencyStore";
import axios from "axios";

export const AddCarePlanService = ({ handleClose }) => {
  const [saveUpadateState, setSaveUpadateState] = useState("");
  const handlesaveUpadateState = (value) => {
    setSaveUpadateState(value);
  };
  const { clientId } = useParams();
  const [tasks, setTasks] = useState(cgpos);
  const formik = useFormik({
    initialValues: {
      relationRN: "",
      relationCg: "",
      visitType: "",
    },
    validationSchema: Yup.object({
      relationRN: YupValidation.requiredOnly,
      relationCg: YupValidation.requiredOnly,
      visitType: YupValidation.requiredOnly,
    }),

    onSubmit: (values) => {
      if (saveUpadateState == "save") {
        saveCarePOS(values);
      }
    },
  });

  const [relationRN, setRelationRN] = useState([]);
  const [relationCg, setRelationCg] = useState([]);

  const handleChange = (id, type, value) => {
    setTasks((prevTasks) =>
      prevTasks.map((item, index) => {
        if (index === id) {
          if (type === "radio") {
            return {
              ...item,
              task: item.task,
              yesNo: value,
              comment: value ? item.comment : "",
            };
          } else if (type === "text") {
            return { ...item, comment: value };
          }
        }
        return item;
      })
    );
  };

  useEffect(() => {
    const nurseData = [];
    const careGiverData = [];
    ClientStore.clientEmployeeRelationData.forEach((item) => {
      const formattedItem = {
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      };
      if (item.employeeCategory === "RN") {
        nurseData.push(formattedItem);
      } else if (item.employeeCategory === "CareGiver") {
        careGiverData.push(formattedItem);
      }
    });
    setRelationRN(nurseData);
    setRelationCg(careGiverData);
  }, []);

  const saveCarePOS = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientId: clientId,
      rnId: values.relationRN,
      visitType: values.visitType,
      careGiverId: values.relationCg,
      taskList: tasks,
    };

    axios
      .post(ClientURL.SAVE_CARE_POS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.showAlert(
            "success",
            "Successfully Saved Care Plan Of Service Records"
          );
          // setIsSaved(true);
          ClientStore.setCarePosRecord(res?.data?.result);
          AgencyStore.setIsLoading(false);
          handleClose();
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to saved Care Plan Of Service Record List"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to saved Care Plan Of Service Record List"
        );
      });
  };

  return (
    <>
      <div className="homeSafetyContainer">
        <div className="underlineClick" onClick={handleClose}>
          Back
        </div>
        <div className="safetySelectInput">
          <div>Visit Type:</div>
          <div className="clientListinputFiled">
            <FormikController
              control="selects"
              error
              selectitem={nurseVisitType}
              {...formik.getFieldProps("visitType")}
              touch={formik.touched.visitType}
              errorname={formik.errors.visitType}
              // disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="medicationWrapper">
        <div className="medicationSection">
          <div className="heading">Caregiver's Plan Of Service</div>
          <div className="detailWrapper">
            <Grid container spacing={2}>
              <Grid item xs={6} className="rowSection">
                <div className="nameList">Client's Name:</div>
                <div className="medicationForm">
                  <FormikController
                    value={
                      ClientStore.ClientAllData.client?.firstName +
                      "  " +
                      ClientStore.ClientAllData.client?.lastName
                    }
                    variant="standard"
                    control="textfield"
                  />
                </div>
              </Grid>
              <Grid item xs={6} className="rowSection">
                <div className="nameList">Nurse's Name:</div>
                <div className="medicationForm">
                  <FormikController
                    control="selectByInput"
                    options={relationRN}
                    handleChange={(event, value) => {
                      formik.setFieldValue(
                        "relationRN",
                        value ? value.value : ""
                      );
                    }}
                    touch={formik.touched.relationRN}
                    errorname={formik.errors.relationRN}
                  />
                </div>
              </Grid>

              <Grid item xs={8} className="rowSection">
                <div className="nameDesc">Caregiver's Name:</div>
                <div className="medicationForm">
                  <FormikController
                    control="selectByInput"
                    options={relationCg}
                    handleChange={(event, value) => {
                      formik.setFieldValue(
                        "relationCg",
                        value ? value.value : ""
                      );
                    }}
                    touch={formik.touched.relationCg}
                    errorname={formik.errors.relationCg}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <TableContainer>
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell" sx={headerTextStyles}>
                    Task
                  </TableCell>
                  <TableCell className="tablecell" sx={headerTextStyles}>
                    Yes
                  </TableCell>
                  <TableCell className="tablecell" sx={headerTextStyles}>
                    No
                  </TableCell>
                  <TableCell className="tablecell" sx={headerTextStyles}>
                    Comments
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="tablecell">{item.task}</TableCell>
                      <TableCell className="tablecell">
                        <FormControlLabel
                          control={
                            <Radio
                              value="yes"
                              checked={item.yesNo}
                              onChange={() => {
                                handleChange(index, "radio", true);
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell className="tablecell">
                        <FormControlLabel
                          control={
                            <Radio
                              checked={!item.yesNo}
                              value="no"
                              onChange={() => {
                                handleChange(index, "radio", false);
                              }}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell className="tablecell">
                        <FormikController
                          control="textfield"
                          variant="standard"
                          value={item.comment}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          onChange={(e) =>
                            handleChange(index, "text", e.target.value)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={2}>
            <Grid item xs={6} className="rowSection">
              <div className="nameDesc">RN Signature:</div>
              <div className="medicationForm">
                <FormikController control="textfield" variant="standard" />
              </div>
            </Grid>
            <Grid item xs={6} className="rowSection">
              Date:
              <div className="medicationForm">
                <FormikController control="textfield" variant="standard" />
              </div>
            </Grid>

            <Grid item xs={6} className="rowSection">
              <div className="nameDesc">Caregiver Signature:</div>
              <div className="medicationForm">
                <FormikController control="textfield" variant="standard" />
              </div>
            </Grid>
            <Grid item xs={6} className="rowSection">
              Date:
              <div className="medicationForm">
                <FormikController control="textfield" variant="standard" />
              </div>
            </Grid>
            <div className="formButtonSave">
              <ConfirmPopUp
                buttonLabel="Save"
                title="Update"
                // buttonLabel={type == "save" ? "Save " : "Update"}
                // title={type == "save" ? "Save " : "Update"}
                content=" Please confirm your decision below."
                onConfirm={() => {
                  // if (type == "save") {
                  handlesaveUpadateState("save");
                  formik.handleSubmit();
                  // } else {
                  //   handlesaveUpadateState("Update");
                  //   formik.handleSubmit();
                  // }
                }}
                clickHTML={
                  <FormikController
                    control="buttons"
                    name="Save Caregiver Plan of Service"
                    // name={
                    //   type == "save"
                    //     ? "Save Home Safety"
                    //     : "Update Home Safety"
                    // }
                    className="buttonSelectedGreen fixedWidth"
                    fullWidth="false"
                  />
                }
              />
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};
