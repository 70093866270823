import React, { useState } from "react";
import { AddCarePlanService } from "./AddCarePlanService";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import CareGiverPosList from "./CareGiverPosList";

const CareGiverPlanOfServiceViewPage = () => {
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const [handleSearchChange] = useSearchData();
  return (
    <>
      <div className="homeSafetyWrapper">
        {show ? (
          <AddCarePlanService
            type="save"
            handleClose={handleClose}
            handleOpen={handleOpen}
          />
        ) : (
          <>
            <div className="actionButtonSearchMainContainer">
              <div className="searchMainCotainer">
                <div className="searchContainer">
                  <SearchBox
                    handleChange={(e) => {
                      handleSearchChange(e, "DocList");
                    }}
                  />
                </div>
                <div className="underlineClick" onClick={handleOpen}>
                  <Icon iconName="circleAdd" />
                  Add CG Plan Of Service
                </div>
              </div>
            </div>
            <CareGiverPosList />
          </>
        )}
      </div>
    </>
  );
};

export default CareGiverPlanOfServiceViewPage;
