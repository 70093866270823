import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import CareGiverStore from "../../../Store/CareGiverStore";

import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles } from "../../../Constants/Constants";
import {
  statusClientCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";
import ClientStore from "../../../Store/ClientStore";

const ClientListTable = observer(({ customerType }) => {
  const navigate = useNavigate();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    ClientStore,
    "clientAgGrid"
  );
  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);
      if (customerType === "leads") {
        navigate("/agency/Leads_profile/" + clientId);
      } else if (customerType === "client") {
        navigate("/agency/Client_profile/" + clientId);
      }
    };

    const fullName = `${params.data.fullName}`;

    return (
      <div onClick={onFullNameClick} class="ag-inner-cell" title={fullName}>
        {fullName}
      </div>
    );
  };

  const idCellRenderer = (params) => {
    const clientId = params.data.mra;
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);
      navigate("/agency/Client_profile/" + clientId);
    };
    return (
      <div onClick={onFullNameClick} class="ag-inner-cell">
        {clientId}
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "mra",
        headerName: "#MRA",
        width: 80,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: idCellRenderer,
      },
      {
        field: "fullName",
        headerName: "FullName",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "status",
        headerName: "Status",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: statusClientCellRenderer,
      },
      {
        field: "county",
        headerName: "County",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        field: "referralSource",
        headerName: "Referral Source",
        cellStyle: (params) => {
          return leftStyles;
        },
        valueGetter: (params) => {
          return params.data.clientReferralIntake?.referralSource || "";
        },
      },
      {
        field: "cocIncharge",
        headerName: "COC Incharge",
        cellStyle: (params) => {
          return leftStyles;
        },
        valueGetter: (params) => {
          return params.data.clientReferralIntake?.cocId || "";
        },
        cellRenderer: (params) => {
          return params.data.clientReferralIntake?.cocIncharge || "";
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (customerType == "leads") {
      ClientStore.getLeadsList();
    } else if (customerType == "client") {
      ClientStore.getClientList();
    }
  }, []);

  return (
    <FormikController
      rowData={
        customerType == "client"
          ? ClientStore.careGiverList
          : ClientStore.leadList
      }
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
});

export default ClientListTable;
