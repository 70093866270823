import { action, makeObservable, observable } from "mobx";
import $ from "jquery";
import axios from "axios";
import { CareGiverURL } from "../Url/Url";
import AgencyStore from "./AgencyStore";
import ClientStore from "./ClientStore";

class CommunityCoordinatorStore {
  communityCoordinatorList = [];
  communityCoordinatorSearch = "";
  communityCoordinatorActiveCheckState = "";
  communityCoordinatorAgGrid = {};
  communityCoordinatorRoles = [];
  constructor() {
    makeObservable(this, {
      communityCoordinatorList: observable,
      communityCoordinatorSearch: observable,
      communityCoordinatorActiveCheckState: observable,
      communityCoordinatorAgGrid: observable,
      communityCoordinatorRoles: observable,

      setCommunityCoordinatorList: action,
      setCommunityCoordinatorSearch: action,
      setCommunityCoordinatorActiveCheckState: action,
      setCommunityCoordinatorAgGrid: action,
      setCommunityCoordinatorRoles: action,

      getCocAssociatedClients: action,
    });
  }

  setCommunityCoordinatorList(data) {
    this.communityCoordinatorList = data;
  }

  setCommunityCoordinatorSearch(data) {
    this.communityCoordinatorSearch = data;
  }

  setCommunityCoordinatorActiveCheckState(data) {
    this.communityCoordinatorActiveCheckState = data;
  }

  setCommunityCoordinatorAgGrid(data) {
    this.communityCoordinator = data;
  }
  setCommunityCoordinatorRoles(data) {
    this.communityCoordinatorRoles = data;
  }

  getCocAssociatedClients = async (employeeId) => {
    AgencyStore.setIsLoading(true);
    await axios
      .get(CareGiverURL.GET_COC_ASSOCIATED_CLIENT + "/" + employeeId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary.status === "success") {
          ClientStore.setEmployeeClientRelationAllData(res?.data?.resultSet);
          AgencyStore.setIsLoading(false);
        } else if (res?.data?.summary.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fatch data ");
        }
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get client list ");
        AgencyStore.setIsLoading(false);
      });
  };
}

export default new CommunityCoordinatorStore();
