import { action, makeObservable, observable } from "mobx";
import $ from "jquery";
import axios from "axios";
import { CalenderUrl, CareGiverURL } from "../Url/Url";
import AgencyStore from "./AgencyStore";
import GlobelStore from "./GlobelStore";
import CareGiverStore from "./CareGiverStore";

class CalenderStore {
  employeeAllCalendarData = [];
  specificCalendarAllData = [];
  specificEmployeeCalendar = [];
  openDrawer = false;
  isSwipeableTemporaryDrawer = "";
  constructor() {
    makeObservable(this, {
      employeeAllCalendarData: observable,
      specificCalendarAllData: observable,
      specificEmployeeCalendar: observable,
      openDrawer: observable,
      isSwipeableTemporaryDrawer: observable,

      setEmployeeAllCalendarData: action,
      setSpecificCalendarAllData: action,
      setOpenDrawer: action,
      setIsSwipeableTemporaryDrawer: action,
      setSpecificEmployeeCalendar: action,

      getEmployeeCalendar: action,
      getSpecificEmployeeCalendar: action,
      getEmployeeList: action,
    });
  }

  setEmployeeAllCalendarData(data) {
    this.employeeAllCalendarData = data;
  }
  setSpecificCalendarAllData(data) {
    this.specificCalendarAllData = data;
  }
  setSpecificEmployeeCalendar(data) {
    this.specificEmployeeCalendar = data;
  }

  setOpenDrawer(value) {
    this.openDrawer = value;
  }
  setIsSwipeableTemporaryDrawer(value) {
    this.isSwipeableTemporaryDrawer = value;
  }

  getEmployeeCalendar = async (employeeCategory) => {
    await axios
      .get(CalenderUrl.GET_EMPLOYEE_CALENDER + "/" + employeeCategory, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          this.setEmployeeAllCalendarData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Schedule Data");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Schedule Data");
      });
  };

  getSpecificEmployeeCalendar = async (employeeProfileId) => {
    await axios
      .get(
        CalenderUrl.GET_SPECIFIC_EMPLOYEE_CALENDER + "/" + employeeProfileId,
        {
          headers: { Authorization: localStorage.getItem("loginToken") },
        }
      )
      .then((res) => {
        if (res?.data?.summary?.status == "success") {
          this.setSpecificEmployeeCalendar(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to get Schedule Data");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to get  Schedule Data");
      });
  };

  getEmployeeList = async (employeeType) => {
    AgencyStore.setIsLoading(true);
    const params = {
      employeeCategory: employeeType,
    };
    await axios
      .post(CareGiverURL.GET_EMLOYEE_LIST, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (employeeType == "CareGiver") {
          CareGiverStore.setCareGiverList(res?.data?.resultSet);
        } else if (employeeType == "RN") {
          GlobelStore.setNurseList(res?.data?.resultSet);
        }

        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get care giver list ");
        AgencyStore.setIsLoading(false);
      });
  };
}

export default new CalenderStore();
