import { observer } from "mobx-react";
import React, { useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { CareGiverURL } from "../../../Url/Url";
import AgencyStore from "../../../Store/AgencyStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { YupValidation } from "../../../Constants/ValidationConstants";

const AdminEmployeeConfidential = observer(() => {
  const [isSaved, setIsSaved] = useState(false);

  const formik = useFormik({
    initialValues: {
      cprAndFirstAid: "",
      cprExpiryDate: "",
      physicalAndPpd: "",
      directDeposit: "",
      oigSearch: "",
      sexOffender: "",
      healthStatement: "",
      hepatitisVaccineRequirement: "",
      tbTargeted: "",
    },
    validationSchema: Yup.object({
      cprAndFirstAid: YupValidation.file_type,
      cprExpiryDate: YupValidation.expiryDate,
      physicalAndPpd: YupValidation.file_type,
      directDeposit: YupValidation.file_type,
      oigSearch: YupValidation.file_type,
      sexOffender: YupValidation.file_type,
      healthStatement: YupValidation.file_type,
      hepatitisVaccineRequirement: YupValidation.file_type,
      tbTargeted: YupValidation.file_type,
    }),
    onSubmit: (values) => {
      saveConfidentialDoc(values);
    },
  });

  const saveConfidentialDoc = (values) => {
    AgencyStore.setIsLoading(true);
    const employeeProfileID = CareGiverStore.careGiverDbId;

    const params = new FormData();
    if (employeeProfileID) {
      params.append("employeeProfileId", employeeProfileID);
      params.append("cprAndFirstAidFile", values.cprAndFirstAid);
      params.append("cprAndFirstAidExpiryDate", values.cprExpiryDate);
      params.append("physicalAndPpdFile", values.physicalAndPpd);
      params.append("directDepositFile", values.directDeposit);
      params.append("oigSearchFile", values.oigSearch);
      params.append("sexOffenderFile", values.sexOffender);
      params.append("healthStatementFile", values.healthStatement);
      params.append(
        "hepatitisVaccineRequirementFile",
        values.hepatitisVaccineRequirement
      );
      params.append("tbTargetedFile", values.tbTargeted);
    }

    axios
      .post(CareGiverURL.SAVE_CONFIDENTIALTDOC, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Confidential document Saved");
          setIsSaved(true);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Fail to save Confidential document!!"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Fail to save Confidential document!!");
      });
  };

  return (
    <div>
      <div className="confidentailMainContainer marginTop">
        <div>
          <div className="formInputContainer">
            <FormikController
              name="cprAndFirstAid"
              label="CPR &amp; First Aide"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "cprAndFirstAid",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.cprAndFirstAid}
              errorname={formik.errors.cprAndFirstAid}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="cprExpiryDate"
              error
              label="CPR Expiry Date"
              type="date"
              control="textfield"
              {...formik.getFieldProps("cprExpiryDate")}
              touch={formik.touched.cprExpiryDate}
              errorname={formik.errors.cprExpiryDate}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="physicalAndPpd"
              label="Physical &amp; PPD"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "physicalAndPpd",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.physicalAndPpd}
              errorname={formik.errors.physicalAndPpd}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>

        <div>
          <div className="formInputContainer">
            <FormikController
              name="directDeposit"
              label="Direct Deposit"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "directDeposit",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.directDeposit}
              errorname={formik.errors.directDeposit}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="oigSearch"
              label="OIG Search"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue("oigSearch", event.currentTarget.files[0]);
              }}
              touch={formik.touched.oigSearch}
              errorname={formik.errors.oigSearch}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="sexOffender"
              label="Sex Offender"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "sexOffender",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.sexOffender}
              errorname={formik.errors.sexOffender}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="healthStatement"
              label="Health Statement"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "healthStatement",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.healthStatement}
              errorname={formik.errors.healthStatement}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="hepatitisVaccineRequirement"
              label="Hepatitis Vaccine Requirement"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "hepatitisVaccineRequirement",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.hepatitisVaccineRequirement}
              errorname={formik.errors.hepatitisVaccineRequirement}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
        <div>
          <div className="formInputContainer">
            <FormikController
              name="tbTargeted"
              label="TB Targeted"
              type="file"
              accept=".pdf"
              control="file"
              onChange={(event) => {
                formik.setFieldValue(
                  "tbTargeted",
                  event.currentTarget.files[0]
                );
              }}
              touch={formik.touched.tbTargeted}
              errorname={formik.errors.tbTargeted}
              disabled={isSaved ? true : false}
            />
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        {isSaved == true ? (
          ""
        ) : (
          <>
            <FormikController
              name="Save Changes"
              control="buttons"
              onClick={formik.handleSubmit}
              className="buttonSelected fixedWidth"
              fullWidth="false"
            />
          </>
        )}
      </div>
    </div>
  );
});

export default AdminEmployeeConfidential;
