import React, { useEffect, useMemo } from "react";
import "../../CSS/Agency.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import ClientList from "./Client/ClientList";
import ProfileMainViewPage from "./Profile/ProfileMainViewPage";
import LogList from "./LogList/LogList";
import Calendars from "./Calendar/Calendars";
import Error404 from "./Error404";
import CareGiverList from "./CareGiver/CareGiverList";
import AddCareGiver from "./CareGiver/AddCareGiver";
import CareGiverViewPage from "./CareGiver/CareGiverViewPage";
import AgencyStore from "../../Store/AgencyStore";
import CommunityOutReachCoordinator from "./CommunityOutReachCoordinator/CommunityOutReachCoordinator";
import AddCommunityOutReachCoordinator from "./CommunityOutReachCoordinator/AddCommunityOutReachCoordinator";
import CommunityOutReachCoordinatorViewPage from "./CommunityOutReachCoordinator/CommunityOutReachCoordinatorViewPage";
import AdminEmployeeList from "./AdminEmployee/AdminEmployeeList";
import AdminEmployee from "./AdminEmployee/AdminEmployee";
import AdminEmployeeViewPage from "./AdminEmployee/AdminEmployeeViewPage";
import AccountAccessMainPage from "./AccountAccess/AccountAccessMainPage";
import AddClient from "./Client/AddClient";
import ClientViewPage from "./Client/ClientViewPage";
import GlobelStore from "../../Store/GlobelStore";
import NursingAssesmentDoc from "./NursingAssessment/NursingAssesmentDoc";
import NurseList from "./Nurse/NurseList";
import AddNurse from "./Nurse/AddNurse";
import NurseViewPage from "./Nurse/NurseViewPage";
import CareGiverSchedule from "./CareGiverSchedule/CareGiverSchedule";
import { observer } from "mobx-react";
import NurseSchedule from "./NurseSchedule/NurseSchedule";
import Notification from "./Notification/Notification";
import ManualNotificationViewPage from "./ManualNotification/ManualNotificationViewPage";
import LeadsList from "./Leads/LeadsList";
import AddHomeSafety from "./HomeSafety/AddHomeSafety";

const PagesMain = observer(() => {
  const navigate = useNavigate();

  const checkToken = async () => {
    const result = await AgencyStore.validateLoginToken();
    return result;
  };

  useEffect(() => {
    const redirectToLogin = async () => {
      const isValidToken = await checkToken();

      if (!isValidToken) {
        navigate("/");
        localStorage.clear();
      }
    };

    redirectToLogin();
  }, [navigate]);

  const routes = useMemo(() => {
    return (
      <>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<ProfileMainViewPage />} />
        {GlobelStore.checkUserRole("ChangeLog") && (
          <Route path="logList" element={<LogList />} />
        )}
        {GlobelStore.checkUserRole("Client") && (
          <>
            <Route path="leads_list" element={<LeadsList />} />
            <Route path="client_list" element={<ClientList />} />
            <Route
              path="add_client"
              element={<AddClient customerType="client" />}
            />
            <Route
              path="add_leads"
              element={<AddClient customerType="leads" />}
            />
            <Route
              path="Client_profile/:clientId"
              element={<ClientViewPage customerType="client" />}
            />
            <Route
              path="Client_profile/home_safety/:clientId"
              element={<AddHomeSafety customerType="client" />}
            />
            <Route
              path="Leads_profile/:clientId"
              element={<ClientViewPage customerType="leads" />}
            />
          </>
        )}
        {GlobelStore.checkUserRole("Nurse") && (
          <>
            <Route path="nurse_List" element={<NurseList />} />
            <Route path="add_Nurse" element={<AddNurse />} />
            <Route
              path="Nurse_Profile/:employeeId"
              element={<NurseViewPage />}
            />
            <Route path="NurseSchedule" element={<NurseSchedule />} />
          </>
        )}

        {GlobelStore.checkUserRole("CareGiver") && (
          <>
            <Route path="careGiverList" element={<CareGiverList />} />
            <Route path="addCareGiver" element={<AddCareGiver />} />
            <Route
              path="careGiverProfile/:employeeId"
              element={<CareGiverViewPage />}
            />
            <Route path="careGiverSchedule" element={<CareGiverSchedule />} />
          </>
        )}

        {GlobelStore.checkUserRole("AdminEmployee") && (
          <>
            <Route path="adminEmployeeList" element={<AdminEmployeeList />} />
            <Route path="addAdminEmployee" element={<AdminEmployee />} />
            <Route
              path="adminProfile/:employeeId"
              element={<AdminEmployeeViewPage />}
            />
          </>
        )}

        {GlobelStore.checkUserRole("CommunityCoordinator") && (
          <>
            <Route
              path="communityOutReachCoordinator"
              element={<CommunityOutReachCoordinator />}
            />
            <Route
              path="addCommunityOutReachCoordinator"
              element={<AddCommunityOutReachCoordinator />}
            />
            <Route
              path="communityOutReachCoordinatorProfile/:employeeId"
              element={<CommunityOutReachCoordinatorViewPage />}
            />
          </>
        )}

        {GlobelStore.checkUserRole("AccountAndAccess") && (
          <Route path="account_access" element={<AccountAccessMainPage />} />
        )}

        {GlobelStore.checkUserRole("Notifications") && (
          <Route path="notification" element={<Notification />} />
        )}

        {GlobelStore.checkUserRole("Notifications") && (
          <Route
            path="manualNotification"
            element={<ManualNotificationViewPage />}
          />
        )}

        <Route path="calendar" element={<Calendars />} />
        <Route path="addNursingAssessment" element={<NursingAssesmentDoc />} />
        <Route path="*" element={<Error404 />} />
      </>
    );
  }, []);

  return (
    <div className="paagesMainContainer">
      <Routes>{routes}</Routes>
    </div>
  );
});

export default PagesMain;
