import { observer } from "mobx-react";
import React, { useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import AgencyStore from "../../../Store/AgencyStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { YupValidation } from "../../../Constants/ValidationConstants";
import { EmpApplicationResumeHiringDoc } from "../../../Url/Url";

const EmployeeApplicationResumeHiring = observer(() => {
  const [isSaved, setIsSaved] = useState(false);

  const formik = useFormik({
    initialValues: {
      application: "",
      hiring: "",
      resume: "",
    },
    validationSchema: Yup.object({
      application: YupValidation.file_type_without_require,
      hiring: YupValidation.file_type,
      resume: YupValidation.file_type,
    }),
    onSubmit: (values) => {
      saveEmpApplicationResumeHiringDoc(values);
    },
  });

  const saveEmpApplicationResumeHiringDoc = (values) => {
    AgencyStore.setIsLoading(true);
    const employeeProfileID = CareGiverStore.careGiverDbId;
    const params = new FormData();

    if (employeeProfileID) {
      params.append("employeeProfileId", employeeProfileID);
      params.append("resumeFile", values.resume);
      params.append("hireDocFile", values.hiring);
      params.append("applicationFile", values.application);
    }
    axios
      .post(
        EmpApplicationResumeHiringDoc.EMP_APPLCATION_RESUME_HIRING_DOC,
        params,
        {
          headers: { Authorization: localStorage.getItem("loginToken") },
        }
      )
      .then((res) => {
        if (res?.status == 200) {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "File saved successfully !!");
          setIsSaved(true);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Fail to save document !!");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Fail to save Application or Hiring document!!"
        );
      });
  };

  return (
    <div>
      <div className="confidentailMainContainer marginTop">
        <div className="formInputContainer">
          <FormikController
            name="application"
            label="Application Document"
            type="file"
            accept=".pdf"
            control="file"
            onChange={(event) => {
              formik.setFieldValue("application", event.currentTarget.files[0]);
            }}
            touch={formik.touched.application}
            errorname={formik.errors.application}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="resume"
            label="Resume"
            type="file"
            accept=".pdf"
            control="file"
            onChange={(event) => {
              formik.setFieldValue("resume", event.currentTarget.files[0]);
            }}
            touch={formik.touched.resume}
            errorname={formik.errors.resume}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="hiring"
            label="Hiring Document"
            type="file"
            accept=".pdf"
            control="file"
            onChange={(event) => {
              formik.setFieldValue("hiring", event.currentTarget.files[0]);
            }}
            touch={formik.touched.hiring}
            errorname={formik.errors.hiring}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="buttonContainer">
        {isSaved == true ? (
          ""
        ) : (
          <FormikController
            name="Save Changes"
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelected fixedWidth"
            fullWidth="false"
          />
        )}
      </div>
    </div>
  );
});

export default EmployeeApplicationResumeHiring;
