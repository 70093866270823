import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import SearchBox from "../../../components/Search/SearchBox";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import { CellRenderer, useOnGridReady } from "../../../Utils/AgGridUtils";
import NotificationStore from "../../../Store/NotificationStore";
import ActionPopUp from "./ActionPopUp";
import { ManualEmailSmsURL } from "../../../Url/Url";
import AgencyStore from "../../../Store/AgencyStore";
import axios from "axios";
import useSearchData from "../../../components/useSearchData";
import CareGiverStore from "../../../Store/CareGiverStore";

const EmployeeNotiEmailSmsList = observer(
  ({ category, employeeName, storeName, storeGrid }) => {
    const [onGridReady] = useOnGridReady(storeName, storeGrid);
    const [handleSearchChange] = useSearchData();
    const columnDefs = useMemo(
      () => [
        {
          field: "title",
          headerName: "Title",
          filter: true,
          cellStyle: (params) => {
            return leftStyles;
          },
          cellRenderer: CellRenderer,
        },
        {
          field: "description",
          headerName: "Description",
          filter: true,
          cellStyle: (params) => {
            return leftStyles;
          },
          cellRenderer: CellRenderer,
        },
        {
          field: "sentDate",
          headerName: "Date",
          filter: true,
          cellStyle: (params) => {
            return leftStyles;
          },
          cellRenderer: CellRenderer,
        },

        {
          headerName: "Action",
          sortable: false,

          headerClass: "center-header",

          cellStyle: (params) => {
            return centerStyles;
          },
          cellRenderer: (params) => {
            const handleButtonClick = () => {
              if (category === "employee") {
                NotificationStore.getEmpSingleNotiDetails(params.data.id);
              } else if (category === "client") {
                NotificationStore.getClientSingleNotiDetails(params.data.id);
              }
            };

            return (
              <div
                className="ag-inner-cell"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActionPopUp
                  type={category}
                  onConfirm={() => {}}
                  clickHTML={
                    <div className="manageClick" onClick={handleButtonClick}>
                      View Detail
                    </div>
                  }
                />
              </div>
            );
          },
        },
      ],
      []
    );
    const getEmpManualNoti = (employeeName) => {
      AgencyStore.setIsLoading(true);
      axios
        .get(
          category === "employee"
            ? ManualEmailSmsURL.GET_EMAIL_SMS_EMPLOYEE + "/" + employeeName
            : ManualEmailSmsURL.GET_EMAIL_SMS_CLIENT,
          {
            headers: { Authorization: localStorage.getItem("loginToken") },
          }
        )
        .then((res) => {
          category === "employee"
            ? NotificationStore.setCareGiverManualNotiAllData(res?.data)
            : NotificationStore.setClientManualNotiAllData(res?.data);

          AgencyStore.setIsLoading(false);
        })
        .catch((err) => {
          AgencyStore.showAlert("error", "Failed to get Manual Notification ");
          AgencyStore.setIsLoading(false);
        });
    };

    useEffect(() => {
      getEmpManualNoti(employeeName);
    }, []);

    useEffect(() => {
      const data =
        category === "employee"
          ? NotificationStore?.careGiverManualNotiAllData?.resultSet
          : NotificationStore?.clientManualNotiAllData?.resultSet;
      CareGiverStore.setTableHeight(data?.length);
    }, [
      NotificationStore?.careGiverManualNotiAllData,
      NotificationStore?.clientManualNotiAllData,
    ]);

    return (
      <div>
        <div className="actionButtonSearchMainContainer">
          <div className="searchMainCotainer">
            <div className="searchContainer">
              <SearchBox
                handleChange={(e) => {
                  handleSearchChange(
                    e,
                    employeeName == "RN" ? "Nurse" : employeeName
                  );
                }}
              />
            </div>
          </div>
        </div>

        <FormikController
          rowData={
            category === "employee"
              ? NotificationStore?.careGiverManualNotiAllData?.resultSet
              : NotificationStore?.clientManualNotiAllData?.resultSet
          }
          control="agGrid"
          columnDefs={columnDefs}
          onGridReady={onGridReady}
        />
      </div>
    );
  }
);

export default EmployeeNotiEmailSmsList;
