import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../../CSS/Profile.css";
import CareGiverStore from "../../../Store/CareGiverStore";
import DocViewerList from "../DocViewerList/DocViewerList";
import PageTitle from "../../PageTitle";
import Accordions from "../../../components/Accordion/Accordions";
import Profile from "./Profile";
import Security from "./Security";

import Icon from "../../../components/Icons/Icon";

const ProfileMainViewPage = observer(() => {
  const [value, setValue] = React.useState("1");

  const initialPanelsState = {
    panel1: true,
  };

  const [expandedPanels, setExpandedPanels] =
    React.useState(initialPanelsState);

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanels((prevExpanded) => ({
      ...prevExpanded,
      [panel]: newExpanded,
    }));
  };

  const employeeId = localStorage.getItem("profileId");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (employeeId) {
      CareGiverStore.getEmployeeProfile(employeeId);
      // CareGiverStore.getConfidentialDoc(employeeId);
      CareGiverStore.getEmployeeDocs();
    }
  }, [employeeId]);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle=" My Detail"
          subHeaderTitle="Profile/My Detail"
        />
      </div>
      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList onChange={handleChange}>
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="profile" className="profileEeachMenuIcon" />
                  }
                  label="Profile"
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Government Document"
                  value="2"
                />
                {localStorage.getItem("userType") == "Admin" && (
                  <Tab
                    className="profileEachMenu"
                    icon={
                      <Icon
                        iconName="document"
                        className="profileEeachMenuIcon"
                      />
                    }
                    label="Confidential Document"
                    value="3"
                  />
                )}

                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Application & Hiring Document"
                  value="4"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="account"
                      ldLock
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Security"
                  value="5"
                />
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <Accordions
                accordianComponent={<Profile />}
                expanded={expandedPanels.panel1}
                OnhandleChange={handleChangePanel("panel1")}
                title="Profile"
              />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <DocViewerList id={employeeId} govResultSet="govResultSet" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <DocViewerList
                id={employeeId}
                confidentialResultSet="confidentialResultSet"
              />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="4">
              <DocViewerList
                id={employeeId}
                applicationResumeAndHireResultSet="applicationResumeAndHireResultSet"
              />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <Security />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
});

export default ProfileMainViewPage;
