import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { InputAdornment } from "@mui/material";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import axios from "axios";
import { YupValidation } from "../../../Constants/ValidationConstants";
import GlobelStore from "../../../Store/GlobelStore";
import ClientStore from "../../../Store/ClientStore";

const ClientUserView = observer(() => {
  const { clientId } = ClientStore.ClientAllData.client.id
    ? { clientId: ClientStore.ClientAllData.client.id }
    : useParams();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
    }),
    onSubmit: (values) => {
      //   createCareGiverAccount(values);
    },
  });

  const setProfileDataFormik = () => {
    const result = ClientStore.ClientAllData.client;
    formik.setValues({
      email: result?.email || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [clientId, ClientStore.ClientAllData]);
  return (
    <div>
      <div className="CGSHeader">Account</div>
      <div className="changePasswordFormMainContainer">
        <div className="formMainUniqueContainer">
          <div>
            <FormikController
              name="email"
              error
              label="User Name"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ClientUserView;
