import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { observer } from "mobx-react";
import {
  maritalStatusOption,
  status,
  immigrationStatus,
  radioLabel,
} from "../../Constants/Constants";

import { YupValidation } from "../../Constants/ValidationConstants";

import { setDateInFomat } from "../../Utils/Utils";
import FormikController from "../../components/FormikController/FormikController";
import AgencyStore from "../../Store/AgencyStore";
import axios from "axios";
import { CareGiverURL } from "../../Url/Url";
import CareGiverStore from "../../Store/CareGiverStore";
const Profile = observer(() => {
  const [isSaved, setIsSaved] = useState(false);
  const [saveUpdateState, setSaveUpdateState] = useState("");
  const employeeId = localStorage.getItem("id");
  const handlesaveUpadateState = (value) => {
    setSaveUpdateState(value);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      phone: "",
      email: "",
      maritalStatus: "Single",
      immigrationStatus: "Green Card",
      ethnicity: "",
      gender: "Male",
      street: "",
      city: "",
      zipCode: "",
      state: "",
      status: "Active",
      joinDate: "",
      ECfirstName: "",
      ECmiddleName: "",
      EClastName: "",
      relationship: "",
      ECphone: "",
      ECemail: "",
      employeeId: "",
    },

    validationSchema: Yup.object({
      firstName: YupValidation.firstName,
      middleName: YupValidation.middleName,
      lastName: YupValidation.lastName,
      gender: YupValidation.gender,
      dateOfBirth: YupValidation.dateOfBirth,
      phone: YupValidation.phone,
      email: YupValidation.email,
      maritalStatus: YupValidation.maritalStatus,
      immigrationStatus: YupValidation.immigration,
      ethnicity: YupValidation.ethnicity,
      street: YupValidation.street,
      city: YupValidation.city,
      zipCode: YupValidation.zipCode,
      state: YupValidation.state,
      status: YupValidation.status,
      joinDate: YupValidation.joinDate,
      ECfirstName: YupValidation.ECfirstName,
      ECmiddleName: YupValidation.ECmiddleName,
      EClastName: YupValidation.EClastName,
      relationship: YupValidation.relationship,
      ECphone: YupValidation.phone,
      ECemail: YupValidation.email,
      employeeId: YupValidation.employeeId,
    }),
    onSubmit: (values) => {
      updateEmployeeProfile(values);
    },
  });

  const updateEmployeeProfile = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: employeeId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
      ethnicity: values.ethnicity,
      immigrationStatus: values.immigrationStatus,
      maritalStatus: values.maritalStatus,
      phoneNo: values.phone,
      email: values.email,
      employeeAddress: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },
      employeeInfo: {
        hireDate: values.joinDate,
        status: values.status,
      },
      employeeEmergencyContact: {
        firstName: values.ECfirstName,
        middleName: values.ECmiddleName,
        lastName: values.EClastName,
        relationship: values.relationship,
        phoneNo: values.ECphone,
        email: values.ECemail,
      },
    };
    await axios
      .post(CareGiverURL.UPDATE_EmployeeProfile, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          CareGiverStore.getEmployeeProfile(employeeId);
          AgencyStore.showAlert("success", "successfully Updated");
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to update Admin employee Profile"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to update Admin employee Profile"
        );
      });
  };

  const setProfileDataFormik = () => {
    const result = CareGiverStore.careGiverProfileData;
    formik.setValues({
      firstName: result?.firstName || "",
      middleName: result?.middleName || "",
      lastName: result?.lastName || "",
      dateOfBirth: setDateInFomat(result?.dateOfBirth) || "",
      phone: result?.phoneNo || "",
      email: result?.email || "",
      maritalStatus: result?.maritalStatus || "",
      immigrationStatus: result?.immigrationStatus || "",
      ethnicity: result?.ethnicity || "",
      gender: result?.gender || "Male",
      street: result?.employeeAddress?.street || "",
      city: result?.employeeAddress?.city || "",
      zipCode: result?.employeeAddress?.zipCode || "",
      state: result?.employeeAddress?.state || "",
      status: result?.employeeInfo?.status || "",
      joinDate: setDateInFomat(result?.employeeInfo?.hireDate) || "",
      ECfirstName: result?.employeeEmergencyContact?.firstName || "",
      ECmiddleName: result?.employeeEmergencyContact?.middleName || "",
      EClastName: result?.employeeEmergencyContact?.lastName || "",
      relationship: result?.employeeEmergencyContact?.relationship || "",
      ECphone: result?.employeeEmergencyContact?.phoneNo || "",
      ECemail: result?.employeeEmergencyContact?.email || "",
      employeeId: result?.employeeId || "",
    });
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, CareGiverStore.careGiverProfileData]);

  const resetForm = () => {
    formik.resetForm();
  };
  return (
    <>
      <div className="headingTitle">Profile</div>
      {employeeId ? (
        <div className="formMainUniqueContainer">
          <div className="formInputContainer">
            <FormikController
              name="employeeId"
              error
              label="Qnique Id"
              type="text"
              control="textfield"
              {...formik.getFieldProps("employeeId")}
              touch={formik.touched.employeeId}
              errorname={formik.errors.employeeId}
              disabled={true}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="formMainContainer marginTop">
        <div className="formInputContainer">
          <FormikController
            name="firstName"
            error
            label="First Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("firstName")}
            touch={formik.touched.firstName}
            errorname={formik.errors.firstName}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="middleName"
            error
            label="Middle Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("middleName")}
            touch={formik.touched.middleName}
            errorname={formik.errors.middleName}
            disabled={isSaved ? true : false}
          />
        </div>
        <div className="formInputContainer">
          <FormikController
            name="lastName"
            error
            label="Last Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("lastName")}
            touch={formik.touched.lastName}
            errorname={formik.errors.lastName}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="dateOfBirth"
            error
            label="Date of Birth"
            type="date"
            control="textfield"
            values={formik.values.dateOfBirth}
            {...formik.getFieldProps("dateOfBirth")}
            touch={formik.touched.dateOfBirth}
            errorname={formik.errors.dateOfBirth}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="phone"
            error
            label="Phone"
            type="text"
            control="textfield"
            {...formik.getFieldProps("phone")}
            touch={formik.touched.phone}
            errorname={formik.errors.phone}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="email"
            error
            label="Email"
            type="email"
            control="textfield"
            {...formik.getFieldProps("email")}
            touch={formik.touched.email}
            errorname={formik.errors.email}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Marital Status"
            {...formik.getFieldProps("maritalStatus")}
            error
            touch={formik.touched.maritalStatus}
            errorname={formik.errors.maritalStatus}
            selectitem={maritalStatusOption}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Immigration Status"
            {...formik.getFieldProps("immigrationStatus")}
            error
            touch={formik.touched.immigrationStatus}
            errorname={formik.errors.immigrationStatus}
            selectitem={immigrationStatus}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="ethnicity"
            error
            label="Ethnicity"
            type="text"
            control="textfield"
            {...formik.getFieldProps("ethnicity")}
            touch={formik.touched.ethnicity}
            errorname={formik.errors.ethnicity}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="marginTop">
        <FormikController
          title="Gender"
          control="radio"
          row
          error
          radioLabel={radioLabel}
          {...formik.getFieldProps("gender")}
          touch={formik.touched.gender}
          errorname={formik.errors.gender}
          disabled={isSaved ? true : false}
        />
      </div>

      <div className="headingTitle marginTop">Address</div>
      <div className="addressMainContainer marginTop">
        <div className="formInputContainer">
          <FormikController
            name="street"
            error
            label="Street"
            type="text"
            control="textfield"
            {...formik.getFieldProps("street")}
            touch={formik.touched.street}
            errorname={formik.errors.street}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="city"
            error
            label="City"
            type="text"
            control="textfield"
            {...formik.getFieldProps("city")}
            touch={formik.touched.city}
            errorname={formik.errors.city}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="zipCode"
            error
            label="Zip code"
            type="text"
            control="textfield"
            {...formik.getFieldProps("zipCode")}
            touch={formik.touched.zipCode}
            errorname={formik.errors.zipCode}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="state"
            error
            label="State"
            type="text"
            control="textfield"
            {...formik.getFieldProps("state")}
            touch={formik.touched.state}
            errorname={formik.errors.state}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="marginTop headingTitle">Employee Info</div>
      <div className="addressMainContainer marginTop">
        <div className="formInputContainer">
          <FormikController
            control="selects"
            title="Status"
            {...formik.getFieldProps("status")}
            error
            touch={formik.touched.status}
            errorname={formik.errors.status}
            selectitem={status}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="joinDate"
            error
            label="Hiring Date"
            type="date"
            control="textfield"
            {...formik.getFieldProps("joinDate")}
            values={formik.values.dateOfBirth}
            touch={formik.touched.joinDate}
            errorname={formik.errors.joinDate}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="emergencyContactTitle headingTitle">
        Emergency Contact
      </div>
      <div className="formMainContainer marginTop">
        <div className="formInputContainer">
          <FormikController
            name="ECfirstName"
            error
            label="First Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("ECfirstName")}
            touch={formik.touched.ECfirstName}
            errorname={formik.errors.ECfirstName}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="ECmiddleName"
            error
            label="Middle Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("ECmiddleName")}
            touch={formik.touched.ECmiddleName}
            errorname={formik.errors.ECmiddleName}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="EClastName"
            error
            label="Last Name"
            type="text"
            control="textfield"
            {...formik.getFieldProps("EClastName")}
            touch={formik.touched.EClastName}
            errorname={formik.errors.EClastName}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="relationship"
            error
            label="Relationship"
            type="text"
            control="textfield"
            {...formik.getFieldProps("relationship")}
            touch={formik.touched.relationship}
            errorname={formik.errors.relationship}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="ECphone"
            error
            label="Phone"
            type="text"
            control="textfield"
            {...formik.getFieldProps("ECphone")}
            touch={formik.touched.ECphone}
            errorname={formik.errors.ECphone}
            disabled={isSaved ? true : false}
          />
        </div>

        <div className="formInputContainer">
          <FormikController
            name="ECemail"
            error
            label="Email"
            type="email"
            control="textfield"
            {...formik.getFieldProps("ECemail")}
            touch={formik.touched.ECemail}
            errorname={formik.errors.ECemail}
            disabled={isSaved ? true : false}
          />
        </div>
      </div>
      <div className="buttonContainer">
        {employeeId ? (
          <FormikController
            name="Update Changes"
            control="buttons"
            onClick={() => {
              handlesaveUpadateState("update");
              formik.handleSubmit();
            }}
            className="buttonSelected fixedWidth"
            fullWidth="false"
          />
        ) : isSaved === true ? (
          ""
        ) : (
          <>
            <FormikController
              name="Save Changes"
              control="buttons"
              onClick={() => {
                handlesaveUpadateState("save");
                formik.handleSubmit();
              }}
              className="buttonSelected fixedWidth"
              fullWidth="false"
            />
            <FormikController
              name="Reset"
              control="buttons"
              onClick={() => {
                resetForm();
              }}
              className="buttonUnselected fixedWidth"
              fullWidth="false"
            />
          </>
        )}
      </div>
    </>
  );
});

export default Profile;
