import React, { useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import { centerStyles } from "../../../Constants/Constants";

import CareGiverStore from "../../../Store/CareGiverStore";
import { observer } from "mobx-react";
import {
  useOnGridReady,
  fullNameCellRenderer,
} from "../../../Utils/AgGridUtils";
import ClientStore from "../../../Store/ClientStore";

const ManualChangeLogTable = observer((employeeCategory) => {
  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        valueGetter: "node.rowIndex + 1",
        width: 80,
      },
      {
        field: "caseNote",
        headerName: "Description",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "whoChanged",
        headerName: "Who Changed",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "whatChanged",
        headerName: "What Changed",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "changeDate",
        headerName: "Log Change Date",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
    ],
    []
  );

  const [onGridReady] = useOnGridReady(
    employeeCategory.employeeCategory === "Client"
      ? ClientStore
      : CareGiverStore,
    employeeCategory.employeeCategory === "Client"
      ? "clientAgGrid"
      : "careGiverAgGrid"
  );

  if (CareGiverStore.employeeChangeLog.length > 0)
    CareGiverStore.setTableHeight(CareGiverStore.employeeChangeLog.length);

  return (
    <FormikController
      rowData={CareGiverStore.employeeChangeLog}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
});

export default ManualChangeLogTable;
