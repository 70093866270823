import React, { useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import logo from "../Images/login3.svg";
import headLogo from "../Images/GBHLogo.png";
import "../CSS/login.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikController from "../components/FormikController/FormikController";
import { Link, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD } from "../Url/Url";
import AgencyStore from "../Store/AgencyStore";
import axios from "axios";
import { observer } from "mobx-react";
import { YupValidation } from "../Constants/ValidationConstants";
import Icon from "../components/Icons/Icon";

const ResetPassword = observer(() => {
  const navigate = useNavigate();
  const [show, setshow] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const handleClick = (field) => {
    setshow((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: YupValidation.password,
      confirmPassword: YupValidation.confirmPassword,
    }),
    onSubmit: (values) => {
      changeForgetPassword(values);
      formik.resetForm();
    },
  });

  const changeForgetPassword = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      newPass: values.newPassword,
      token: localStorage.getItem("resetPassToken"),
    };

    await axios
      .post(FORGET_PASSWORD.Change_Forget_Password, params)
      .then((res) => {
        if (res?.data?.status === "success") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Password changed successfully!");
          navigate("/");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Password failed to change!");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Password failed to change !");
      });
  };

  return (
    <div className="login">
      <img className="loginImage" src={logo} alt="" loading="lazy" />
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img className="healthLogo" src={headLogo} alt="" loading="lazy" />
            <div className="head">
              <div className="headtitle">Reset Your Password !</div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormikController
              name="newPassword"
              error
              label="New Password"
              control="textfield"
              type={show.newPassword ? "text" : "password"}
              touch={formik.touched.newPassword}
              errorname={formik.errors.newPassword}
              {...formik.getFieldProps("newPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => handleClick("newPassword")}
                  >
                    {show.newPassword ? (
                      <Icon
                        iconName="visible"
                        className={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                            ? "errors"
                            : "icon"
                        }
                      />
                    ) : (
                      <Icon
                        iconName="invisible"
                        className={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                            ? "errors"
                            : "icon"
                        }
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikController
              name="confirmPassword"
              error
              label="Confirm Password"
              control="textfield"
              type={show.confirmPassword ? "text" : "password"}
              touch={formik.touched.confirmPassword}
              errorname={formik.errors.confirmPassword}
              {...formik.getFieldProps("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => handleClick("confirmPassword")}
                  >
                    {show.confirmPassword ? (
                      <Icon
                        iconName="visible"
                        className={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? "errors"
                            : "icon"
                        }
                      />
                    ) : (
                      <Icon
                        iconName="invisible"
                        className={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? "errors"
                            : "icon"
                        }
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikController
              name="Reset Password"
              control="buttons"
              onClick={formik.handleSubmit}
              className="custom-button"
            />
          </Grid>

          <Grid item xs={12}>
            <Link to="/" className="backToLogin">
              Back to Login
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});

export default ResetPassword;
