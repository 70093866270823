import { action, makeObservable, observable } from "mobx";
import $ from "jquery";
import axios from "axios";
import { CareGiverURL, ClientURL, NurseAndCareGiverURL } from "../Url/Url";
import AgencyStore from "./AgencyStore";
import GlobelStore from "./GlobelStore";
import ClientStore from "./ClientStore";

class CareGiverStore {
  nurseEmployeeSearch = "";
  nurseActiveCheckState = "";
  careGiverAgGrid = {};
  careGiverActiveCheckState = "";
  careGiverSearch = "";
  isCareGiverProfile = false;
  careGiverList = [];
  isCareGiverSaved = false;
  careGiverDbId = null;
  careGiverAllDocList = {};
  fileUploadPopUp = false;
  selectedDoc = "";
  selectedEmloyeeId = "";
  careGiverEmployeeUpdate = [];
  employeeChangeLog = [];
  isCareGiverProfileFetched = false;
  careGiverProfileData = {};
  isUniqueCodeEntered = false;
  isCareGiverAccountCreated = 0;
  employeeAllData = {};
  clockInOutAlldata = {};
  empSignatureAllData = {};
  constructor() {
    makeObservable(this, {
      nurseEmployeeSearch: observable,
      isUniqueCodeEntered: observable,
      isCareGiverAccountCreated: observable,
      careGiverProfileData: observable,
      selectedEmloyeeId: observable,
      selectedDoc: observable,
      fileUploadPopUp: observable,
      careGiverDbId: observable,
      careGiverList: observable,
      isCareGiverSaved: observable,
      isCareGiverProfile: observable,
      careGiverAgGrid: observable,
      careGiverActiveCheckState: observable,
      nurseActiveCheckState: observable,
      careGiverSearch: observable,
      careGiverAllDocList: observable,
      careGiverEmployeeUpdate: observable,
      isCareGiverProfileFetched: observable,
      employeeAllData: observable,
      employeeChangeLog: observable,
      clockInOutAlldata: observable,
      empSignatureAllData: observable,

      setIsCareGiverAccountCreated: action,
      setCareGiverProfileData: action,
      setCareGiverActiveCheckState: action,
      setCareGiverSearch: action,
      setCareGiverAgGrid: action,
      setTableHeight: action,
      setMessage: action,
      setIsCareGiverProfile: action,
      setCareGiverList: action,
      setIsCareGiverSaved: action,
      setCareGiverDbId: action,
      setCareGiverAllDocList: action,
      setFileUploadPopUp: action,
      setSelectedDoc: action,
      setSelectedEmloyeeId: action,
      setCareGiverEmployeeUpdate: action,
      setIsCareGiverProfileFetched: action,
      setIsUniqueCodeEntered: action,
      setEmployeeAllData: action,
      setEmployeeManualChangeLog: action,
      setNurseEmployeeSearch: action,
      setClockInOutAlldata: action,
      setEmpSignatureAllData: action,

      getEmployeeProfile: action,
      getClientList: action,
      getProfile: action,
      getEmployeeDocs: action,
      getEmpClockInOutDetails: action,
      getEmpSignature: action,
    });
  }
  setEmployeeManualChangeLog(data) {
    this.employeeChangeLog = data;
  }
  setClockInOutAlldata(data) {
    this.clockInOutAlldata = data;
  }
  setEmpSignatureAllData(data) {
    this.empSignatureAllData = data;
  }

  setIsCareGiverAccountCreated(data) {
    this.isCareGiverAccountCreated = data;
  }
  setIsUniqueCodeEntered(data) {
    this.isUniqueCodeEntered = data;
  }
  setCareGiverProfileData(data) {
    this.careGiverProfileData = data;
  }
  setSelectedEmloyeeId(data) {
    this.selectedEmloyeeId = data;
  }
  setCareGiverEmployeeUpdate(data) {
    this.careGiverEmployeeUpdate = data;
  }
  setIsCareGiverProfileFetched(data) {
    this.isCareGiverProfileFetched = data;
  }
  setSelectedDoc(data) {
    this.selectedDoc = data;
  }
  setNurseEmployeeSearch(data) {
    this.nurseEmployeeSearch = data;
  }

  setFileUploadPopUp(data) {
    this.fileUploadPopUp = data;
  }
  setCareGiverAllDocList(data) {
    this.careGiverAllDocList = data;
  }
  setCareGiverDbId(data) {
    this.careGiverDbId = data;
  }
  setCareGiverList(data) {
    this.careGiverList = data;
  }
  setIsCareGiverSaved(data) {
    this.isCareGiverSaved = data;
  }
  setIsCareGiverProfile(data) {
    this.isCareGiverProfile = data;
  }
  setCareGiverAgGrid(data) {
    this.careGiverAgGrid = data;
  }

  setCareGiverSearch(data) {
    this.careGiverSearch = data;
  }

  setCareGiverActiveCheckState(data) {
    this.careGiverActiveCheckState = data;
  }

  setNurseActiveCheckState(data) {
    this.nurseActiveCheckState = data;
  }
  setEmployeeAllData(data) {
    this.employeeAllData = data;
  }

  getEmployeeProfile = async (employeeId) => {
    await axios
      .post(
        CareGiverURL.GET_EMPLOYEEPROFILE + "/" + employeeId,
        {},
        {
          headers: { Authorization: localStorage.getItem("loginToken") },
        }
      )
      .then((res) => {
        if (res?.data?.status === "success") {
          this.setCareGiverProfileData(res?.data?.result);
          this.setCareGiverDbId(res?.data?.result?.id);
          this.setEmployeeAllData(res?.data);
          GlobelStore.setPageType("careGiver");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fetch Profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fetch Profile");
      });
  };

  getProfile = async () => {
    const params = {};

    await axios
      .post(NurseAndCareGiverURL.GET_PROFILE, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          this.setCareGiverProfileData(res?.data?.result);
          this.setEmployeeAllData(res?.data);
          GlobelStore.setPageType("careGiver");
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to fetch Profile");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fetch Profile");
      });
  };

  getConfidentialDoc = async (employeeId) => {
    await axios
      .get(CareGiverURL.GET_EMPLOYEEDOCS + "/" + employeeId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status === 200) {
          this.setCareGiverAllDocList(res?.data);
        } else {
          AgencyStore.showAlert("error", "Failed to get document");
        }
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get document");
      });
  };

  getEmployeeDocs = async () => {
    await axios
      .get(NurseAndCareGiverURL.GET_EMPLOYEE_DOCS, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.status === 200) {
          this.setCareGiverAllDocList(res?.data);
        } else {
          AgencyStore.showAlert("error", "Failed to get document");
        }
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get document");
      });
  };

  getClientList = async () => {
    AgencyStore.setIsLoading(true);
    await axios
      .get(ClientURL.GET_CLIENT_PROFILE_LIST, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        ClientStore.setCareGiverList(res?.data?.resultSet);
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.showAlert("error", "Failed to get client list ");
        AgencyStore.setIsLoading(false);
      });
  };

  // getEmpClockInOutDetails = async (employeeProfileId, fromDate, toDate) => {
  //   const params = {
  //     employeeProfileId: employeeProfileId,
  //     fromDate: fromDate,
  //     toDate: toDate,
  //     page: 1,
  //     pageSize: 1000,
  //   };

  //   try {
  //     const res = await axios.post(
  //       CareGiverURL.GET_EMP_CLOCKINOUT_DTL,
  //       params,
  //       {
  //         headers: { Authorization: localStorage.getItem("loginToken") },
  //       }
  //     );
  //     if (res?.data?.status === "success") {
  //       this.setClockInOutAlldata(res?.data?.result);
  //     } else {
  //       AgencyStore.setIsLoading(false);
  //       AgencyStore.showAlert("error", "Failed to fetch clock In/Out details");
  //     }
  //   } catch (err) {
  //     AgencyStore.setIsLoading(false);
  //     AgencyStore.showAlert("error", "Failed to fetch clock In/Out details");
  //   }
  // };

  getEmpClockInOutDetails = async (employeeProfileId, fromDate, toDate) => {
    const params = {
      employeeId: employeeProfileId,
      fromDate: fromDate,
      toDate: toDate,
      page: 1,
      pageSize: 1000,
    };

    await axios
      .post(CareGiverURL.GET_EMP_CLOCKINOUT_DTL, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.summary?.status === "success") {
          this.setClockInOutAlldata(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to fetch clock In/Out details"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to fetch clock In/Out details");
      });
  };

  getEmpSignature = async (employeeProfileId) => {
    await axios
      .get(CareGiverURL.GET_EMP_SIGNATURE + "/" + employeeProfileId, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          this.setEmpSignatureAllData(res?.data);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to fetch employee signature details"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "error",
          "Failed to fetch employee signature details"
        );
      });
  };

  setTableHeight(rowCount) {
    var menuType = "careGiver";
    var windowsHeight = $(window).height();
    let navbar = $(".agencyTopNavContainer").height();
    let upperActionContainer = $(".actionButtonSearchMainContainer").height();
    var tableHeight = windowsHeight - (navbar + upperActionContainer + 50);
    var countedTableHeight = 35 * rowCount + 35;
    if (rowCount === 0) {
      $("#" + menuType + "_table").height(countedTableHeight + 5 + 35);
      this.setMessage(true, "careGiver");
    } else {
      if (countedTableHeight >= tableHeight) {
        $("#" + menuType + "_table").css("height", tableHeight);
      } else {
        $("#" + menuType + "_table").height(countedTableHeight + 5);
      }
    }
  }

  setMessage(isSet, menuType) {
    var messageText;
    var selector = "#" + menuType + "_table " + ".ag-center-cols-viewport";
    var container = document.querySelector(selector);
    var searchEl = $("#" + menuType + "_search").val();
    var onlyMyCheckBox = $("#" + menuType + "_active").prop("checked");

    if (searchEl || onlyMyCheckBox) {
      messageText = "No Matching Record Found";
    } else {
      messageText = " No Record Found ";
    }

    if (isSet) {
      if (!(container && container.querySelector("#messageText"))) {
        var message = document.createElement("div");
        message.setAttribute("id", "messageText");
        message.innerHTML = messageText;
        container.append(message);
        container.style.cssText = "";
      }
    } else {
      var messageTextEl = container?.querySelector("#messageText");
      if (messageTextEl) {
        container.removeChild(messageTextEl);
      }
    }
  }
}

export default new CareGiverStore();
