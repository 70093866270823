import { action, makeObservable, observable } from "mobx";
class AdminEmployeeStore {
  adminEmployeeList = [];
  adminEmployeeSearch = "";
  adminEmployeeActiveCheckState = "";
  adminEmployeeAgGrid = {};
  adminRoles = [];
  constructor() {
    makeObservable(this, {
      adminEmployeeList: observable,
      adminEmployeeSearch: observable,
      adminEmployeeActiveCheckState: observable,
      adminEmployeeAgGrid: observable,
      adminRoles: observable,
      setAdminEmployeeList: action,
      setAdminEmployeeSearch: action,
      setAdminEmployeeActiveCheckState: action,
      setAdminEmployeeAgGrid: action,
      setAdminRoles: action,
    });
  }

  setAdminEmployeeList(data) {
    this.adminEmployeeList = data;
  }

  setAdminEmployeeSearch(data) {
    this.adminEmployeeSearch = data;
  }

  setAdminEmployeeActiveCheckState(data) {
    this.adminEmployeeActiveCheckState = data;
  }

  setAdminEmployeeAgGrid(data) {
    this.adminEmployeeAgGrid = data;
  }
  setAdminRoles(data) {
    this.adminRoles = data;
  }
}

export default new AdminEmployeeStore();
