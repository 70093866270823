import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import "../CSS/LeftNavBar.css";

import LogoSvg from "../Images/GBHLogo.png";
import { Link } from "react-router-dom";
import Footer from "../Agency/Footer";
import AgencyStore from "../Store/AgencyStore";
import Icon from "../components/Icons/Icon";
import { FaWheelchair } from "react-icons/fa";
import NotificationStore from "../Store/NotificationStore";
import axios from "axios";
import { NurseAndCareGiverURL } from "../Url/Url";
import { Badge } from "@mui/material";
import NurseAndCareGiverEmployeeStore from "../Store/NurseAndCareGiverEmployeeStore";

const LeftNavBar = observer(() => {
  const handleMenuItemsClicked = useCallback((data) => {
    AgencyStore.setSelectedMenuItem(data);
  }, []);

  // const getEmpClientUnseenNotificationCount = () => {
  //   AgencyStore.setIsLoading(true);

  //   axios
  //     .get(NurseAndCareGiverURL.GET_UNSEEN_NOTIFICATION, {
  //       headers: { Authorization: localStorage.getItem("loginToken") },
  //     })
  //     .then((res) => {
  //       NotificationStore.setNotificationUnseenCount(res?.data);
  //       AgencyStore.setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       AgencyStore.setIsLoading(false);
  //       AgencyStore.showAlert("error", "Failed to get Unseen Notification");
  //     });
  // };

  useEffect(() => {
    NurseAndCareGiverEmployeeStore.getEmpClientUnseenNotificationCount();
  }, []);

  return (
    <div>
      <div className="logoMainContainer">
        <div className="logoContainer">
          <img className="logoImage" src={LogoSvg} alt="" loading="lazy" />
        </div>
      </div>
      <div className="menuButtoncontainer">
        {/* <div>
          <div className="menuItemsSubContainer">
            <div className="menuItemsSubTilte">Navigation</div>
            <div className="menuItemsSub">
              <div className="menuItemsContainer">
                <Link to="/agency/dashboard" className="navleftBarLink">
                  <div
                    className={
                      AgencyStore.selectedMenuItem === "dashboard"
                        ? "leftNavBarButtonSelected "
                        : "leftNavBarButtonUnselected "
                    }
                    onClick={() => handleMenuItemsClicked("dashboard")}
                  >
                    <RiDashboard3Line className="leftNavBarIcon" />
                    <span>Dashboard</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="othersMenuContiner">
          <div className="menuItemsMainContainer">
            <div className="menuItemsSubContainer">
              <div className="menuItemsSubTilte">Navigation</div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link to="/agency/profile" className="navleftBarLink">
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Profile"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Profile")}
                    >
                      <Icon iconName="client" className="leftNavBarIcon" />
                      <span>Profile</span>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link to="/agency/Notification" className="navleftBarLink">
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Notification"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Notification")}
                    >
                      <Icon iconName="client" className="leftNavBarIcon" />
                      <span>Notification</span>

                      <Badge
                        badgeContent={
                          NotificationStore?.notificationUnseenCount?.totalCount
                        }
                        color="error"
                      >
                        <div style={{ marginLeft: "20px" }}></div>
                      </Badge>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="menuItemsSubContainer">
              <div className="menuItemsSubTilte">Document</div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link
                    to="/agency/GovernmentDocument"
                    className="navleftBarLink"
                  >
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Government"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Government")}
                    >
                      <Icon iconName="client" className="leftNavBarIcon" />
                      <span>Government</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link
                    to="/agency/ConfidentialDocument"
                    className="navleftBarLink"
                  >
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Confidential"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Confidential")}
                    >
                      <Icon iconName="client" className="leftNavBarIcon" />
                      <span>Confidential</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link
                    to="/agency/ApplicationAndHiringDocument"
                    className="navleftBarLink"
                  >
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "ApplicationAndHiring"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() =>
                        handleMenuItemsClicked("ApplicationAndHiring")
                      }
                    >
                      <Icon iconName="client" className="leftNavBarIcon" />
                      <span>Application & Hiring</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="menuItemsSubContainer">
              <div className="menuItemsSubTilte">Customer</div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link to="/agency/ClientList" className="navleftBarLink">
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Client"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Client")}
                    >
                      <FaWheelchair className="leftNavBarIcon" />
                      <span>Client</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="menuItemsSubContainer">
              <div className="menuItemsSubTilte">Schedule</div>
              <div className="menuItemsSub">
                <div className="menuItemsContainer">
                  <Link to="/agency/Schedule" className="navleftBarLink">
                    <div
                      className={
                        AgencyStore.selectedMenuItem === "Schedule"
                          ? "leftNavBarButtonSelected "
                          : "leftNavBarButtonUnselected "
                      }
                      onClick={() => handleMenuItemsClicked("Schedule")}
                    >
                      <FaWheelchair className="leftNavBarIcon" />
                      <span>Schedule</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
});

export default LeftNavBar;
