import React, { useRef, useEffect } from "react";
import TableFormPdf from "./Agency/Pages/PdfViewer/TableFormPdf";
import { PDFViewer } from "@react-pdf/renderer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AgencyMain from "./Agency/AgencyMain";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import ResetPassword from "./ForgetPassword/ResetPassword";
import Login from "./Login/Login";
import Error404 from "./Agency/Pages/Error404.js";
import Loading from "./components/Loading/Loading.js";
import AgencyStore from "./Store/AgencyStore.js";
import Alerts from "./components/Alert/Alerts.js";
import FileUploadPopUp from "./components/FileUploadPopUp/FileUploadPopUp.js";
import ClientFileUploadPopUp from "./components/FileUploadPopUp/ClientFileUploadPopUp.js";
import "./App.css";
import NurseAndCareGiverMain from "./NurseAndCareGiver/NurseAndCareGiverMain.js";
import { NotificationURL } from "./Url/Url.js";
import axios from "axios";
import NotificationStore from "./Store/NotificationStore.js";

const App = () => {
  const customAlert = useRef(null);

  useEffect(() => {
    AgencyStore.setCustomAlert(customAlert.current);

    let inactivityTimer;
    const inactivityTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logout, inactivityTimeout);
    };

    const logout = () => {
      window.location.href = "/";
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(inactivityTimer);
    };
  }, []);

  const userType = localStorage.getItem("userType");

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {userType == "RN" || userType == "CareGiver" ? (
            <Route path="/agency/*" element={<NurseAndCareGiverMain />} />
          ) : (
            <Route path="/agency/*" element={<AgencyMain />} />
          )}

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>

      <Loading />
      <Alerts ref={customAlert} />
      <ClientFileUploadPopUp />
      <FileUploadPopUp />
    </>
  );
};

export default App;
