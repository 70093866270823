import React from "react";
import { TextField } from "@mui/material";
import "../../CSS/Input.css";
const Inputs = React.memo((props) => {
  const { name, label, type, touch, error, errorname, ...otherProps } = props;
  const textFielConfig = {
    size: "small",
    fullWidth: true,
    ...otherProps,
  };
  const inputStyle = {
    color: "#918F98",
    fontSize: "15px",
    letterSpacing: "0.5px",
  };
  return (
    <>
      <div className="title">{label}</div>
      <TextField
        autoComplete="off"
        {...textFielConfig}
        name={name}
        type={type}
        error={touch && errorname ? error : false}
        inputProps={{ style: inputStyle }}
        className="input"
      />
      <div className="error">{touch && errorname ? errorname : ""}</div>
    </>
  );
});

export default Inputs;
