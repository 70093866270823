import { View, Text } from "@react-pdf/renderer";
import { styles } from "./CommonUtilsPdf";
export const TableRow = ({ category, items, id = 1 }) => (
  <View style={styles.category}>
    <View>
      <Text style={styles.categoryTitle}>{category}</Text>
    </View>
    {Object.entries(items).map(([key, value]) => (
      <View style={styles.safetyTHeader}>
        <View style={styles.safetyTBody}>
          <Text>{id++}</Text>
        </View>
        <View style={styles.safetydescBody}>
          <Text>{key}</Text>
        </View>
        <View style={styles.safetyTBody}>
          <Text>{value == "yes" ? "X" : ""}</Text>
        </View>
        <View style={styles.safetyTBody}>
          <Text>{value == "no" ? "X" : ""}</Text>
        </View>
        <View style={styles.safetyTBody}>
          <Text>{value == "n/a" ? "X" : ""}</Text>
        </View>
      </View>
    ))}
  </View>
);
