import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import {
  createColumnDef,
  onFullNameClick,
  statusCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";
import AdminEmployeeStore from "../../../Store/AdminEmployeeStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useNavigate } from "react-router-dom";

const AdminEmployeeListTable = observer(() => {
  const navigate = useNavigate();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    AdminEmployeeStore,
    "adminEmployeeAgGrid"
  );
  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);
      navigate("/agency/adminProfile/" + employeeId);
    };

    return (
      <div onClick={onFullNameClick} class="ag-inner-cell">
        {params.value}
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      createColumnDef(
        "employeeId",
        "#Id",
        leftStyles,
        fullNameCellRenderer,
        "",
        60
      ),
      createColumnDef("fullName", "FullName", leftStyles, fullNameCellRenderer),
      createColumnDef(
        "status",
        "Status",
        centerStyles,
        statusCellRenderer,
        "center-header"
      ),
    ],
    []
  );

  useEffect(() => {
    GlobelStore.getEmployeeList("AdminEmployee");
  }, []);

  return (
    <FormikController
      rowData={AdminEmployeeStore.adminEmployeeList}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
});

export default AdminEmployeeListTable;
