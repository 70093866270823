import React, { useState } from "react";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "../Images/login3.svg";
import headLogo from "../Images/GBHLogo.png";
import "../CSS/login.css";
import FormikController from "../components/FormikController/FormikController";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import axios from "axios";
import { FORGET_PASSWORD } from "../Url/Url";
import AgencyStore from "../Store/AgencyStore";
import { YupValidation } from "../Constants/ValidationConstants";

const ForgetPassword = React.memo(() => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      resetCode: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
      resetCode: open ? YupValidation.resetCode : null,
    }),
    onSubmit: async (values) => {
      if (!open) {
        await sendResetCodeToEmail(values);
      } else {
        await validateResetCode(values);
      }
    },
  });

  const sendResetCodeToEmail = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      email: values.email,
    };

    try {
      const res = await axios.post(FORGET_PASSWORD.Forget_Password, params);
      if (res?.data?.status === "success") {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "success",
          "Password reset code has been sent to email"
        );
        setOpen(true);
      } else {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "User not found");
      }
    } catch (err) {
      AgencyStore.setIsLoading(false);
      AgencyStore.showAlert("error", "User not found");
    }
  };

  const validateResetCode = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      userEmail: values.email,
      resetCode: values.resetCode,
    };

    try {
      const res = await axios.post(
        FORGET_PASSWORD.Validate_Forget_Password,
        params
      );
      if (res?.data?.status === "success") {
        localStorage.setItem("resetPassToken", res?.data?.token);
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert(
          "success",
          "Username and code validated successfully."
        );
        navigate("/reset-password");
      } else {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Please enter correct username or code");
      }
    } catch (err) {
      AgencyStore.setIsLoading(false);
      AgencyStore.showAlert("error", "Please enter correct username or code");
    }
  };

  return (
    <>
      <div className="login">
        <img className="loginImage" src={logo} alt="" />
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img className="healthLogo" src={headLogo} alt="" />
              <div className="head">
                <div className="headtitle">
                  {open ? "Reset Password" : "Forgot Password? 🔒"}
                </div>
                {open && (
                  <span className="resetText">
                    Please check your email and use the reset code within 10
                    minutes
                  </span>
                )}
                {!open && (
                  <span className="forgetText">
                    Enter your email and we'll send you instructions to reset
                    your password
                  </span>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormikController
                name="email"
                error
                label="Email"
                type="email"
                control="textfield"
                {...formik.getFieldProps("email")}
                touch={formik.touched.email}
                errorname={formik.errors.email}
              />
            </Grid>

            {open && (
              <>
                <Grid item xs={12}>
                  <FormikController
                    control="textfield"
                    name="resetCode"
                    label="Enter your reset code"
                    {...formik.getFieldProps("resetCode")}
                    touch={formik.touched.resetCode}
                    errorname={formik.errors.resetCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikController
                    name="Reset"
                    control="buttons"
                    onClick={formik.handleSubmit}
                    className="custom-button"
                  />
                </Grid>
              </>
            )}

            {!open && (
              <Grid item xs={12}>
                <FormikController
                  name="Send Reset Code"
                  control="buttons"
                  onClick={formik.handleSubmit}
                  className="custom-button"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Link to="/" className="backToLogin">
                <IoChevronBackOutline />
                Back to Login
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
});

export default ForgetPassword;
