import React, { useEffect, useMemo, useState } from "react";

import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import {
  createColumnDef,
  statusCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";
import { useNavigate } from "react-router-dom";
const NurseListTable = observer(() => {
  const navigate = useNavigate();
  const [onGridReady] = useOnGridReady(GlobelStore, "nurseAgGrid");
  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/Nurse_Profile/" + employeeId);
    };

    return (
      <div onClick={onFullNameClick} class="ag-inner-cell">
        {params.value}
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      createColumnDef(
        "employeeId",
        "#Id",
        leftStyles,
        fullNameCellRenderer,
        "",
        60
      ),

      createColumnDef("fullName", "FullName", leftStyles, fullNameCellRenderer),
      createColumnDef(
        "status",
        "Status",
        centerStyles,
        statusCellRenderer,
        "center-header"
      ),
    ],
    []
  );

  useEffect(() => {
    GlobelStore.getEmployeeList("RN");
  }, []);
  if (GlobelStore.nurseList.length > 0) {
    CareGiverStore.setTableHeight(GlobelStore.nurseList.length);
  }

  return (
    <FormikController
      rowData={GlobelStore.nurseList}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
});

export default NurseListTable;
