import { useRef, useState } from "react";
import CareGiverStore from "../Store/CareGiverStore";

import GlobelStore from "../Store/GlobelStore";
import { getStatusTypeName } from "./Utils";
import { colorMap } from "../Constants/Constants";

export const createColumnDef = (
  field,
  headerName,
  cellStyles,
  cellRenderer,
  headerClass,
  width,
  filter
) => {
  return {
    field: field,
    headerName: headerName,
    cellStyle: cellStyles,
    cellRenderer: cellRenderer,
    headerClass: headerClass,
    width: width,
    filter: filter,
  };
};

export const fullNameCellRenderer = (params) => (
  <div className="innerAgCell" title={params.value}>
    {params.value}
  </div>
);

export const dateCellRenderer = (params) => (
  <div className="innerAgCell" title={params.value}>
    {params.value}
  </div>
);

export const statusCellRenderer = (params) => (
  <div
    className="ag-inner-cell"
    style={{ color: params.value === "Active" ? "green" : "red" }}
  >
    {params.value}
  </div>
);

export const statusClientCellRenderer = (params) => {
  const statusTypeName = getStatusTypeName(params.value);

  return (
    <div
      className="ag-inner-cell"
      style={{ color: colorMap[params.value] || "black" }}
    >
      {statusTypeName}
    </div>
  );
};

export const CellRenderer = (params) => (
  <div className="innerAgCell" title={params.value}>
    {params.value}
  </div>
);

export const notificationCellRenderer = (params) => (
  <div
    style={{
      color: params.data.isViewed === false ? "Black" : "",
      fontWeight: params.data.isViewed === false ? "bold" : "",
      ":hover": {
        color: "blue",
      },
    }}
    className="innerAgCell"
    title={params.value}
  >
    {params.value}
  </div>
);

export const useOnGridReady = (
  store,
  storeProperty,
  isEventListener = false
) => {
  const gridApiRef = useRef(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridApiRef.current.sizeColumnsToFit();
    store[storeProperty] = gridApiRef.current;
    CareGiverStore.setTableHeight(store[storeProperty].getDisplayedRowCount());
    if (isEventListener && gridApiRef.current) {
      gridApiRef.current.addEventListener("selectionChanged", () => {
        const selectedRows = gridApiRef.current.getSelectedRows();
        const selectedIds = selectedRows.map((row) => row.id);
        setSelectedIds(selectedIds);
      });
    }
  };
  const onFirstDataRendered = () => {
    if (store[storeProperty]?.getDisplayedRowCount) {
      CareGiverStore.setTableHeight(
        store[storeProperty].getDisplayedRowCount()
      );
    } else CareGiverStore.setTableHeight(0);
  };

  return [onGridReady, onFirstDataRendered, selectedIds];
};

export const onFullNameClick = (params, navigate) => {
  const employeeId = params?.data?.employeeProfileId;
  const employeeCategory = params?.data?.employeeCategory;
  CareGiverStore.setIsCareGiverProfile(true);
  CareGiverStore.setIsUniqueCodeEntered(false);
  GlobelStore.setEmployeeData(params.data);
  if (employeeCategory === "RN") {
    navigate("/agency/Nurse_Profile/" + employeeId);
  } else if (employeeCategory === "AdminEmployee") {
    navigate("/agency/adminProfile/" + employeeId);
  } else if (employeeCategory === "CareGiver") {
    navigate("/agency/careGiverProfile/" + employeeId);
  } else if (employeeCategory === "CommunityCoordinator") {
    navigate("/agency/communityOutReachCoordinatorProfile/" + employeeId);
  }
};
