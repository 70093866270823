import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import axios from "axios";
import { YupValidation } from "../../../Constants/ValidationConstants";
import GlobelStore from "../../../Store/GlobelStore";
import "../../../CSS/AccountAccess.css";

const CommunityOutReachCoordinatorUserView = observer(() => {
  const { employeeId } = GlobelStore.employeeData.id
    ? { employeeId: GlobelStore.employeeData.id }
    : useParams();

  const rolesArray =
    CareGiverStore.employeeAllData.accountDetails?.userRoles?.map(
      (role) => role.role
    ) || [];

  const [selectedRoles, setSelectedRoles] = useState(rolesArray);

  const handleCheckboxChange = (item) => {
    if (selectedRoles.includes(item)) {
      if (item !== "Admin") {
        setSelectedRoles(
          selectedRoles.filter((role) => role !== item && role !== "Admin")
        );
      } else {
        setSelectedRoles(selectedRoles.filter((role) => role !== "Admin"));
      }
    } else {
      if (item !== "Admin") {
        setSelectedRoles([...selectedRoles, item]);
      } else {
        setSelectedRoles(GlobelStore.availableRoles);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
      fullName: YupValidation.fullName,
    }),
    onSubmit: (values) => {
      //   createCareGiverAccount(values);
    },
  });

  const setProfileDataFormik = () => {
    const result = CareGiverStore.careGiverProfileData;

    const fullName = `${result?.firstName || ""} ${result?.middleName || ""} ${
      result?.lastName || ""
    }`;

    formik.setValues({
      email: result?.email || "",
      fullName: fullName.trim(), // Trim to remove extra spaces if any
    });
  };

  const [roleErrorMessage, setRoleErrorMessage] = useState("");

  const updateEmployeeRoles = (values) => {
    AgencyStore.setIsLoading(true);
    if (selectedRoles.length === 0) {
      AgencyStore.setIsLoading(false);
      setRoleErrorMessage("Please select at least one role.");
      return; // Exit the function early
    }
    const params = {
      userId: CareGiverStore.isCareGiverAccountCreated,

      roles: selectedRoles,
    };
    axios
      .post(CareGiverURL.UPDATE_EMPLOYEEROLES, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.showAlert("success", "Roles successfully change");
        } else {
          AgencyStore.showAlert("error", "Failed to change roles");
        }
        AgencyStore.setIsLoading(false);
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to change roles");
      });
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, CareGiverStore.careGiverProfileData]);
  return (
    <div className="adminEmployeeViewMainContainer">
      <div className="subMainContiner">
        <div className="CGSHeader">Details</div>
        <div className="">
          <div>
            <FormikController
              name="fullName"
              error
              label="Full Name"
              type="text"
              control="textfield"
              {...formik.getFieldProps("fullName")}
              touch={formik.touched.fullName}
              errorname={formik.errors.fullName}
              disabled="true"
            />
          </div>
        </div>
        <div>
          <FormikController
            name="email"
            error
            label="User Name"
            type="email"
            control="textfield"
            {...formik.getFieldProps("email")}
            touch={formik.touched.email}
            errorname={formik.errors.email}
            disabled="true"
          />
        </div>
      </div>

      <div className="rolesContainer backgroundcolorLightDark">
        <div className="roleSpamMsgConatiner">
          <div className="title">Roles</div>
          <span className="errorMessage">{roleErrorMessage}</span>
        </div>
        {GlobelStore.availableRoles.map((item, index) => (
          // {Array.isArray(GlobelStore.availableRoles) &&
          //   GlobelStore.availableRoles.map((item, index) => (
          <div key={index} className="roleItem">
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(item)}
              checked={selectedRoles.includes(item)}
            />
            <span>{item}</span>
          </div>
        ))}
        <div className="buttonContainer">
          <FormikController
            name="Update Roles"
            control="buttons"
            className="buttonSelectedGreen menuItemButton "
            fullWidth={false}
            onClick={() => updateEmployeeRoles()}
          />
        </div>
      </div>
    </div>
  );
});

export default CommunityOutReachCoordinatorUserView;
