import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { CgProfile } from "react-icons/cg";
import "../../../CSS/Client.css";
import FormikController from "../../../components/FormikController/FormikController";
import ClientDoctorCaseNote from "./ClientDoctorCaseNote";
import ClientStore from "../../../Store/ClientStore";
import DocterList from "./DocterInfo/DocterList";

const ClientDoctorMain = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="clientDoctorMainContaner">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
            >
              <Tab className="profileEachMenu" label="list" value="1" />
              <Tab className="profileEachMenu" label="Case Note" value="2" />
            </TabList>
          </Box>
          <TabPanel className="profileEachTabPanel" value="1">
            <DocterList />
          </TabPanel>
          <TabPanel className="profileEachTabPanel" value="2">
            <ClientDoctorCaseNote />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
});

export default ClientDoctorMain;
