import React from "react";
import { observer } from "mobx-react";
import NurseProfile from "./NurseProfile";
import Accordions from "../../../components/Accordion/Accordions";
import useHandleState from "../../../components/useHandleState";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import Icon from "../../../components/Icons/Icon";

const AddNurse = observer(() => {
  const { employeeId } = useParams();

  const initialPanelsState = {
    panel1: true,
    panel2: false,
    panel3: false,
    panel4: false,
  };

  const { state, handleChange } = useHandleState(initialPanelsState);

  return (
    <div>
      {employeeId ? (
        ""
      ) : (
        <div className="pageHeaderContainer">
          <PageTitle
            icon={<Icon iconName="menu" />}
            headerTitle="Nurse"
            subHeaderTitle="  Employee/Nurse/Profile"
          />
        </div>
      )}
      <Accordions
        accordianComponent={<NurseProfile />}
        expanded={state.panel1}
        OnhandleChange={handleChange("panel1")}
        title="Nurse Profile"
      />

      {/* {CareGiverStore.isCareGiverProfile === true ? (
        ""
      ) : (
        <>
          {CareGiverStore.isCareGiverSaved === false ? (
            ""
          ) : (
            <>
              <Accordions
                accordianComponent={<NurseGovernment />}
                expanded={state.panel2}
                OnhandleChange={handleChange("panel2")}
                title="Government document"
              />
              <Accordions
                accordianComponent={<NurseConfidential />}
                expanded={state.panel3}
                OnhandleChange={handleChange("panel3")}
                title="Confidential document"
              />
              <Accordions
                accordianComponent={<EmployeeApplicationResumeHiring />}
                expanded={state.panel4}
                OnhandleChange={handleChange("panel4")}
                title="Application and Hiring document"
              />
            </>
          )}
        </>
      )} */}
    </div>
  );
});

export default AddNurse;
