import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useFormik } from "formik";
import FormikController from "../../../components/FormikController/FormikController";
import * as Yup from "yup";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { InputAdornment } from "@mui/material";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import AgencyStore from "../../../Store/AgencyStore";
import { CareGiverURL } from "../../../Url/Url";
import axios from "axios";
import { YupValidation } from "../../../Constants/ValidationConstants";
import GlobelStore from "../../../Store/GlobelStore";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const NurseUserView = observer(() => {
  const { employeeId } = GlobelStore.employeeData.id
    ? { employeeId: GlobelStore.employeeData.id }
    : useParams();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const handleClickConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: YupValidation.email,
    }),
    onSubmit: (values) => {
      //   createCareGiverAccount(values);
    },
  });

  const setProfileDataFormik = () => {
    const result = CareGiverStore.careGiverProfileData;
    formik.setValues({
      email: result?.email || "",
    });
  };

  useEffect(() => {
    if (employeeId) {
      setProfileDataFormik();
    }
  }, [employeeId, CareGiverStore.careGiverProfileData]);
  return (
    <div>
      <div className="CGSHeader">Account</div>
      <div className="changePasswordFormMainContainer">
        <div className="formMainUniqueContainer">
          <div>
            <FormikController
              name="email"
              error
              label="User Name"
              type="email"
              control="textfield"
              {...formik.getFieldProps("email")}
              touch={formik.touched.email}
              errorname={formik.errors.email}
              disabled="true"
            />
          </div>
        </div>
      </div>

      {/* <div className="securityMainContainer">
        <Accordion
          className="accordionMainContainer"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className="accordionSummaryMainContainer"
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
                        <div className="CGSHeader">Deactivate Account</div>

            <span className="accordionTitle">Account</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="changePasswordFormMainContainer">
              <div className="currentPasswordFormMainContainer">
                <div>
                  <FormikController
                    name="email"
                    error
                    label="User Id"
                    type="email"
                    control="textfield"
                    {...formik.getFieldProps("email")}
                    touch={formik.touched.email}
                    errorname={formik.errors.email}
                    disabled="true"
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div> */}
    </div>
  );
});

export default NurseUserView;
